import { Injectable } from '@angular/core';
import { MessageBus } from '../messaging/MessageBus';
import { Configuration } from '../constant/configuration';

export interface NotificationButtonI {
    label?: string;
    callback?: () => void;
    icon?: string;
}

@Injectable({ providedIn: 'root' })
export class NotificationMessageService {
    private notificationChannel: string;

    constructor(private messageBus: MessageBus, private configuration: Configuration) {
        this.notificationChannel = configuration.messageBusChannels.NOTIFICATION_CHANNEL;
    }

    init(title, message): void {
        this.messageBus
            .channel(this.notificationChannel)
            .next({ message: 'event:notificationMessage-init', data: { title, message } });
    }

    initTranslationValues(titleValues, messageValues): void {
        this.messageBus.channel(this.notificationChannel).next({
            message: 'event:notificationMessage-initTranslationValues',
            data: {
                title: titleValues,
                message: messageValues
            }
        });
    }

    addLeftButton(button: NotificationButtonI): void {
        this.messageBus.channel(this.notificationChannel).next({
            message: 'event:notificationMessage-addLeftButton',
            data: button
        });
    }

    addRightButton(button: NotificationButtonI): void {
        this.messageBus.channel(this.notificationChannel).next({
            message: 'event:notificationMessage-addRightButton',
            data: button
        });
    }

    show(): void {
        this.messageBus.channel(this.notificationChannel).next({ message: 'event:notificationMessage-show' });
    }
}
