import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { RoleI } from '../models/role';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import { UserDefaultRolesE } from '../models/user';
import { HttpClient } from '@angular/common/http';
import {
    CACHED_COMPANY_ROLE_KEY,
    CompanyRoleRepository,
    skipWhileCompanyRoleCached
} from '../store/companyRole.repository';

@Injectable({ providedIn: 'root' })
export class RoleService {
    private roleList: Array<RoleI>;
    private sessionRoleList: Array<RoleI>;
    serviceUrl = 'webfront/role';

    constructor(public httpClient: HttpClient, private companyRoleRepository: CompanyRoleRepository) {}

    getRoles(): Observable<RoleI[]> {
        if (!this.roleList) {
            return this.httpClient.get<RoleI[]>(this.serviceUrl).pipe(
                tap((value: Array<RoleI>) => {
                    this.roleList = value;
                })
            );
        }
        return of(this.roleList);
    }

    getSessionRoles(): Observable<RoleI[]> {
        if (!this.sessionRoleList) {
            return this.httpClient.get(`${this.serviceUrl}/session-available`).pipe(
                tap((values: RoleI[]) => {
                    this.sessionRoleList = values.filter(role => role.name !== UserDefaultRolesE.TEMPORARY_USER);
                })
            );
        }
        return of(this.sessionRoleList);
    }

    resetSessionRoles(): void {
        this.sessionRoleList = null;
    }

    getRolesByCompany(companyUuid: string): Observable<RoleI[]> {
        return this.httpClient.get<RoleI[]>(`${this.serviceUrl}/company/${companyUuid}`).pipe(
            tap(roles => this.companyRoleRepository.addRoles(companyUuid, roles)),
            skipWhileCompanyRoleCached(`${CACHED_COMPANY_ROLE_KEY}-${companyUuid}`, {
                returnSource: this.companyRoleRepository.getRolesByCompany$(companyUuid)
            })
        );
    }
}
