import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent {
    constructor(private service: SpinnerService) {}

    get show(): Observable<boolean> {
        return this.service.isShow;
    }
}
