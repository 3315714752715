import { Pipe, PipeTransform } from '@angular/core';
import { FilterSubCategoryI } from '../../../../models/filter';
import { FacetKeyHitsI } from '../../../../models/facets';

@Pipe({
    name: 'sortFilterFields'
})
export class SortFilterFieldsPipe implements PipeTransform {
    /**
     * Order subcategories by number of hits (greater first) and alphabetically
     */
    transform(subCategories: FilterSubCategoryI[], hits: FacetKeyHitsI[], isDesc = false): FilterSubCategoryI[] {
        const sortAscDesc = isDesc ? -1 : 1;

        const sorted = subCategories.sort((n1, n2) => {
            if (n1.selected.value && !n2.selected.value) {
                return -1 * sortAscDesc;
            }

            if (!n1.selected.value && n2.selected.value) {
                return 1 * sortAscDesc;
            }

            const hitsN1 = this.findHits(n1.key, hits);
            const hitsN2 = this.findHits(n2.key, hits);
            if (hitsN1 > hitsN2) {
                return -1 * sortAscDesc;
            }
            if (hitsN1 < hitsN2) {
                return 1 * sortAscDesc;
            }
            const label1 = n1.label.trim();
            const label2 = n2.label.trim();

            if (label1 > label2) {
                return 1 * sortAscDesc;
            }
            if (label1 < label2) {
                return -1 * sortAscDesc;
            }
            return 0;
        });
        return sorted;
    }

    findHits(key: string, hits: FacetKeyHitsI[]): number {
        if (hits === undefined || hits === null) {
            return 0;
        }
        const element = hits.find(f => f.key === key);
        return element !== undefined ? Number(element.hits) : 0;
    }
}
