import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-route-name-header',
    templateUrl: './route-name-header.component.html',
    styleUrls: ['./route-name-header.component.scss']
})
export class RouteNameHeaderComponent {
    @Input() mainHeader: string;
    @Input() subHeader: string;
    @Input() iconName: string;
    @Input() notTranslatableHeader: string;
    @Input() notTranslatableSubHeader: string;
    @Input() badge: string | number = null;
    @Input() goBack: () => void;
    @Input() backButtonRoutePath: string;
}
