import { Injectable } from '@angular/core';
import { HttpClientService } from '../../services/http-client.service';
import { Observable } from 'rxjs/internal/Observable';
import {
    ContinuousPaginationBookCardsDTO,
    RecentDocumentsByWorkspaceFromDBI,
    RecentPeriodicsI
} from '../../models/publication';
import { DocumentTypeI } from '../../models/document';
import { TopWordI } from '../../models/suggestion';
import { DocumentSearchI, DocumentSimpleSearchI } from '../../models/documentSearch';
import { HttpHeaders } from '@angular/common/http';
import { Pageable } from '../../mixins/pagination/pagination.mixin';
import { MixpanelService } from '../../services/mixpanel.service';
import { Configuration } from '../../constant/configuration';
import { PublicationIdType } from '../../models/publicationIdType';

@Injectable({ providedIn: 'root' })
export class HomeServiceNg {
    bookTypeCode: any;
    publicationType: any;

    constructor(
        private httpService: HttpClientService,
        private mixPanelService: MixpanelService,
        configuration: Configuration
    ) {
        this.bookTypeCode = configuration.bookTypeCode;
        this.publicationType = configuration.publicationType;
    }

    /**
     * Retrieve the most recent documents for current user from DB
     */
    latestDocumentUpdatesFromDB$ = this.httpService.post(
        '/document/recent_db',
        {},
        {
            headers: new HttpHeaders({ skipSpinner: 'true' })
        }
    ) as Observable<RecentDocumentsByWorkspaceFromDBI[]>;

    /**
     * Find all the document type list available and ordered by legal weight
     */
    getDocumentTypeList(): Observable<DocumentTypeI[]> {
        return this.httpService.get('/documentType');
    }

    /**
     * Retrieve the most recent publications for current user
     */
    getLatestPublicationUpdates(pageable: Pageable): Observable<RecentPeriodicsI> {
        return this.httpService.post(
            '/periodic/recent',
            {},
            {
                headers: new HttpHeaders({ skipSpinner: 'true' }),
                params: {
                    page: pageable.page,
                    size: pageable.size
                }
            }
        );
    }

    /**
     * Retrieve the most recent publications for current user
     */
    getLatestMonographyUpdates(pageable: Pageable): Observable<ContinuousPaginationBookCardsDTO> {
        return this.httpService.post(
            '/book/recent',
            {},
            {
                headers: new HttpHeaders({ skipSpinner: 'true' }),
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }
        );
    }

    getPublicationIdAndTypeByReference(ref: string): Observable<PublicationIdType> {
        return this.httpService.get('/reference/' + ref);
    }

    // Retrieve the 10 best searched words
    searchTopWords(): Observable<TopWordI[]> {
        return this.httpService.get('/u/topwords');
    }

    /**
     * Retrieve the 10 latest keywords of simple search
     */
    getLastKeywordsForCurrentUser(): Observable<DocumentSimpleSearchI[]> {
        return this.httpService.get('/simplesearch/user');
    }
    /**
     * Retrieve the 10 latest keywords of simple search
     */
    latestSavedSearches(): Observable<DocumentSearchI[]> {
        return this.httpService.get('/search/latest');
    }

    saveLastSearch(keyword: string): Observable<DocumentSimpleSearchI> {
        return this.httpService.post('/simplesearch/', keyword);
    }

    saveLastKeyword(keyword: string): Observable<void> {
        return this.httpService.post('/statistics/keyword', keyword);
    }

    getPursuedDiscoveryHasCards(): Observable<boolean> {
        return this.httpService.get('/home/pursuedDiscoveryHasCards');
    }

    getYourEssentialsHasCards(): Observable<boolean> {
        return this.httpService.get('/home/yourEssentialsHasCards');
    }
}
