import { Injectable } from '@angular/core';
import {
    getPaginationData,
    PaginationData,
    selectCurrentPageEntities,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination
} from '@ngneat/elf-pagination';
import { createStore } from '@ngneat/elf';
import {
    addEntities,
    deleteEntities,
    getEntity,
    updateEntities,
    upsertEntities,
    withEntities
} from '@ngneat/elf-entities';
import { Pageable } from '../providers/pagination-provider';
import { HttpPaginationResponseI } from '../models/http';
import * as _ from 'lodash';
import { IMagazine, IMagazineFilter } from '../models/magazine';
import {
    clearRequestsCache,
    createRequestsCacheOperator,
    isRequestCached,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';

export interface IMagazineState extends IMagazine {
    id: number;
}

const store = createStore(
    { name: 'adminMagazine' },
    withEntities<IMagazineState>(),
    withPagination(),
    withRequestsCache<`admin-magazines-${number}`>()
);

export const skipWhileAdminMagazineCached = createRequestsCacheOperator(store);
export const CACHED_ADMIN_MAGAZINE_KEY = 'admin-magazines';

@Injectable({ providedIn: 'root' })
export class AdminMagazineRepository {
    activeFilters: IMagazineFilter;
    currentPageable: Pageable;
    magazinesOnCurrentPage$ = store.pipe(selectCurrentPageEntities());

    getPaginationData(): PaginationData {
        return store.query(getPaginationData());
    }
    setActiveFilters(filters: IMagazineFilter): void {
        this.activeFilters = JSON.parse(JSON.stringify(filters)); // to remove objects and arrays pointers
    }

    clearCacheIfDistinctFiltersOrSize(filters: IMagazineFilter, size: number): void {
        if (!_.isEqual(filters, this.activeFilters) || this.getPaginationData().perPage !== size) {
            this.clearCache();
        }
    }

    clearCache(): void {
        store.update(clearRequestsCache());
    }

    updateCurrentPageIfIsCached(page: number): void {
        if (store.query(isRequestCached(`${CACHED_ADMIN_MAGAZINE_KEY}-${page}`))) {
            store.update(setCurrentPage(page));
        }
    }

    addMagazine(payload: IMagazine): void {
        store.update(addEntities(payload as IMagazineState));
        this.clearCache();
    }

    upsertMagazine(payload: IMagazine): void {
        store.update(upsertEntities(payload as IMagazineState));
    }

    getMagazine(id: number): IMagazine {
        return store.query(getEntity(id));
    }

    updateMagazine(payload: IMagazine): void {
        const magazineUpdated = store.query(getEntity(payload.id));
        if (magazineUpdated[this.currentPageable.sort] !== payload[this.currentPageable.sort]) {
            // if sort field has been updated it can affect order
            this.clearCache();
        }
        store.update(updateEntities(payload.id, { ...payload }));
    }

    deleteMagazine(id: number): void {
        store.update(deleteEntities(id));
        this.clearCache();
    }

    addPaginationMagazines(paginationResponse: HttpPaginationResponseI<IMagazine[]>, pageable: Pageable): void {
        this.currentPageable = pageable;

        store.update(
            updateRequestCache(`${CACHED_ADMIN_MAGAZINE_KEY}-${paginationResponse.number}`),
            upsertEntities(paginationResponse.content),
            updatePaginationData({
                currentPage: paginationResponse.number,
                perPage: paginationResponse.size,
                total: paginationResponse.totalElements,
                lastPage: paginationResponse.totalPages
            }),
            setPage(
                paginationResponse.number,
                paginationResponse.content.map(c => c.id)
            ),
            setCurrentPage(paginationResponse.number)
        );
    }
}
