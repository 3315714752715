import { Injectable } from '@angular/core';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { createStore } from '@ngneat/elf';
import {
    addEntities,
    selectEntity,
    updateEntities,
    updateEntitiesByPredicate,
    withEntities
} from '@ngneat/elf-entities';
import { BookI } from '../models/book';
import { ICodeBucket } from '../models/codeBucket';
import { Observable } from 'rxjs/internal/Observable';

export interface BookCodeState {
    id: number; // id of book
    book: BookI;
    codeBucketList: ICodeBucket[];
}

const store = createStore(
    { name: 'bookCode' },
    withEntities<BookCodeState>(),
    withRequestsCache<`book-codes-${string}`>()
);

export const skipWhileBookCodeCached = createRequestsCacheOperator(store);
export const CACHED_BOOK_CODES_KEY = 'book-codes';

@Injectable({ providedIn: 'root' })
export class BookCodeRepository {
    selectBookWithCodeBuckets(id: number): Observable<BookCodeState> {
        return store.pipe(selectEntity(id));
    }

    updateExportDateBookCodeToNow(bucketId: number): void {
        store.update(
            updateEntitiesByPredicate(
                ({ codeBucketList }) => {
                    return codeBucketList.find(cb => cb.id === bucketId) !== null;
                },
                entity => {
                    const bc = entity.codeBucketList.find(cb => cb.id === bucketId);
                    bc.exportingDate = new Date().toISOString();
                    // replace the code bucket in the entity list
                    const newCodeBucketList = [entity.codeBucketList.filter(cb => cb.id !== bucketId), bc].flat();
                    return { ...entity, codeBucketList: newCodeBucketList };
                }
            )
        );
    }
    addBookWithCodeBuckets(book: BookI, codeBucketList: ICodeBucket[]): void {
        store.update(
            addEntities({ id: book.id, book, codeBucketList }),
            updateRequestCache(`${CACHED_BOOK_CODES_KEY}-${book.id}`)
        );
    }

    addCodeBucket(bookId: number, codeBucket: ICodeBucket): void {
        store.update(
            updateEntities(bookId, entity => {
                const newCodeBucketList = entity.codeBucketList.slice();
                newCodeBucketList.push(codeBucket);
                return {
                    ...entity,
                    codeBucketList: newCodeBucketList
                };
            })
        );
    }

    updateCodeBucket(bookId: number, codeBucket: ICodeBucket): void {
        store.update(
            updateEntities(bookId, entity => {
                const newCodeBucketList = entity.codeBucketList.slice();
                const index = newCodeBucketList.findIndex(x => x.id === codeBucket.id);
                if (index !== -1) {
                    newCodeBucketList.splice(index, 1, codeBucket);
                }
                return {
                    ...entity,
                    codeBucketList: newCodeBucketList
                };
            })
        );
    }
}
