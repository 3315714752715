import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { map } from 'rxjs/operators';
import { userAgentIsGoogleBot } from './browser';

export function generateGRecaptchaHeaders(action: string): Observable<HttpHeaders> {
    return generateGRecaptchaToken(action).pipe(
        map(token => {
            return new HttpHeaders({
                'X-ReCaptcha-ExpectedAction': action,
                'X-Recaptcha-Token': token,
                'X-Recaptcha-SiteKey': environment?.recaptcha?.siteKey
            });
        }),
        retry({ count: 5, delay: 1000 })
    );
}
export function generateGRecaptchaToken(action: string): Observable<string> {
    return new Observable<string>(observer => {
        if (userAgentIsGoogleBot()) {
            observer.next('');
            observer.complete();
        } else {
            if (!grecaptcha) {
                observer.error(new Error('google recaptcha is not loaded yet'));
            } else {
                grecaptcha.enterprise.ready(() => {
                    void grecaptcha.enterprise
                        .execute(environment.recaptcha.siteKey, { action })
                        .then((token: string) => {
                            observer.next(token);
                            observer.complete();
                        });
                });
            }
        }
    });
}
