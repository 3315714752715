import { Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
/**
 * This is an abstract service which allows all the CRUD REST-based operations.
 * All operation has a common given base url.
 */
import AbstractSearchService from './abstract_search.service';

export class AbstractCRUDsService extends AbstractSearchService {
    /**
     * Constructor
     * @param url            The base url of all the operations
     * @param httpService    The http service
     */
    constructor(public serviceUrl: string, httpService: HttpClientService) {
        super(serviceUrl, httpService);
    }

    /**
     * Http request for creation preparation
     */
    async prepareCreate(): Promise<any> {
        const query: Observable<any> = this.httpService.get(this.serviceUrl + '/create/init');
        return query.toPromise();
    }

    /**
     * Http request for read preparation
     */
    async prepareRead(id): Promise<any> {
        // abstract;
        return null;
    }

    /**
     * Http request for update preparation
     */
    async prepareUpdate(): Promise<any> {
        const query: Observable<any> | Promise<any> = this.httpService.get(this.serviceUrl + '/update/init');
        return query.toPromise();
    }

    /**
     * Http request for read preparation
     */
    async prepareSearch(): Promise<any> {
        const query: Observable<any> = this.httpService.get(this.serviceUrl + '/search/init', {
            cache: true
        });
        return query.toPromise();
    }

    /**
     * Http request for creation
     * @param item The item to create
     */
    async create(item: any): Promise<any> {
        const query: Observable<any> = this.httpService.post(this.serviceUrl, item);
        return query.toPromise();
    }

    /**
     * Http request for reading
     * @param  id The item identifier
     */
    async read(id: string): Promise<any> {
        const query: Observable<any> | Promise<any> = this.httpService.get(this.serviceUrl + '/' + id);
        return query.toPromise();
    }

    /**
     * Http request for updating
     * @param  item The item to update
     */
    async update(item: any): Promise<any> {
        const query: Observable<any> = this.httpService.put(this.serviceUrl, item);
        return query.toPromise();
    }

    /**
     * Http request for deletion
     */
    async delete(id: string): Promise<any> {
        const query: Observable<any> = this.httpService.delete(this.serviceUrl + '/' + id);
        return query.toPromise();
    }
}
