import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IPeriodicFilter, ISimplePeriodic } from '../models/book';
import { PaginationData } from '@ngneat/elf-pagination';
import { Pageable } from '../providers/pagination-provider';
import { createStore } from '@ngneat/elf';
import { getEntity, upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import {
    createRequestsCacheOperator,
    isRequestCached,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';
import { HttpPaginationResponseI } from '../models/http';
import { AdminPeriodicRepository } from './adminPeriodic.repository';

interface IPeriodicContainerState {
    id: number; // id of magazine
    pages: number[][]; // id of magazine subscription by page
    activeFilters: IPeriodicFilter;
    pageable: Pageable;
    paginationData: PaginationData;
}

const store = createStore(
    { name: 'adminPeriodicContainer' },
    withEntities<IPeriodicContainerState>(),
    withRequestsCache<`admin-periodic-containers-${number}-${number}`>()
);

export const skipWhileAdminPeriodicContainerCached = createRequestsCacheOperator(store);
export const CACHED_ADMIN_PERIODIC_CONTAINER_KEY = 'admin-periodic-containers';
@Injectable({ providedIn: 'root' })
export class AdminPeriodicContainerRepository {
    constructor(private adminPeriodicRepository: AdminPeriodicRepository) {}

    getPeriodicContainer(id: number): IPeriodicContainerState {
        return store.query(getEntity(id));
    }

    clearCacheIfDistinctFiltersOrPageable(magazineId: number, filters: IPeriodicFilter, pageable: Pageable): void {
        const currentMagazine = store.query(getEntity(magazineId));
        if (
            currentMagazine &&
            (!_.isEqual(filters, currentMagazine.activeFilters) ||
                currentMagazine.pageable.size !== pageable.size ||
                currentMagazine.pageable.sort !== pageable.sort)
        ) {
            this.clearCacheOfMagazine(magazineId);
        }
    }

    clearCacheOfMagazine(magazineId: number): void {
        const currentMagazine = store.query(getEntity(magazineId));
        currentMagazine?.pages.forEach((value, key) => {
            store.update(
                updateRequestCache(`${CACHED_ADMIN_PERIODIC_CONTAINER_KEY}-${magazineId}-${key}`, {
                    value: 'none'
                })
            );
        });
    }

    updateCurrentPageIfIsCached(magazineId: number, page: number): void {
        if (store.query(isRequestCached(`${CACHED_ADMIN_PERIODIC_CONTAINER_KEY}-${magazineId}-${page}`))) {
            const currentMagazine = store.query(getEntity(magazineId));
            this.adminPeriodicRepository.updatePagination(
                magazineId,
                page,
                currentMagazine.pages[page],
                currentMagazine.paginationData
            );
        }
    }

    addPaginationPeriodics(
        magazineId: number,
        paginationResponse: HttpPaginationResponseI<ISimplePeriodic[]>,
        pageable: Pageable,
        filters: IPeriodicFilter
    ): void {
        const paginationData = {
            currentPage: paginationResponse.number,
            perPage: paginationResponse.size,
            total: paginationResponse.totalElements,
            lastPage: paginationResponse.totalPages
        };

        store.update(
            updateRequestCache(`${CACHED_ADMIN_PERIODIC_CONTAINER_KEY}-${magazineId}-${paginationResponse.number}`),
            upsertEntitiesById(magazineId, {
                creator: id => {
                    const pages = [];
                    pages[paginationResponse.number] = paginationResponse.content.map(c => c.id);
                    return {
                        id,
                        activeFilters: { ...filters },
                        pageable: { ...pageable },
                        paginationData,
                        pages
                    };
                },
                updater: entity => {
                    const pages = [...entity.pages];
                    pages[paginationResponse.number] = paginationResponse.content.map(c => c.id);
                    return {
                        ...entity,
                        activeFilters: { ...filters },
                        pageable: { ...pageable },
                        paginationData,
                        pages
                    };
                }
            })
        );

        this.adminPeriodicRepository.addPaginationPeriodics(magazineId, paginationResponse);
    }
}
