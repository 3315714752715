import { HttpClientService } from '../../../../services/http-client.service';
import { ExportExcelService } from '../../../../services/export-excel.service';
import { Observable } from 'rxjs';
import { ITimesheetFilter } from '../../../../models/timesheet';

export class TimesheetRecordingService {
    serviceUrl = '/timesheet';

    public currentGroupUuid: string;
    public currentSessionUuid: string;

    constructor(public httpService: HttpClientService, private exportExcelService: ExportExcelService) {}

    async createTimesheet(timesheet): Promise<any> {
        if (this.currentGroupUuid) {
            timesheet.groupUuid = this.currentGroupUuid;
        }
        if (this.currentSessionUuid) {
            timesheet.sessionUuid = this.currentSessionUuid;
        }

        this.httpService.post(this.serviceUrl, timesheet).subscribe(newTimesheet => {
            this.currentGroupUuid = newTimesheet.groupUuid;
            this.currentSessionUuid = newTimesheet.sessionUuid;
        });
    }

    async updateTimesheet(timesheet): Promise<any> {
        return this.httpService.put(this.serviceUrl, timesheet).toPromise();
    }

    async updateTimesheetGroup(timesheetGroup): Promise<any> {
        return this.httpService.put(this.serviceUrl + '/group', timesheetGroup).toPromise();
    }

    async filterTimesheets(filter): Promise<any> {
        return this.httpService.post(this.serviceUrl + '/filter', filter).toPromise();
    }

    async filterGroups(filter, pageable): Promise<any> {
        return this.httpService.post(this.serviceUrl + '/group/filter', filter, { params: pageable }).toPromise();
    }

    exportExcel(filter: ITimesheetFilter): Observable<ArrayBuffer> {
        return this.exportExcelService.fetchExcelFilterReportAndDownload(
            `/webfront${this.serviceUrl}`,
            filter,
            'timesheet.xlsx'
        );
    }

    async getLatestInProgressTimesheet(): Promise<any> {
        return this.httpService.get(this.serviceUrl + '/last-in-progress').toPromise();
    }

    reset(): void {
        this.currentGroupUuid = null;
        this.currentSessionUuid = null;
    }
}
