import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface MarketI {
    id?: number;
    code: string;
}

@Injectable({ providedIn: 'root' })
export class MarketService {
    private marketList: Array<MarketI>;
    private allMarketsSubject = new BehaviorSubject<MarketI[]>([]);
    public allMarkets$: Observable<MarketI[]> = this.allMarketsSubject.asObservable();
    private readonly SERVICE_URL = 'webfront/market';

    constructor(public httpClient: HttpClient) {
        this.fetchMarkets().subscribe();
    }

    get markets(): Array<MarketI> {
        return this.marketList;
    }

    fetchMarkets(): Observable<MarketI[]> {
        return this.httpClient.get<MarketI[]>(this.SERVICE_URL).pipe(
            tap((value: Array<MarketI>) => {
                this.marketList = value;
                this.allMarketsSubject.next(value);
            })
        );
    }

    getMarkets(): Observable<Array<MarketI>> {
        if (!this.marketList) {
            return this.fetchMarkets();
        }
        return of(this.marketList);
    }
}
