import { Injectable } from '@angular/core';
import { IMagazineSubscription, IMagazineSubscriptionFilter } from '../models/magazine';
import { Pageable } from '../providers/pagination-provider';
import { createStore } from '@ngneat/elf';
import { getEntity, upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import {
    createRequestsCacheOperator,
    isRequestCached,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';
import { HttpPaginationResponseI } from '../models/http';
import { AdminMagazineSubscriptionRepository } from './adminMagazineSubscription.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import * as _ from 'lodash';

interface IMagazineSubscriptionContainerState {
    id: number; // id of magazine
    pages: number[][]; // id of magazine subscription by page
    activeFilters: IMagazineSubscriptionFilter;
    pageable: Pageable;
    paginationData: PaginationData;
}

const store = createStore(
    { name: 'adminMagazineSubscriptionContainer' },
    withEntities<IMagazineSubscriptionContainerState>(),
    withRequestsCache<`admin-magazine-subscription-containers-${number}-${number}`>()
);

export const skipWhileAdminMagazineSubscriptionContainerCached = createRequestsCacheOperator(store);
export const CACHED_ADMIN_MAGAZINE_SUBSCRIPTION_CONTAINER_KEY = 'admin-magazine-subscription-containers';

@Injectable({ providedIn: 'root' })
export class AdminMagazineSubscriptionContainerRepository {
    constructor(private adminMagazineSubscriptionRepository: AdminMagazineSubscriptionRepository) {}

    getMagazineSubscriptionContainer(id: number): IMagazineSubscriptionContainerState {
        return store.query(getEntity(id));
    }

    clearCacheIfDistinctFiltersOrPageable(
        magazineId: number,
        filters: IMagazineSubscriptionFilter,
        pageable: Pageable
    ): void {
        const currentMagazine = store.query(getEntity(magazineId));
        if (
            currentMagazine &&
            (!_.isEqual(filters, currentMagazine.activeFilters) ||
                currentMagazine.pageable.size !== pageable.size ||
                currentMagazine.pageable.sort !== pageable.sort)
        ) {
            this.clearCacheOfMagazine(magazineId);
        }
    }

    clearCacheOfMagazine(magazineId: number): void {
        const currentMagazine = store.query(getEntity(magazineId));
        currentMagazine.pages.forEach((value, key) => {
            store.update(
                updateRequestCache(`${CACHED_ADMIN_MAGAZINE_SUBSCRIPTION_CONTAINER_KEY}-${magazineId}-${key}`, {
                    value: 'none'
                })
            );
        });
    }

    updateCurrentPageIfIsCached(magazineId: number, page: number): void {
        if (store.query(isRequestCached(`${CACHED_ADMIN_MAGAZINE_SUBSCRIPTION_CONTAINER_KEY}-${magazineId}-${page}`))) {
            const currentMagazine = store.query(getEntity(magazineId));
            this.adminMagazineSubscriptionRepository.updatePagination(
                magazineId,
                page,
                currentMagazine.pages[page],
                currentMagazine.paginationData
            );
        }
    }

    addPaginationMagazineSubscription(
        magazineId: number,
        paginationResponse: HttpPaginationResponseI<IMagazineSubscription[]>,
        pageable: Pageable,
        filters: IMagazineSubscriptionFilter
    ): void {
        const paginationData = {
            currentPage: paginationResponse.number,
            perPage: paginationResponse.size,
            total: paginationResponse.totalElements,
            lastPage: paginationResponse.totalPages
        };

        store.update(
            updateRequestCache(
                `${CACHED_ADMIN_MAGAZINE_SUBSCRIPTION_CONTAINER_KEY}-${magazineId}-${paginationResponse.number}`
            ),
            upsertEntitiesById(magazineId, {
                creator: id => {
                    const pages = [];
                    pages[paginationResponse.number] = paginationResponse.content.map(c => c.id);
                    return {
                        id,
                        activeFilters: { ...filters },
                        pageable: { ...pageable },
                        paginationData,
                        pages
                    };
                },
                updater: entity => {
                    const pages = [...entity.pages];
                    pages[paginationResponse.number] = paginationResponse.content.map(c => c.id);
                    return {
                        ...entity,
                        activeFilters: { ...filters },
                        pageable: { ...pageable },
                        paginationData,
                        pages
                    };
                }
            })
        );

        this.adminMagazineSubscriptionRepository.addPaginationMagazineSubscription(magazineId, paginationResponse);
    }
}
