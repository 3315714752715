import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticateService } from '../../services/authenticate.service';
import { GuardService } from '../../services/guard.service';

@Directive({
    selector: '[appGuardedRouterLink]'
})
export class GuardedRouterLinkDirective {
    constructor(
        private el: ElementRef,
        private authenticateService: AuthenticateService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private guardService: GuardService
    ) {}

    @Input() set appGuardedRouterLink(linkPath: string | string[]) {
        const linkPathList = typeof linkPath === 'string' ? [linkPath] : linkPath;

        for (let i = 0; i < linkPathList.length; i++) {
            const valid = this.checkAccess(linkPathList[i]);
            if (valid) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                break;
            } else if (!valid && i + 1 === linkPathList.length) {
                this.viewContainer.clear();
            }
        }
    }

    checkAccess(path: string): boolean {
        return this.guardService.checkAuthorizedToAccessRoutePath(path);
    }
}
