import { Injectable } from '@angular/core';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { createStore } from '@ngneat/elf';
import { addEntities, getAllEntities, selectAllEntities, withEntities } from '@ngneat/elf-entities';
import { AccountTreeGroupedI, AccountTreeI } from '../models/accounting';
import { BehaviorSubject } from 'rxjs';

const store = createStore(
    { name: 'accountingTree' },
    withEntities<AccountTreeI>(),
    withRequestsCache<'accounting-trees'>()
);

export const skipWhileAccountingTreeCached = createRequestsCacheOperator(store);
export const CACHED_ACCOUNTING_TREE_KEY = 'accounting-trees';

@Injectable({ providedIn: 'root' })
export class AccountingTreeRepository {
    accountingTrees$ = store.pipe(selectAllEntities());
    accountingTreesGroupedSubject = new BehaviorSubject<AccountTreeGroupedI[]>([]);

    getAccountingTreesGrouped(): AccountTreeGroupedI[] {
        return store.query(getAllEntities()).reduce((acc: AccountTreeGroupedI[], accountTree, i, self) => {
            if (!accountTree.parentId) {
                acc.push({ ...accountTree, children: [] });
            } else {
                const isItemParentNotAlreadyIndexed = self.slice(i).findIndex(item => item.id === accountTree.parentId);
                if (isItemParentNotAlreadyIndexed !== -1) {
                    (self[i] as AccountTreeGroupedI).children = [
                        ...((self[i] as AccountTreeGroupedI).children || []),
                        accountTree
                    ];
                } else {
                    this.putItemInGroupedAccountingTree(accountTree, acc);
                }
            }

            return acc;
        }, []);
    }

    putItemInGroupedAccountingTree(
        accountTree: AccountTreeI,
        accountingTreeGrouped: Array<AccountTreeGroupedI | AccountTreeI>
    ) {
        const index = accountingTreeGrouped.findIndex(item => item.id === accountTree.parentId);
        if (index !== -1) {
            (accountingTreeGrouped[index] as AccountTreeGroupedI).children = [
                ...((accountingTreeGrouped[index] as AccountTreeGroupedI).children || []),
                accountTree
            ];
        } else {
            accountingTreeGrouped.forEach((item, i, self) => {
                if ((item as AccountTreeGroupedI).children) {
                    this.putItemInGroupedAccountingTree(accountTree, (self[i] as AccountTreeGroupedI).children);
                }
            });
        }
    }

    addAccountingTrees(accountingTrees: AccountTreeI[]): void {
        store.update(updateRequestCache(CACHED_ACCOUNTING_TREE_KEY), addEntities(accountingTrees));
        this.accountingTreesGroupedSubject.next(this.getAccountingTreesGrouped());
    }
}
