import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { FileSaverService } from 'ngx-filesaver';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';

export const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
@Injectable({ providedIn: 'root' })
export class ExportExcelService {
    constructor(private httpService: HttpClient, private fileSaver: FileSaverService) {}

    /**
     * Fetches an excel report and downloads it
     * @param serviceUrl should be the url of the service that returns the excel report ex: /api/v1/clients
     * @param filename the name of the file to be downloaded ex: clients.xlsx
     * @param path the path of the service that returns the excel report ex: /clients
     */
    fetchExcelReportAndDownload(serviceUrl: string, filename?: string, path?: string): Observable<ArrayBuffer> {
        const url = serviceUrl + (!path ? '/filter.xlsx' : path);
        return this.httpService
            .get(url, { responseType: 'arraybuffer' })
            .pipe(tap((data: ArrayBuffer) => this.downloadFile(data, filename ?? 'export.xlsx')));
    }

    /**
     * Fetches an excel report specific report for a parameter in the route and downloads it
     *
     * @param serviceUrl should be the url of the service that returns the excel report ex: /api/v1/clients
     * @param parameter the parameter (usually and id) to be added to the url ex: 1
     * @param filename the name of the file to be downloaded ex: clients.xlsx
     * @param path the path of the service that returns the excel report ex: /clients
     */
    fetchExcelSpecificReportAndDownload(
        serviceUrl: string,
        parameter: string | number,
        filename?: string,
        path?: string
    ): Observable<ArrayBuffer> {
        parameter = parameter ? '/' + parameter : '';
        const url = serviceUrl + parameter + (!path ? '/filter.xlsx' : path);
        return this.httpService
            .get(url, { responseType: 'arraybuffer' })
            .pipe(tap((data: ArrayBuffer) => this.downloadFile(data, filename ?? 'export.xlsx')));
    }

    /**
     * Fetches an excel report with filter as body and downloads it
     *
     * @param serviceUrl should be the url of the service that returns the excel report ex: /api/v1/clients
     * @param filename the name of the file to be downloaded ex: clients.xlsx
     * @param filter the filter to be applied to the report as a body
     */
    fetchExcelFilterReportAndDownload(serviceUrl: string, filter: object, filename?: string): Observable<ArrayBuffer> {
        return this.httpService
            .post(`${serviceUrl}/filter.xlsx`, filter, {
                responseType: 'arraybuffer'
            })
            .pipe(tap((data: ArrayBuffer) => this.downloadFile(data, filename ?? 'export.xlsx')));
    }

    private downloadFile(data: ArrayBuffer, fileName: string): void {
        const blob = new Blob([data], { type: EXCEL_FILE_TYPE });
        this.fileSaver.save(blob, fileName);
    }
}
