import { Component } from '@angular/core';
import { MessageBus } from '../../messaging/MessageBus';
import { Configuration } from '../../constant/configuration';
import { NotificationButtonI } from '../../services/notification_message.service';

export const EVENT_NOTIFICATION_MESSAGE_INIT_TRANSLATION_VALUES = 'event:notificationMessage-initTranslationValues';

export const EVENT_NOTIFICATION_MESSAGE_ADD_LEFT_BUTTON = 'event:notificationMessage-addLeftButton';

export const EVENT_NOTIFICATION_MESSAGE_ADD_RIGHT_BUTTON = 'event:notificationMessage-addRightButton';

export const EVENT_NOTIFICATION_MESSAGE_SHOW = 'event:notificationMessage-show';

export const EVENT_NOTIFICATION_MESSAGE_INIT = 'event:notificationMessage-init';

export const UPDATE_MESSAGE = 'update';

// TODO: IVAN - REPLACE WITH BOOTSTRAP NOTIFICATION COMPONENT, IF CHANGES WILL BE REQIRERED HERE
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html'
})
export class NotificationComponent {
    notification: {
        dialogStyle: any;
        show: boolean;
        displayLeftButton: boolean;
        displayRightButton: boolean;
        rightButton?: NotificationButtonI;
        leftButton?: NotificationButtonI;
        titleTranslationValues?: any;
        messageTranslationValues?: any;
        title?: string;
        message?: string;
    };

    constructor(private messageBus: MessageBus, private configuration: Configuration) {
        this.notification = {
            dialogStyle: {},
            show: false,
            displayLeftButton: false,
            displayRightButton: false
        };
        messageBus.channel(configuration.messageBusChannels.NOTIFICATION_CHANNEL).subscribe(message => {
            switch (message.message) {
                case EVENT_NOTIFICATION_MESSAGE_INIT:
                    this.notification.title = message.data.title;
                    this.notification.message = message.data.message;
                    this.notification.displayRightButton = false;
                    // Default behavior : show a left button with 'OK' value
                    this.notification.displayLeftButton = true;
                    this.notification.leftButton = {};
                    this.notification.leftButton.label = 'global.forms.ok';
                    this.notification.leftButton.icon = 'ok-green-image-active';
                    break;

                case EVENT_NOTIFICATION_MESSAGE_INIT_TRANSLATION_VALUES:
                    this.notification.titleTranslationValues = message.data.title;
                    this.notification.messageTranslationValues = message.data.message;
                    break;

                case EVENT_NOTIFICATION_MESSAGE_ADD_LEFT_BUTTON:
                    this.notification.leftButton = message.data;

                    if (!message.data.icon) {
                        this.notification.leftButton.icon = 'ok-green-image-active';
                    }
                    this.notification.displayLeftButton = true;
                    break;

                case EVENT_NOTIFICATION_MESSAGE_ADD_RIGHT_BUTTON:
                    this.notification.rightButton = message.data;
                    if (!message.data.icon) {
                        this.notification.rightButton.icon = 'cancel-red-image-active';
                    }
                    this.notification.displayRightButton = true;
                    break;

                case EVENT_NOTIFICATION_MESSAGE_SHOW:
                    this.notification.show = true;
                    break;
            }
        });
    }

    close(button: any): any {
        if (button?.callback) {
            button.callback();
        }
        this.notification.show = false;
    }
}
