import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-back-in-history-button',
    templateUrl: './back-in-history-button.component.html',
    styleUrls: ['./back-in-history-button.component.scss']
})
export class BackInHistoryButtonComponent {
    @Input() isHistoryEmpty: Observable<boolean>;
    @Input() goBack: () => void;
}
