import { createStore, withProps, select, setProp } from '@ngneat/elf';
import { HeaderMenuInformationI } from '../models/headerMenuInformation';
import { Injectable } from '@angular/core';

interface HeaderMenuInformationState {
    information: HeaderMenuInformationI;
}

const store = createStore(
    { name: 'headerMenuInformation' },
    withProps<HeaderMenuInformationState>({
        information: {
            numberSearch: 0,
            numberDocument: 0,
            numberAlerts: 0,
            numberBooks: 0,
            numberFreeBooks: 0,
            numberStreamsAndBuckets: 0
        }
    })
);

@Injectable({ providedIn: 'root' })
export class HeaderMenuInformationRepository {
    information$ = store.pipe(select(state => state.information));

    updateInformation(payload: HeaderMenuInformationState['information']): void {
        store.update(setProp('information', information => ({ ...information, ...payload })));
    }

    incrementInformationNumberAlertsBy(payload: number): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberAlerts: information.numberAlerts + payload
            }))
        );
    }

    decrementInformationNumberAlertsBy(payload: number): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberAlerts: information.numberAlerts - payload
            }))
        );
    }

    incrementInformationNumberDocument(): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberDocument: information.numberDocument + 1
            }))
        );
    }

    decrementInformationNumberDocumentBy(payload: number): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberDocument: information.numberDocument - payload
            }))
        );
    }

    incrementInformationNumberStreamAndBuckets(): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberStreamsAndBuckets: information.numberStreamsAndBuckets + 1
            }))
        );
    }

    decrementInformationNumberStreamAndBuckets(): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberStreamsAndBuckets: information.numberStreamsAndBuckets - 1
            }))
        );
    }

    incrementInformationNumberSearch(): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberSearch: information.numberSearch + 1
            }))
        );
    }

    decrementInformationNumberSearchBy(payload: number): void {
        store.update(
            setProp('information', information => ({
                ...information,
                numberSearch: information.numberSearch - payload
            }))
        );
    }
}
