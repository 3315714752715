import { Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { MixinBase } from '../mixins/crud/MixinBase';

export class AbstractSearchService extends MixinBase {
    constructor(protected serviceUrl: string, protected httpService: HttpClientService) {
        super();
    }

    /**
     * Http request for searching
     * @param filter The search filter
     */
    async search(filter: any, pageable?: any): Promise<any> {
        const query: Observable<any> = this.httpService.post(this.serviceUrl + '/filter', filter, {
            params: pageable ? pageable : {}
        });
        return query.toPromise();
    }
}
export default AbstractSearchService;
