import { WorkspaceI } from '../models/workspace';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { syncState } from 'elf-sync-state';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

interface WorkspaceState {
    workspaces: WorkspaceI[];
    workspacesForCurrentMarket: WorkspaceI[];
}

const store = createStore(
    { name: 'workspace' },
    withProps<WorkspaceState>({ workspaces: [], workspacesForCurrentMarket: [] }),
    withRequestsCache<'workspace' | `workspace-${string}`>()
);

export const skipWhileWorkspaceCached = createRequestsCacheOperator(store);

export const CACHE_KEY_WORKSPACES = 'workspace';
export const CACHE_KEY_WORKSPACES_FOR_SESSION_MARKET = 'workspace-forCurrentMarket';

syncState(store);

@Injectable({ providedIn: 'root' })
export class WorkspaceRepository {
    workspaces$: Observable<WorkspaceI[]> = store.pipe(select(state => state.workspaces));
    workspacesForCurrentMarket$: Observable<WorkspaceI[]> = store.pipe(
        select(state => state.workspacesForCurrentMarket)
    );

    setWorkspaces(workspaces: WorkspaceI[]): void {
        store.update(
            updateRequestCache(CACHE_KEY_WORKSPACES),
            setProp('workspaces', () => workspaces)
        );
    }

    setWorkspacesForCurrentMarket(workspaces: WorkspaceI[]): void {
        store.update(
            updateRequestCache(CACHE_KEY_WORKSPACES_FOR_SESSION_MARKET),
            setProp('workspacesForCurrentMarket', () => workspaces)
        );
    }
}
