import { Component, Input } from '@angular/core';
import { FilteredPublicationsI, PublicationI } from '../../models/publication';
import { IPeriodic } from '../../models/magazine';
import { BookCardDTO } from '../../models/book';
import { Observable } from 'rxjs';
import { MARKET_CODE_LU, MarketI } from '../../models/market';

@Component({
    selector: 'app-latest-documents-tabs',
    templateUrl: './latest-documents-tabs.component.html'
})
export class LatestDocumentsTabsComponent {
    selectedCat: number;
    @Input() latestUpdateList: FilteredPublicationsI[] = [];
    @Input() allDocuments: PublicationI[];
    @Input() goToDocumentDetail: (publication: IPeriodic | BookCardDTO | PublicationI) => void;
    @Input() isDocumentConsulted: (publicationId: string | number) => Observable<boolean>;
    @Input() markets: MarketI[];

    get hasLuxembourgMarket(): boolean {
        return this.markets ? this.markets.find(market => market.code === MARKET_CODE_LU) != null : false;
    }
}
