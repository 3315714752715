import { createStore } from '@ngneat/elf';
import { addEntities, getEntity, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { BookTitleI } from '../models/book';

interface BookTitleState {
    id: string;
    bookTitles: BookTitleI[];
}

const store = createStore(
    { name: 'bookTitle' },
    withEntities<BookTitleState>(),
    withRequestsCache<'book-titles' | `book-titles-${string}`>()
);

export const skipWhileBookTitlesCached = createRequestsCacheOperator(store);
export const CACHED_BOOK_TITLES_KEY = 'book-titles';

@Injectable({ providedIn: 'root' })
export class BookTitleRepository {
    getBookTitle(id: string): BookTitleState {
        return store.query(getEntity(id));
    }

    addBookTitle(payload: BookTitleState): void {
        store.update(addEntities(payload), updateRequestCache(`${CACHED_BOOK_TITLES_KEY}-${payload.id}`));
    }
}
