import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { FiltersInitI } from '../models/filter';
import { selectEntity, upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

interface InitFiltersState {
    userUuid: string;
    initFilters?: FiltersInitI;
    initFiltersByKeys?: Record<string, FiltersInitI>;
}

const store = createStore(
    { name: 'initFilters' },
    withEntities<InitFiltersState, 'userUuid'>({ idKey: 'userUuid' }),
    withRequestsCache<`init-filters-${string}`>()
);

export const skipWhileInitFiltersCached = createRequestsCacheOperator(store);
export const CACHED_INIT_FILTERS_KEY = 'init-filters';
export const CACHED_INIT_FILTERS_BY_KEYS = 'init-filters-by-keys';

@Injectable({ providedIn: 'root' })
export class InitFiltersRepository {
    selectInitFilters$(userUuid: string): Observable<FiltersInitI> {
        return store.pipe(selectEntity(userUuid, { pluck: 'initFilters' }));
    }

    selectInitFiltersByKeys$(userUuid: string, keys: string): Observable<FiltersInitI> {
        return store.pipe(
            selectEntity(userUuid, { pluck: 'initFiltersByKeys' }),
            map(initFiltersByKeys => {
                return initFiltersByKeys[keys];
            })
        );
    }

    public upsertInitFilters(userUuid: string, initFilters: FiltersInitI): void {
        store.update(
            upsertEntitiesById(userUuid, {
                creator: () => ({ userUuid, initFilters }),
                updater: entity => ({
                    ...entity,
                    initFilters
                })
            }),
            updateRequestCache(`${CACHED_INIT_FILTERS_KEY}-${userUuid}`)
        );
    }

    public upsertInitFiltersByKeys(userUuid: string, keys: string, initFilters: FiltersInitI): void {
        store.update(
            upsertEntitiesById(userUuid, {
                creator: () => ({
                    userUuid,
                    initFiltersByKeys: {
                        [keys]: initFilters
                    }
                }),
                updater: entity => ({
                    ...entity,
                    initFiltersByKeys: {
                        ...(entity.initFiltersByKeys || {}),
                        [keys]: initFilters
                    }
                })
            }),
            updateRequestCache(`${CACHED_INIT_FILTERS_BY_KEYS}-${userUuid}-${keys}`)
        );
    }
}
