import { Configuration } from 'src/app/constant/configuration';
import { Injectable } from '@angular/core';
import { MessageBus } from '../messaging/MessageBus';

export const EVENT_RESTRICTED_ACCESS_MESSAGE_SHOW = 'event:restrictedAccessMessage-show';

interface RestrictedAccessPopupServicePayloadI {
    askToUpgrade?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class RestrictedAccessPopupService {
    private channel: string;

    constructor(private messageBus: MessageBus, configuration: Configuration) {
        this.channel = configuration.messageBusChannels.RESTRICTED_ACCESS_CHANNEL;
    }

    show(payload: RestrictedAccessPopupServicePayloadI = {}): void {
        this.messageBus
            .channel(this.channel)
            .next({ message: EVENT_RESTRICTED_ACCESS_MESSAGE_SHOW, data: { ...payload } });
    }
}
