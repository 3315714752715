import { AdministrationTranslationService } from '../online/administration/translations/service/administration-translation.service';
import { filter, first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * Will wait until the translations are loaded and then will call the callback
 */
export default function (translationService: AdministrationTranslationService): () => Observable<void> {
    return (): Observable<void> =>
        translationService.externalTranslations$.pipe(
            filter(translations => translations && translations.length > 0),
            first(),
            map(() => null)
        );
}
