import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { selectEntity, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { syncState } from 'elf-sync-state';
import { LicenseI } from '../models/license';
import { Observable } from 'rxjs/internal/Observable';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';

interface LicenseCompanyStateI {
    companyUuid: string;
    licenses: LicenseI[];
}

const store = createStore(
    { name: 'licenseCompany' },
    withEntities<LicenseCompanyStateI, 'companyUuid'>({ idKey: 'companyUuid' }),
    withRequestsCache<`license-companies-${string}`>()
);
export const skipWhileLicenseCompanyCached = createRequestsCacheOperator(store);
export const CACHE_KEY_LICENSE_COMPANY = 'license-companies';

syncState(store);

@Injectable({ providedIn: 'root' })
export class LicenseCompanyRepository {
    getLicensesByCompanyUuid$(companyUuid: string): Observable<LicenseI[]> {
        return store.pipe(selectEntity(companyUuid, { pluck: 'licenses' }));
    }

    upsertLicensesByCompanyUuid(companyUuid: string, licenses: LicenseI[]): void {
        store.update(
            updateRequestCache(`${CACHE_KEY_LICENSE_COMPANY}-${companyUuid}`),
            upsertEntities({ companyUuid, licenses })
        );
    }

    clearCacheOfCompany(companyUuid: string): void {
        store.update(updateRequestCache(`${CACHE_KEY_LICENSE_COMPANY}-${companyUuid}`, { value: 'none' }));
    }
}
