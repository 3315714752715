import { Component } from '@angular/core';

@Component({
    selector: 'app-search-icon',
    templateUrl: './search-icon.component.svg',
    styles: [
        `
            svg {
                width: 5em;
                height: 4em;
            }
        `
    ]
})
export class SearchIconComponent {}
