import { DataGuardI } from '../models/guard';
import { MarketE } from '../models/market';

/**
 * With Angular Router, we use lazy loading of routes
 * Then, every routes declared in a lazy xxx-routing.module.ts will be not accessible at the runtime
 * until user navigate to this route or when it will be fetched later with the "preloadingStrategy" declared in "AppRoutingModule"
 * so the "data guards" declared in a lazy xxx-routing.module.ts will be not be accessible also in others place of the application
 * Exemple in [Directive] GuardedRouterLinkDirective where we need to check those guards
 * It's Why we put it here, so it can be accessible anywhere in the application
 */

const onlineGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/lexprh': {
        props: { profileType: 'lexprh' }
    },
    'o/catalogue': {
        permissions: ['LIBRARY:READ']
    },
    'o/search': {
        permissions: ['DOCUMENT_SEARCH:READ']
    },
    'o/perform-search': {
        permissions: ['DOCUMENT_FILTER:SEARCH']
    },
    'o/password': {
        permissions: ['USER:RESET_PASSWORD']
    },
    'o/alert': {
        permissions: ['FOLDER_ALERT:READ']
    },
    'o/folder': {
        permissions: ['FOLDER:READ']
    },
    'o/statistics/keywords': {
        permissions: ['STATISTIC_KEYWORD:READ']
    },
    'o/statistics/connections': {
        permissions: ['STATISTIC_CONNECTION:READ']
    },
    'o/timesheet': {
        permissions: ['TIMESHEET:CREATE'],
        props: { ff_timer_module: 'true' }
    },
    'o/legislations-codes': {
        props: { legislations_codes_access: 'true' }
    },
    'o/lex-gain': {
        props: { lex_ai_search_view: 'true' }
    }
};

const streamAndBucketGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/streams-and-buckets/factory': {
        permissions: ['STREAMS_BUCKETS:READ'],
        props: { sb_creation: 'true' },
        markets: [MarketE.BE]
    },
    'o/streams-and-buckets/stream-factory/:x': {
        permissions: ['STREAM:READ']
    },
    'o/streams-and-buckets/stream/:x': {
        permissions: ['STREAM:READ']
    },
    'o/streams-and-buckets/bucket-factory/:x': {
        props: { sb_creation: 'true' }
    },
    'o/streams-and-buckets/bucket/:x': {
        permissions: ['BUCKET:READ'],
        props: { sb_view: 'true' }
    },
    'o/streams-and-buckets/marketplace': {
        permissions: ['STREAMS_BUCKETS:READ'],
        props: { sb_view: 'true' },
        markets: [MarketE.BE]
    },
    'o/streams-and-buckets/my-flows': {
        permissions: ['STREAMS_BUCKETS:READ'],
        props: { sb_view: 'true' },
        markets: [MarketE.BE]
    }
};

const administrationGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/admin/magazine': {
        permissions: ['ADMIN_MAGAZINE:READ']
    },
    'o/admin/features': {
        permissions: ['ADMIN:ADMIN_FEATURES_ACCESS']
    },
    'o/admin/lexnow-users': {
        permissions: ['LEXNOW_USERS:ACCESS']
    },
    'o/admin/magazine/:x/periodic': {
        permissions: ['ADMIN_PERIODIC:READ', 'ADMIN_MAGAZINE:READ']
    },
    'o/admin/magazine/:x/subscription': {
        permissions: ['ADMIN_MAGAZINE:READ', 'COMPANY_MAGAZINE_SUBSCRIPTION:READ']
    },
    'o/admin/licenses': {
        permissions: ['LICENSE:READ']
    },
    'o/admin/my-licenses': {
        permissions: ['LICENSE_COMPANY:READ'],
        markets: [MarketE.LU]
    },
    'o/admin/my-book-license': {
        permissions: ['BOOK_LICENSE:READ'],
        markets: [MarketE.LU]
    },
    'o/admin/my-subscriptions': {
        permissions: ['COMPANY_MAGAZINE_SUBSCRIPTION:READ']
    },
    'o/admin/users': {
        permissions: ['SESSION_COMPANY:READ_USERS']
    },
    'o/admin/users/active-legal-professionals': {
        permissions: ['SESSION_COMPANY:UPDATE'],
        markets: [MarketE.BE]
    },
    'o/admin/users/new-invitations': {
        permissions: ['SESSIONS_COMPANY:INVITE_USERS'],
        markets: [MarketE.BE]
    },
    'o/admin/users/pending-invitations': {
        permissions: ['SESSIONS_COMPANY:INVITE_USERS'],
        markets: [MarketE.BE]
    },
    'o/admin/users/free-user-invitations': {
        permissions: ['SESSIONS_COMPANY:INVITE_USERS']
    },
    'o/admin/my-company': {
        permissions: ['SESSION_COMPANY:READ']
    },
    'o/admin/translations': {
        permissions: ['TRANSLATION:FILTER']
    }
};

const administrationBookGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/admin/book': {
        permissions: ['BOOK_TITLE:READ', 'ADMIN_BOOK:READ']
    },
    'o/admin/book/create': {
        permissions: ['ADMIN_BOOK:CREATE']
    },
    'o/admin/book/:x/new-version': {
        permissions: ['ADMIN_BOOK:CREATE']
    },
    'o/admin/book/:x/edit': {
        permissions: ['ADMIN_BOOK:UPDATE']
    },
    'o/admin/book/:x/used-codes': {
        permissions: ['BOOK_LICENSE:UPDATE', 'ADMIN_BOOK_LICENSE:READ']
    },
    'o/admin/book/:x/codes': {
        permissions: ['ACTIVATION_CODE:READ']
    },
    'o/admin/book/:x/codes/:x': {
        permissions: ['ACTIVATION_CODE:READ']
    }
};

const administrationCompanyGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/admin/company': {
        permissions: ['COMPANY:READ']
    },
    'o/admin/company/:x/properties': {
        permissions: ['CONFIG_ENTRY:READ_FROM_COMPANY', 'CONFIG_ENTRY:UPDATE_FROM_COMPANY']
    },
    'o/admin/company/:x/licenses': {
        permissions: ['LICENSE_COMPANY:READ_ALL']
    },
    'o/admin/company/:x/administrators': {
        permissions: ['ADMIN_COMPANY_USER:READ']
    },
    'o/admin/company/:x/books': {
        permissions: ['ADMIN_COMPANY_BOOK:READ']
    },
    'o/admin/company/:x/administrators/:x/properties': {
        permissions: ['CONFIG_ENTRY:READ_FROM_USER', 'CONFIG_ENTRY:UPDATE_FROM_USER']
    },
    'o/admin/company/:x/administrators/:x/new-user-invitations': {
        permissions: ['COMPANY:USER_INVITATION_MANAGE']
    },
    'o/admin/company/:x/administrators/:x/pending-user-invitations': {
        permissions: ['COMPANY:USER_INVITATION_MANAGE']
    }
};

const documentGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/document/result': {
        permissions: ['DOCUMENT_FILTER:SEARCH']
    },
    'o/document/details/:x': {
        permissions: ['DOCUMENT:READ']
    },
    'o/document/details/:x/compare': {
        permissions: ['DOCUMENT:COMPARE']
    },
    'o/document/details/:x/links': {
        permissions: ['DOCUMENT_LINK:READ']
    },
    'o/document/details/:x/translate': {
        permissions: ['TRANSLATOR_DOCUMENT:READ']
    }
};

const bookGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/book/detail/:x': {
        permissions: ['BOOK:READ']
    },
    'o/book/detail/:x/translate': {
        permissions: ['TRANSLATOR_DOCUMENT:READ']
    },
    'o/book/detail/:x/purchase': {
        permissions: ['BOOK:PURCHASE']
    }
};

const accountingGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/accounting': {
        props: { accounting_access: 'true' }
    }
};

const lexchatGuardedRoutes: { [key: string]: DataGuardI } = {
    'o/lexchat': {
        props: { lexchat_access: 'true' }
    }
};

const routesDataGuards: { [key: string]: DataGuardI } = {
    ...onlineGuardedRoutes,
    ...streamAndBucketGuardedRoutes,
    ...administrationGuardedRoutes,
    ...administrationBookGuardedRoutes,
    ...administrationCompanyGuardedRoutes,
    ...documentGuardedRoutes,
    ...bookGuardedRoutes,
    ...accountingGuardedRoutes,
    ...bookGuardedRoutes,
    ...lexchatGuardedRoutes
};
export default routesDataGuards;
