import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatHits'
})
export class FormatHitsPipe implements PipeTransform {
    MAXIMUM_RESULTS = 1000000;

    transform(hits: number): string {
        if (hits == null || hits === 0) {
            return '';
        }
        if (hits >= this.MAXIMUM_RESULTS) {
            return this.wrapBetweenParenthesis('1M +');
        }
        if (hits < 1000) {
            return this.wrapBetweenParenthesis(hits);
        }

        const ks = Math.floor(hits / 1000);
        return this.wrapBetweenParenthesis(ks + 'k');
    }

    wrapBetweenParenthesis(toWrap: string | number): string {
        return '(' + toWrap + ')';
    }
}
