import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-field-errors-display',
    templateUrl: './field-errors-display.component.html',
    styleUrls: ['./field-errors-display.component.scss']
})
export class FieldErrorsDisplayComponent {
    @Input() control: UntypedFormControl | AbstractControl;
}
