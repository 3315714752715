import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface Message {
    message?: string;
    data?: any;
}
@Injectable({ providedIn: 'root' })
export class MessageBus {
    private channels: Map<string, Subject<Message>> = new Map();

    channel(channel: string): Subject<Message> {
        this.lazyInitChannel(channel);
        return this.channels.get(channel);
    }

    private lazyInitChannel(channel: string): void {
        if (this.channels.get(channel) == null) {
            this.channels.set(channel, new Subject<Message>());
        }
    }
}
