import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { AdministrationTranslationService } from '../online/administration/translations/service/administration-translation.service';
import { Injector } from '@angular/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    constructor(public injector: Injector) {}

    handle(params: MissingTranslationHandlerParams): string {
        return this.injector.get(AdministrationTranslationService).findTranslation(params.key);
    }
}
