import { Injectable } from '@angular/core';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { createStore } from '@ngneat/elf';
import { addEntities, selectEntity, withEntities } from '@ngneat/elf-entities';
import { RoleI } from '../models/role';
import { Observable } from 'rxjs';

interface IComparyRoleState {
    companyUuid: string;
    roles: RoleI[];
}

const store = createStore(
    { name: 'companyRole' },
    withEntities<IComparyRoleState, 'companyUuid'>({ idKey: 'companyUuid' }),
    withRequestsCache<`company-roles-${string}`>()
);

export const skipWhileCompanyRoleCached = createRequestsCacheOperator(store);
export const CACHED_COMPANY_ROLE_KEY = 'company-roles';

@Injectable({ providedIn: 'root' })
export class CompanyRoleRepository {
    getRolesByCompany$(companyUuid: string): Observable<RoleI[]> {
        return store.pipe(selectEntity(companyUuid, { pluck: 'roles' }));
    }

    clearCacheOfCompany(companyUuid: string): void {
        store.update(updateRequestCache(`${CACHED_COMPANY_ROLE_KEY}-${companyUuid}`, { value: 'none' }));
    }

    addRoles(companyUuid: string, roles: RoleI[]): void {
        store.update(
            updateRequestCache(`${CACHED_COMPANY_ROLE_KEY}-${companyUuid}`),
            addEntities({
                companyUuid,
                roles
            })
        );
    }
}
