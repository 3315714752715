import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
    enableProdMode();
}

void platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(ref => {
        // Ensure Angular destroys itself on hot reloads.
        if (window[`ngRef`]) {
            window[`ngRef`].destroy();
        }
        window[`ngRef`] = ref;
        // Otherwise, log the boot error
    })
    .catch(err => console.error(err));
