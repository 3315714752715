import { Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { InfoPopupComponent } from './infopopup/infopopup.component';
import { TimesheetRecordingService } from './service/timesheet.recording.service';
import { TimesheetSharedService } from './service/timesheet.shared.service';
import { MessageBus } from '../../../messaging/MessageBus';
import Callback = JQuery.Deferred.Callback;
import { NotificationMessageService } from '../../../services/notification_message.service';
import { Configuration } from '../../../constant/configuration';

@Component({
    selector: 'app-timesheet-recording',
    templateUrl: './timesheet.component.html',
    styleUrls: ['./timesheet.component.scss']
})
export class TimesheetRecordingComponent implements OnDestroy {
    timerTask;
    autosaveTask;

    disconnectEvent;

    @ViewChild(InfoPopupComponent, { static: false })
    public infoPopupComp: InfoPopupComponent;

    constructor(
        public service: TimesheetRecordingService,
        public sharedService: TimesheetSharedService,
        public dialogService: NotificationMessageService,
        public configuration: Configuration,
        public messageBus: MessageBus
    ) {
        void service.getLatestInProgressTimesheet().then(t => {
            if (t) {
                this.showContinueCurrentTimerDialog(
                    () => {
                        this.service.currentGroupUuid = t.groupUuid;
                        this.sharedService.elapsedTime = t.durationInSeconds * 1000;
                        this.sharedService.updateTimerWithFormattedTime(this.sharedService.elapsedTime, false);
                        this.sharedService.setReferenceDescription(t.reference, t.description);
                        this.infoPopupComp.setReferenceDescription(t.reference, t.description);
                        this.startTimer();
                    },
                    () => {
                        // maybe show dialog before delete
                        void this.service.updateTimesheetGroup({
                            groupUuid: t.groupUuid,
                            status: configuration.timesheetStatus.DELETED
                        });
                    }
                );
            }
        });
        this.disconnectEvent = messageBus.channel(configuration.messageBusChannels.TIMESHEET_CHANNEL).subscribe(e => {
            if (this.sharedService.isRunning) {
                this.pauseTimer();
                this.showTimerRunningDialog(() => this.startTimer());
            } else {
                e.data(); // disconnect callback
            }
        });
        this.startAutoSave();
    }

    startAutoSave(): void {
        this.autosaveTask = setInterval(() => {
            if (this.service.currentSessionUuid && this.sharedService.isRunning) {
                void this.service.updateTimesheet({
                    sessionUuid: this.service.currentSessionUuid,
                    updateEndTime: true
                });
            }
        }, 1000 * 60);
    }

    startTimer(): void {
        if (!this.sharedService.isRunning && this.canTimerStart()) {
            this.sharedService.isRunning = true;
            const startTime = Date.now() - this.sharedService.elapsedTime;
            this.timerTask = setInterval(() => {
                this.sharedService.elapsedTime = Date.now() - startTime;
                this.sharedService.updateTimerWithFormattedTime(this.sharedService.elapsedTime, false);
            }, 1000);
            void this.service.createTimesheet({
                reference: this.sharedService.reference,
                description: this.sharedService.description,
                deltaTimeSeconds: this.sharedService.deltaTime
            });
        }
    }

    updateCurrentTimesheetSessionTime(): void {
        if (this.service.currentSessionUuid) {
            void this.service.updateTimesheet({
                sessionUuid: this.service.currentSessionUuid,
                updateEndTime: true
            });
        }
    }

    pauseTimer(): void {
        clearInterval(this.timerTask);
        this.updateCurrentTimesheetSessionTime();
        this.service.currentSessionUuid = null; // this session should be dropped
        this.sharedService.resetDeltaTime();
        this.sharedService.isRunning = false;
    }

    saveTimer(): void {
        this.pauseTimer(); // saves current instance of timer
        this.showSaveTimerConfirmation(
            () => {
                void this.service.updateTimesheetGroup({
                    groupUuid: this.service.currentGroupUuid,
                    status: this.configuration.timesheetStatus.SAVED
                }); // sets current group of timesheets as saved
                this.service.reset();
                this.infoPopupComp.reset();
                this.sharedService.resetTimer();
                this.sharedService.resetDeltaTime();
            },
            () => {
                // continues timer
                this.startTimer();
            }
        );
    }

    deleteTimer(): void {
        if (this.sharedService.isRunning) {
            this.pauseTimer();
        }
        this.showDeleteDialog(
            () => {
                clearInterval(this.timerTask);
                this.sharedService.resetTimer();
                this.sharedService.resetDeltaTime();
                void this.service.updateTimesheetGroup({
                    groupUuid: this.service.currentGroupUuid,
                    status: this.configuration.timesheetStatus.DELETED
                });
                this.infoPopupComp.reset();
                this.sharedService.isRunning = false;
                this.service.reset();
            },
            () => {
                this.startTimer();
            }
        );
    }

    canTimerStart(): boolean {
        if (this.sharedService.description.length <= 0 || this.sharedService.reference.length <= 0) {
            this.showAllFieldsMustBeFilledDialog(() => this.showPopUp());
            return false;
        }
        return true;
    }

    showPopUp(): void {
        this.pauseTimer();
        this.infoPopupComp.show();
    }

    showDeleteDialog(callbackYes: Callback<any>, callbackNo: Callback<any>): void {
        this.dialogService.init('timesheet.dialog.titleDeleteTimer', 'timesheet.dialog.deleteTimer');
        this.dialogService.addLeftButton({ label: 'global.forms.yes', callback: callbackYes });
        this.dialogService.addRightButton({ label: 'global.forms.no', callback: callbackNo });
        this.dialogService.show();
    }

    showAllFieldsMustBeFilledDialog(callback: Callback<any>): void {
        this.dialogService.init('timesheet.dialog.titleFillFields', 'timesheet.dialog.fillFields');
        this.dialogService.addLeftButton({ label: 'global.forms.ok', callback });
        this.dialogService.show();
    }

    showContinueCurrentTimerDialog(callbackYes: Callback<any>, callbackNo: Callback<any>): void {
        this.dialogService.init('timesheet.dialog.titleOpenTimer', 'timesheet.dialog.openTimer');
        this.dialogService.addLeftButton({ label: 'global.forms.ok', callback: callbackYes });
        this.dialogService.addRightButton({ label: 'global.forms.no', callback: callbackNo });
        this.dialogService.show();
    }

    showSaveTimerConfirmation(callbackYes: Callback<any>, callbackNo: Callback<any>): void {
        this.dialogService.init('timesheet.dialog.titleSaveTimer', 'timesheet.dialog.saveTimer');
        this.dialogService.addLeftButton({ label: 'global.forms.yes', callback: callbackYes });
        this.dialogService.addRightButton({ label: 'global.forms.no', callback: callbackNo });
        this.dialogService.show();
    }

    showTimerRunningDialog(callbackYes: Callback<any>): void {
        this.dialogService.init('timesheet.dialog.timerRunningTitle', 'timesheet.dialog.timerRunning');
        this.dialogService.addLeftButton({ label: 'global.forms.ok', callback: callbackYes });
        this.dialogService.show();
    }

    @HostListener('window:beforeunload', ['$event'])
    closingBrowserHandler(event): boolean {
        if (this.sharedService.isRunning) {
            event.preventDefault();
            event.returnValue = 'There is an open timer stopping close';
            this.pauseTimer();
            this.showTimerRunningDialog(() => this.startTimer());
        }
        return true;
    }

    ngOnDestroy(): void {
        if (this.sharedService.isRunning) {
            this.pauseTimer();
        }
        clearInterval(this.timerTask);
        clearInterval(this.autosaveTask);
        this.disconnectEvent?.unsubscribe();
    }
}
