import { Injectable } from '@angular/core';
import { Userpilot } from 'userpilot';
import { Configuration } from '../constant/configuration';
import { CurrentUserI } from '../models/user';
import { NavigationEnd, Router } from '@angular/router';

interface UserUserPilotI {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    language: string;
    company: CompanyUserPilotI;
    markets: string;
    created_at: string;
}

interface CompanyUserPilotI {
    id: string;
    name: string;
    activity: string;
}

@Injectable({ providedIn: 'root' })
export class UserPilotService {
    constructor(configuration: Configuration, router: Router) {
        Userpilot.initialize(configuration.userPilot.token);
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                Userpilot.reload();
            }
        });
    }

    identify(rawUser: CurrentUserI): void {
        const user: UserUserPilotI = {
            email: rawUser.email,
            firstName: rawUser.firstname,
            lastName: rawUser.name,
            language: rawUser.language,
            company: {
                id: rawUser.companyUuid,
                name: rawUser.companyName,
                activity: rawUser.companySector
            },
            role: rawUser.roles.join(' | '),
            markets: rawUser.marketsCodes.join(' | '),
            created_at: rawUser.createdTime
        };
        Userpilot.identify(rawUser.uuid, { ...user });
    }

    closeSession(): void {
        Userpilot.clean();
    }
}
