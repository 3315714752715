import { createStore } from '@ngneat/elf';
import { selectAllEntities, setEntities, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { LangI } from '../models/lang';

const store = createStore({ name: 'Language' }, withEntities<LangI>(), withRequestsCache<'Languages'>());

export const skipWhileLanguagesCached = createRequestsCacheOperator(store);
export const CACHED_LANGUAGES_KEY = 'Languages';

@Injectable({ providedIn: 'root' })
export class LanguageRepository {
    languages$ = store.pipe(selectAllEntities());

    setLanguages(payload: LangI[]): void {
        store.update(updateRequestCache(CACHED_LANGUAGES_KEY), setEntities(payload));
    }
}
