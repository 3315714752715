import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { selectAllEntities, setEntities, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { LawBranchDTO } from '../models/lawBranch';

const store = createStore(
    { name: 'lawSubBranche' },
    withEntities<LawBranchDTO>(),
    withRequestsCache<'lawSubBranches'>()
);
export const skipWhileLawSubBranchesCached = createRequestsCacheOperator(store);
export const CACHE_KEY_LAW_SUB_BRANCHES = 'lawSubBranches';

@Injectable({ providedIn: 'root' })
export class LawBranchSubBranchRepository {
    lawSubBranches$ = store.pipe(selectAllEntities());

    setLawSubBranches(licenses: LawBranchDTO[]): void {
        store.update(updateRequestCache(CACHE_KEY_LAW_SUB_BRANCHES), setEntities(licenses));
    }
}
