import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { deleteEntities, getEntity, updateEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import {
    PaginationData,
    selectCurrentPageEntities,
    selectPaginationData,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination
} from '@ngneat/elf-pagination';
import { HttpPaginationResponseI } from '../models/http';
import { CompanyManagerI } from '../models/companyManager';
import { UserStatusE } from '../models/user';
import { LicenseCompanyRepository } from './licenseCompany.repository';
import * as _ from 'lodash';

interface ICompanyManagerState extends CompanyManagerI {
    uuid: string;
}

const store = createStore(
    { name: 'adminCompanyManager' },
    withEntities<ICompanyManagerState, 'uuid'>({ idKey: 'uuid' }),
    withPagination()
);

@Injectable({ providedIn: 'root' })
export class AdminCompanyManagerRepository {
    companyManagersOnCurrentPage$ = store.pipe(selectCurrentPageEntities());
    selectCurrentPaginationData$ = store.pipe(selectPaginationData());
    currentCompanyUuid: string;

    constructor(private licenseCompanyRepository: LicenseCompanyRepository) {}

    getCompanyManager(uuid: string): CompanyManagerI {
        return store.query(getEntity(uuid));
    }

    upsertCompanyManager(payload: CompanyManagerI): void {
        const currentCompanyManager = this.getCompanyManager(payload.uuid);
        if (currentCompanyManager && !_.isEqual(currentCompanyManager.license, payload.license)) {
            this.licenseCompanyRepository.clearCacheOfCompany(payload.companyUuid);
        }
        store.update(upsertEntities(payload));
    }
    softDeleteCompanyManager(uuid: string): void {
        store.update(
            updateEntities(uuid, {
                status: UserStatusE.DELETED,
                license: null
            })
        );
    }

    recoverDeletedCompanyManager(uuid: string): void {
        store.update(
            updateEntities(uuid, {
                status: UserStatusE.ACTIVE
            })
        );
    }

    deleteCompanyManager(uuid: string): void {
        store.update(deleteEntities(uuid));
    }

    addPaginationCompanyManagers(
        companyUuid: string,
        paginationResponse: HttpPaginationResponseI<CompanyManagerI[]>
    ): void {
        this.currentCompanyUuid = companyUuid;
        store.update(
            upsertEntities(paginationResponse.content),
            updatePaginationData({
                currentPage: paginationResponse.number,
                perPage: paginationResponse.size,
                total: paginationResponse.totalElements,
                lastPage: paginationResponse.totalPages
            }),
            setPage(
                paginationResponse.number,
                paginationResponse.content.map(c => c.uuid)
            ),
            setCurrentPage(paginationResponse.number)
        );
    }

    updatePagination(companyUuid: string, page: number, uuids: string[], paginationData: PaginationData): void {
        this.currentCompanyUuid = companyUuid;
        store.update(updatePaginationData(paginationData), setPage(page, uuids), setCurrentPage(page));
    }
}
