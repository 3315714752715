import { Pageable } from '../../../mixins/pagination/pagination.mixin';
import { FilteredPublicationsI, PublicationFilterI } from '../../../models/publication';
import { DocumentFilterService } from './document-filter.service';
import { PaginationProvider } from '../../../providers/pagination-provider';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

export class TabPagination {
    public paginationProvider: PaginationProvider<Observable<FilteredPublicationsI>>;
    filter: PublicationFilterI;

    constructor(private documentFilterService: DocumentFilterService) {
        this.paginationProvider = new PaginationProvider<Observable<FilteredPublicationsI>>(
            this.loadPageableData.bind(this)
        );
        this.paginationProvider.setupPagination(100, 1);
        this.paginationProvider.pagination.sort = 'RELEVANCE';
        this.paginationProvider.pagination.size = 12;
    }

    get pagination(): Pageable {
        return this.paginationProvider.pagination;
    }

    get totalItems(): number {
        return this.paginationProvider.totalItems;
    }

    firstPage(filter: PublicationFilterI): Observable<FilteredPublicationsI> {
        this.filter = filter;
        return this.paginationProvider.firstPage();
    }

    nextPage(filter: PublicationFilterI): Observable<FilteredPublicationsI> {
        this.filter = filter;
        return this.paginationProvider.nextPage();
    }

    previousPage(filter: PublicationFilterI): Observable<FilteredPublicationsI> {
        this.filter = filter;
        return this.paginationProvider.previousPage();
    }

    loadPageableData(): Observable<FilteredPublicationsI> {
        const concreteFilter = { ...this.filter };
        this.documentFilterService.baseFilter.pagination = this.pagination;
        concreteFilter.pagination = this.pagination;

        return this.documentFilterService.fetchSearchResultsFromServer(concreteFilter).pipe(
            tap(data => {
                if (concreteFilter.attributes.type != null && concreteFilter.attributes.type[0] != null) {
                    this.documentFilterService.hitsOnTab[concreteFilter.attributes.type[0]].next({
                        hits: data.facets,
                        total: data.total
                    });
                }
                this.paginationProvider.updatePagination(data.total);
                this.documentFilterService.facets = data.facets;
                const tabId =
                    concreteFilter.attributes.type !== undefined && concreteFilter.attributes.type.length > 0
                        ? concreteFilter.attributes.type[0]
                        : 'ALL';

                const newResult = [...this.documentFilterService.resultsByTab[tabId].value, ...data.publicationList];
                this.documentFilterService.resultsByTab[tabId].next(newResult);
            })
        );
    }
}
