import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BookSortE } from '../../../../models/book';

interface ISortField {
    label: string;
    value: BookSortE;
}

@Component({
    selector: 'app-sort-by-filter',
    templateUrl: './sort-by-filter.component.html'
})
export class SortByFilterComponent implements OnChanges {
    @Input() sortFields: Array<ISortField>;
    @Output() sortChanged: EventEmitter<string> = new EventEmitter<string>();
    @Input() sort;
    isDesc = false;
    sortByFormControl: UntypedFormControl = new UntypedFormControl(null);

    changeAscending(): void {
        this.isDesc = !this.isDesc;
        this.onSort();
    }
    onSort(): void {
        const desc = this.isDesc ? ',DESC' : '';
        this.sortChanged.emit(this.sortByFormControl.value + desc);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sort && changes.sort.currentValue) {
            const sortValue: string = changes.sort.currentValue as string;
            this.isDesc = sortValue.includes('DESC');
            this.sortByFormControl.setValue(sortValue.split(',')[0]);
        }
    }
}
