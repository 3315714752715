import { createStore } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { addEntitiesFifo, getEntitiesCount, selectEntity, selectFirst, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Observable } from 'rxjs';

export interface BookPartContent {
    id: string; // Because a same bookPart can have multiple translation, the id will be a string like : "bookPartId + '-' + language_key"
    bookPartContent: Blob;
}

const bookPartContentStore = createStore(
    { name: 'bookPartContents' },
    withEntities<BookPartContent>({ initialValue: [] }),
    withRequestsCache<'bookPartContent' | `bookPartContent-${string}`>()
);

const storeMaxSize = 4;

export const skipWhileBookPartContentsCached = createRequestsCacheOperator(bookPartContentStore);
@Injectable({ providedIn: 'root' })
export class BookPartContentRepository {
    addBookPartContent(bookPartId: number, lang: string, content: Blob): void {
        const bookPartContentId: string = lang ? bookPartId.toString() + '-' + lang : bookPartId.toString();

        // Check if the store is already full, delete cache if needed
        if (bookPartContentStore.query(getEntitiesCount()) >= storeMaxSize) {
            bookPartContentStore
                .pipe(selectFirst())
                .subscribe(first =>
                    bookPartContentStore.update(updateRequestCache(`bookPartContent-${first.id}`, { value: 'none' }))
                )
                .unsubscribe();
        }
        // Creating the entity in store and delete if needed
        bookPartContentStore.update(
            updateRequestCache(`bookPartContent-${bookPartContentId}`),
            addEntitiesFifo([{ id: bookPartContentId, bookPartContent: content }], { limit: storeMaxSize })
        );
    }

    subscribeByBookPartId(bookPartId: number, lang: string): Observable<Blob> {
        const bookPartContentId: string = lang ? bookPartId.toString() + '-' + lang : bookPartId.toString();
        return bookPartContentStore.pipe(
            selectEntity(bookPartContentId, { pluck: bookPartContent => bookPartContent.bookPartContent })
        );
    }
}
