import { createStore } from '@ngneat/elf';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { IJurisquareUser } from '../models/user';
import { addEntities, selectEntity, withEntities } from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IJurisquareUserState {
    id: string; // uuid of user
    jurisquareUser: IJurisquareUser;
}

const store = createStore(
    { name: 'jurisquareUser' },
    withEntities<IJurisquareUserState>(),
    withRequestsCache<`jurisquare-users-${string}`>()
);

export const skipWhileJurisquareUserCached = createRequestsCacheOperator(store);
export const CACHED_JURISQUARE_USER_KEY = 'jurisquare-users';

@Injectable({ providedIn: 'root' })
export class JurisquareUserRepository {
    selectJurisquareUserByUuid(uuid: string): Observable<IJurisquareUser> {
        return store.pipe(selectEntity(uuid, { pluck: 'jurisquareUser' }));
    }
    addJurisquareUser(payload: IJurisquareUserState): void {
        store.update(addEntities(payload), updateRequestCache(`${CACHED_JURISQUARE_USER_KEY}-${payload.id}`));
    }
}
