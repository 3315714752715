import { UUID_REGEXP } from './regexpPatterns';

export function toCamelCaseString(str: string): string {
    return toCamelCase(toWords(str));
}

function toCamelCase(inputArray: string[]): string {
    let result = '';

    for (let i = 0, len = inputArray.length; i < len; i++) {
        const currentStr = inputArray[i];

        let tempStr = currentStr.toLowerCase();

        if (i !== 0) {
            // convert first letter to upper case (the word is in lowercase)
            tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        }

        result += tempStr;
    }

    return result;
}

function toWords(input: string): string[] {
    const regex =
        /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
    return input.match(regex);
}

export const stringIsUuid = (payload: string): boolean => {
    const regex = new RegExp(UUID_REGEXP);
    return regex.test(payload);
};

export const removeAccents = (text: string): string => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const correctEncodedString = (text: string): string => {
    if (!text) {
        return '';
    }
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
};

export const convertTextListToWordList = (textsList: string[]): string[] => {
    const wordList = textsList.flatMap(text =>
        text
            .split(/[ ()<>/:"[\]+;,]/)
            .filter(word => word && word.length > 2)
            .map(word => (word.startsWith('-') ? word.substring(1).trim() : word.trim()))
    );

    return Array.from(new Set(wordList));
};
