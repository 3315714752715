import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { removeAccents } from '../../shared/utils/stringUtils';

@Component({
    selector: 'app-autocomplete-input',
    templateUrl: './autocomplete-input.component.html',
    styles: [
        `
            #keywords-field {
                &:focus-visible {
                    outline: none;
                }
            }
        `
    ]
})
export class AutocompleteInputComponent {
    @Input()
    set inputValue(value: string) {
        if (value !== this.searchControl.value) {
            this.searchControl.setValue(value);
        }
    }
    @Input() autoCompleteList: string[];
    @Input() inputStyles: Record<string, string | number>;
    @Output() inputValueChange = new EventEmitter<string>();

    searchControl = new FormControl('');
    autoCompleteListFiltered: Observable<string[]>;

    constructor() {
        this.autoCompleteListFiltered = this.searchControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterKeywords(value || ''))
        );
    }

    get cleanAutoCompleteList(): string[] {
        const autoCompleteListFormatted = this.autoCompleteList
            .slice()
            .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase());

        const autoCompleteListWithoutDuplicate = Array.from(new Set(autoCompleteListFormatted));
        return autoCompleteListWithoutDuplicate.sort((a, b) => a.localeCompare(b));
    }

    private _filterKeywords(value: string): string[] {
        const filterValue = removeAccents(value.toLowerCase());
        return value
            ? this.cleanAutoCompleteList.filter(option => removeAccents(option).toLowerCase().includes(filterValue))
            : [];
    }

    onValueChange(): void {
        this.inputValueChange.emit(this.searchControl.value);
    }
}
