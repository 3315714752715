import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyDetailsFormComponent } from '../../../shared/components/company-details-form/company-details-form.component';
import { CompanyActiveLegalProfessionalsFormComponent } from '../../../shared/components/company-active-legal-professionals-form/company-active-legal-professionals-form.component';
import { CommonSharedModule } from '../../../modules/common-shared/common-shared.module';

@NgModule({
    declarations: [CompanyDetailsFormComponent, CompanyActiveLegalProfessionalsFormComponent],
    imports: [CommonModule, CommonSharedModule],
    exports: [CompanyDetailsFormComponent, CompanyActiveLegalProfessionalsFormComponent]
})
export class AdministrationCompanySharedModule {}
