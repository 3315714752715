import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorI } from '../../../models/author';
import { HttpClientService } from '../../../services/http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PairKeyLabelI } from 'src/app/models/filter';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthorService {
    serviceUrl = '/author';
    httpClientServiceUrl = '/webfront/author';
    filterValue = '';

    constructor(private httpClientService: HttpClientService, private httpClient: HttpClient) {}

    filter(name: string): Observable<AuthorI[]> {
        return this.httpClientService.post(this.serviceUrl + '/filter', name, {
            headers: new HttpHeaders({ skipSpinner: 'true' })
        });
    }

    findByIds(ids: string[]): Observable<AuthorI[]> {
        return this.httpClientService.post(this.serviceUrl + '/find-by-ids', ids);
    }

    typeAheadFilter(nameFilter: string): Observable<PairKeyLabelI[]> {
        return this.httpClient
            .post<AuthorI[]>(this.httpClientServiceUrl + '/filter', nameFilter, {
                headers: new HttpHeaders({ skipSpinner: 'true' })
            })
            .pipe(map(authorList => authorList.map(author => ({ key: author.id.toString(), label: author.fullName }))));
    }
}
