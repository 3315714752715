import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseRoman'
})
export class ParseRomanPipe implements PipeTransform {
    transform(text: string): number {
        if (this.isRoman(text)) {
            return this.parseRoman(text);
        } else {
            return null;
        }
    }

    isRoman(text: string): boolean {
        return /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/i.test(text.trim());
    }

    parseRoman(text: string): number {
        const val = { M: 1000, D: 500, C: 100, L: 50, X: 10, V: 5, I: 1 };
        return text
            .trim()
            .toUpperCase()
            .split('')
            .reduce((acc, current, i, self) => {
                return val[current] < val[self[i + 1]] ? acc - val[current] : acc + val[current];
            }, 0);
    }
}
