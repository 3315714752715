import { ITranslation } from '../models/translations';
import { createStore } from '@ngneat/elf';
import {
    getEntity,
    hasEntity,
    selectAllEntities,
    setEntities,
    upsertEntities,
    withEntities
} from '@ngneat/elf-entities';
import { syncState } from 'elf-sync-state';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';

interface ExternalTranslationState {
    id: string;
    translations: ITranslation[];
}

const store = createStore(
    { name: 'externalTranslations' },
    withEntities<ExternalTranslationState>(),
    withRequestsCache<'external-translations' | `external-translations-${string}`>()
);

syncState(store);

export const skipWhileExternalTranslationsCached = createRequestsCacheOperator(store);

export const CACHED_EXTERNAL_TRANSLATIONS_KEY = 'external-translations';

@Injectable({ providedIn: 'root' })
export class ExternalTranslationsRepository {
    translations$ = store.pipe(selectAllEntities());

    public buildTranslationId(key: string, context: string): string {
        return `*${context}*${key}`;
    }

    public translationsEntryExists(key: string, context: string): boolean {
        return store.query(hasEntity(this.buildTranslationId(key, context)));
    }

    public getTranslationValue(key: string, context: string, language: string): string {
        return store
            .query(getEntity(this.buildTranslationId(key, context)))
            ?.translations?.find(t => t.language === language.toUpperCase())?.value;
    }

    public updateTranslations(translations: { [key: string]: ITranslation[] }): void {
        const state: ExternalTranslationState[] = [];
        Object.entries(translations).forEach(([key, value]) => {
            state.push({ id: key, translations: value });
        });
        store.update(setEntities(state), updateRequestCache(CACHED_EXTERNAL_TRANSLATIONS_KEY));
    }

    public addTranslation(key: string, context: string, translations: ITranslation[]): void {
        store.update(
            upsertEntities({ id: this.buildTranslationId(key, context), translations }),
            updateRequestCache(CACHED_EXTERNAL_TRANSLATIONS_KEY)
        );
    }
}
