import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Configuration } from '../../constant/configuration';
import { AttributesI } from '../../models/publication';

@Component({
    selector: 'app-filters-toggle',
    templateUrl: './filters-toggle.component.html',
    styleUrls: ['./filters-toggle.component.scss']
})
export class FiltersToggleComponent implements OnChanges {
    @Input() model = false;
    @Input() attributes: AttributesI;
    @Output() attributesChange = new EventEmitter<AttributesI>();
    @Input() name: string;
    @Input() labelPosition: 'before' | 'after' = 'after';
    @Input() labelLighter = false;
    @Output()
    toggled = new EventEmitter<boolean>();

    toggleFilterContext = '*' + this.configuration.translationContext.TOGGLE_CONTEXT + '*';
    constructor(private configuration: Configuration) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attributes && !changes.attributes.firstChange) {
            this.model = changes.attributes.currentValue[this.name]
                ? changes.attributes.currentValue[this.name][0]
                : false;
        }
    }

    onToggle(): void {
        this.model = !this.model;
        if (this.attributes) {
            const currentValue = this.attributes;
            currentValue[this.name] = [this.model];
            this.attributesChange.emit(currentValue);
        }
        this.toggled.emit(this.model);
    }
}
