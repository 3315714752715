import { Pipe, PipeTransform } from '@angular/core';
import { PartnerI } from '../models/partner';
@Pipe({
    name: 'partnersToOneLine'
})
export class PartnersToOneLinePipe implements PipeTransform {
    transform(partners: PartnerI[]): string {
        return partners?.map(partner => partner.label).join(', ') || '';
    }
}
