import { TranslationLoadedService } from '../translate/translation-loaded.service';
import { MessageBus } from '../messaging/MessageBus';
import { TranslateService } from '@ngx-translate/core';
import { Configuration } from '../constant/configuration';
import { Observable, take } from 'rxjs';

export default function initializeTranslations(
    translationLoadedService: TranslationLoadedService,
    messageBus: MessageBus,
    translateService: TranslateService,
    configuration: Configuration
): () => Observable<void> {
    messageBus.channel(configuration.messageBusChannels.LANGUAGE_CHANNEL).subscribe(message => {
        translateService.use(message.data);
    });
    return (): Observable<void> => translationLoadedService.loaded$().pipe(take(1));
}
