import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';

interface PdfReaderState {
    zoom: number;
}

const store = createStore(
    { name: 'pdfReader' },
    withProps<PdfReaderState>({
        zoom: 1
    })
);

@Injectable({ providedIn: 'root' })
export class PdfReaderRepository {
    zoom$ = store.pipe(select(state => state.zoom));

    updateZoom(payload: PdfReaderState['zoom']): void {
        store.update(setProp('zoom', payload));
    }
}
