import { Injectable } from '@angular/core';
import {
    getPaginationData,
    hasPage,
    PaginationData,
    selectCurrentPageEntities,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination
} from '@ngneat/elf-pagination';
import { createStore } from '@ngneat/elf';
import { upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import { ITranslationFilter, ITranslationGroup } from '../models/translations';
import { Pageable } from '../providers/pagination-provider';
import { HttpPaginationResponseI } from '../models/http';
import * as _ from 'lodash';
import {
    clearRequestsCache,
    createRequestsCacheOperator,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';

const store = createStore(
    { name: 'translation' },
    withEntities<ITranslationGroup, 'key'>({ idKey: 'key' }),
    withPagination(),
    withRequestsCache<`translations-${number}`>()
);

export const skipWhileTranslationCached = createRequestsCacheOperator(store);
export const CACHED_TRANSLATION_KEY = 'translations';

@Injectable({ providedIn: 'root' })
export class TranslationRepository {
    activeFilters: ITranslationFilter;
    currentPageable: Pageable;
    translationsOnCurrentPage$ = store.pipe(selectCurrentPageEntities());

    getPaginationData(): PaginationData {
        return store.query(getPaginationData());
    }
    setActiveFilters(filters: ITranslationFilter): void {
        this.activeFilters = JSON.parse(JSON.stringify(filters)); // to remove objects and arrays pointers
    }

    clearCacheIfDistinctFiltersOrSize(filters: ITranslationFilter, size: number): void {
        if (!_.isEqual(filters, this.activeFilters) || this.getPaginationData().perPage !== size) {
            this.clearCache();
        }
    }

    clearCache(): void {
        store.update(clearRequestsCache());
    }

    updateCurrentPageIfIsCached(page: number): void {
        if (store.query(hasPage(page))) {
            store.update(setCurrentPage(page));
        }
    }

    addPaginationTranslations(
        paginationResponse: HttpPaginationResponseI<ITranslationGroup[]>,
        pageable: Pageable
    ): void {
        this.currentPageable = pageable;

        paginationResponse.content.forEach(translationGroup => {
            store.update(
                upsertEntitiesById(translationGroup.key, {
                    creator: () => translationGroup,
                    updater: entity => {
                        delete translationGroup.translations;
                        return { ...entity, ...translationGroup };
                    }
                })
            );
        });

        store.update(
            updateRequestCache(`${CACHED_TRANSLATION_KEY}-${paginationResponse.number}`),
            updatePaginationData({
                currentPage: paginationResponse.number,
                perPage: paginationResponse.size,
                total: paginationResponse.totalElements,
                lastPage: paginationResponse.totalPages
            }),
            setPage(
                paginationResponse.number,
                paginationResponse.content.map(c => c.key)
            ),
            setCurrentPage(paginationResponse.number)
        );
    }
}
