import { Injectable } from '@angular/core';
import {
    clearRequestsCache,
    createRequestsCacheOperator,
    isRequestCached,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';
import { createStore } from '@ngneat/elf';
import {
    addEntities,
    deleteEntities,
    getEntity,
    updateEntities,
    upsertEntities,
    withEntities
} from '@ngneat/elf-entities';
import {
    getPaginationData,
    PaginationData,
    selectCurrentPageEntities,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination
} from '@ngneat/elf-pagination';
import { CompanyI, CompanyStatusE, ICompanyFilter } from '../models/company';
import { Pageable } from '../providers/pagination-provider';
import { HttpPaginationResponseI } from '../models/http';
import * as _ from 'lodash';

interface ICompanyState extends CompanyI {
    id: number;
}

const store = createStore(
    { name: 'adminCompany' },
    withEntities<ICompanyState>(),
    withPagination(),
    withRequestsCache<`admin-companies-${number}`>()
);

export const skipWhileAdminCompanyCached = createRequestsCacheOperator(store);
export const CACHED_ADMIN_COMPANY_KEY = 'admin-companies';

@Injectable({ providedIn: 'root' })
export class AdminCompanyRepository {
    activeFilters: ICompanyFilter;
    currentPageable: Pageable;
    companiesOnCurrentPage$ = store.pipe(selectCurrentPageEntities());

    getPaginationData(): PaginationData {
        return store.query(getPaginationData());
    }
    setActiveFilters(filters: ICompanyFilter): void {
        this.activeFilters = JSON.parse(JSON.stringify(filters)); // to remove objects and arrays pointers
    }

    clearCacheIfDistinctFiltersOrPagination(filters: ICompanyFilter, pageable: Pageable): void {
        if (
            !_.isEqual(filters, this.activeFilters) ||
            this.getPaginationData().perPage !== pageable.size ||
            this.currentPageable.sort !== pageable.sort
        ) {
            this.clearCache();
        }
    }

    clearCache(): void {
        store.update(clearRequestsCache());
    }

    updateCurrentPageIfIsCached(page: number): void {
        if (store.query(isRequestCached(`${CACHED_ADMIN_COMPANY_KEY}-${page}`))) {
            store.update(setCurrentPage(page));
        }
    }

    addCompany(payload: CompanyI): void {
        store.update(addEntities(payload as ICompanyState));
        this.clearCache();
    }

    upsertCompany(payload: CompanyI): void {
        store.update(upsertEntities(payload as ICompanyState));
    }

    getCompany(id: number): CompanyI {
        return store.query(getEntity(id));
    }

    updateCompany(payload: CompanyI): void {
        const companyUpdated = store.query(getEntity(payload.id));
        if (companyUpdated && companyUpdated[this.currentPageable.sort] !== payload[this.currentPageable.sort]) {
            // if sort field has been updated it can affect order
            this.clearCache();
        }
        store.update(updateEntities(payload.id, { ...payload }));
    }

    softDeleteCompany(id: number): void {
        store.update(
            updateEntities(id, {
                status: CompanyStatusE.DELETED
            })
        );
    }

    recoverSoftDeletedCompany(id: number): void {
        store.update(
            updateEntities(id, {
                status: CompanyStatusE.VALIDATED
            })
        );
    }

    deleteCompany(id: number): void {
        store.update(deleteEntities(id));
        this.clearCache();
    }

    addPaginationCompanies(paginationResponse: HttpPaginationResponseI<CompanyI[]>, pageable: Pageable): void {
        this.currentPageable = { ...pageable };

        store.update(
            updateRequestCache(`${CACHED_ADMIN_COMPANY_KEY}-${paginationResponse.number}`),
            upsertEntities(paginationResponse.content),
            updatePaginationData({
                currentPage: paginationResponse.number,
                perPage: paginationResponse.size,
                total: paginationResponse.totalElements,
                lastPage: paginationResponse.totalPages
            }),
            setPage(
                paginationResponse.number,
                paginationResponse.content.map(c => c.id)
            ),
            setCurrentPage(paginationResponse.number)
        );
    }
}
