import { Injectable } from '@angular/core';
import { FilterCategoryI, FilterSubCategoryI, PairKeyLabelI } from '../../../../models/filter';
import { BehaviorSubject } from 'rxjs';
import { DocumentFilterService } from '../../service/document-filter.service';
import { AttributesI } from '../../../../models/publication';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    constructor(private documentFilterService: DocumentFilterService) {}

    createCategory(
        label: string,
        subCats: PairKeyLabelI[],
        attributes$: BehaviorSubject<AttributesI>,
        singleSelect = false
    ): FilterCategoryI {
        const category: FilterCategoryI = {
            label,
            selected(): boolean {
                return category.numberOfSelected.value === category.categories.length;
            },
            numberOfSelected: new BehaviorSubject<number>(0),
            categories: []
        };
        category.updateNumberOfSelected = () => {
            const numberOfSelected = category.categories.filter(cat => cat.selected.value).length;
            category.numberOfSelected.next(numberOfSelected);
        };
        category.select = () => {
            category.numberOfSelected.next(category.categories.length);
            category.categories.forEach(cat => cat.selected.next(true));
        };
        category.unselect = () => {
            category.numberOfSelected.next(0);
            category.categories.forEach(cat => cat.selected.next(false));
        };
        category.categories = this.createSubCategories(
            label,
            subCats,
            false,
            category.updateNumberOfSelected.bind(category),
            attributes$,
            singleSelect
        );
        category.updateNumberOfSelected();
        return category;
    }

    createSubCategories(
        label: string,
        categories: PairKeyLabelI[],
        selected: boolean,
        updateNumberOfSelected: () => void,
        attributes$: BehaviorSubject<AttributesI>,
        singleSelect = false
    ): FilterSubCategoryI[] {
        const catResult: FilterSubCategoryI[] = [];
        categories.forEach(keyLabel => {
            const subCat: FilterSubCategoryI = {
                key: keyLabel.key,
                label: keyLabel.label,
                selected: new BehaviorSubject<boolean>(selected)
            };
            subCat.select = () => {
                if (singleSelect && !subCat.selected.value) {
                    catResult.forEach(cat => cat.selected.next(false));
                }
                subCat.selected.next(!subCat.selected.value);
            };
            attributes$.subscribe(attributes => {
                // for filters that come from other interfaces so that it that selected state can be changed from anywhere
                const inAttributes = !!attributes[this.documentFilterService.getCategoryKey(label)]?.includes(
                    subCat.key.toString()
                );
                if (inAttributes !== subCat.selected.value) {
                    subCat.selected.next(inAttributes);
                }
                if (updateNumberOfSelected != null) {
                    updateNumberOfSelected();
                }
            });
            catResult.push(subCat);
        });
        return catResult;
    }
}
