import { MessageBus } from './messaging/MessageBus';
import { Component } from '@angular/core';
import { AuthenticateService } from './services/authenticate.service';
import { Configuration } from './constant/configuration';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationTypeE } from './models/user';

@Component({
    template: '<div></div>'
})
export class AutoLoginComponent {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        authenticateService: AuthenticateService,
        configuration: Configuration,
        messageBus: MessageBus
    ) {
        const credential = {
            email: route.snapshot.params.company,
            password: route.snapshot.params.hash
        };
        authenticateService.login(credential, AuthenticationTypeE.AUTO_LOGIN_LINK).then(
            result => {
                messageBus.channel(configuration.messageBusChannels.USER_LOGIN_CHANNEL).next({
                    data: result,
                    message: 'userLogin'
                });
                void this.router.navigate(['o/home']);
            },
            () => {
                console.log('login failed');
            }
        );
    }
}
