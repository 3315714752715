import { MessageBus } from '../../../messaging/MessageBus';
import { Component, ViewChild } from '@angular/core';
import { Configuration } from 'src/app/constant/configuration';
import { EVENT_RESTRICTED_ACCESS_MESSAGE_SHOW } from 'src/app/services/restricted-access-popup.service';
import { AuthenticateService } from '../../../services/authenticate.service';
import { CurrentUserI, UserDefaultRolesE } from '../../../models/user';
import { CompanyI } from '../../../models/company';
import { SessionCompanyService } from '../../../online/administration/my-company/service/session-company.service';
import { AlertMixin } from '../../../mixins/alert/alert.mixin';
import { MixinBase } from '../../../mixins/crud/MixinBase';
import { MatStepper } from '@angular/material/stepper';
import { NotificationMessageService } from '../../../services/notification_message.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-restricted-access-popup',
    templateUrl: './restricted-access-popup.component.html',
    styleUrls: ['./restricted-access-popup.component.scss']
})
export class RestrictedAccessPopupComponent extends AlertMixin(MixinBase) {
    @ViewChild('matStepper') matStepper: MatStepper;
    showModal = false;
    company: CompanyI;
    askToUpgradePlan = false;

    constructor(
        private router: Router,
        messageBus: MessageBus,
        configuration: Configuration,
        private authenticateService: AuthenticateService,
        private sessionCompanyService: SessionCompanyService,
        private notificationMessageService: NotificationMessageService
    ) {
        super();
        messageBus.channel(configuration.messageBusChannels.RESTRICTED_ACCESS_CHANNEL as string).subscribe(message => {
            if (message.message === EVENT_RESTRICTED_ACCESS_MESSAGE_SHOW) {
                this.askToUpgradePlan = !!message.data.askToUpgrade;
                if (this.canViewUpgradePlanOption) {
                    void this.loadCompany().then(() => {
                        if (this.askToUpgradePlan && !this.company.requestedUpgrade) {
                            setTimeout(() => {
                                this.handleUpgradePlan();
                            });
                            this.showModal = true;
                        } else if (this.askToUpgradePlan) {
                            this.alertAlreadyRequestedUpdate();
                        } else {
                            this.showModal = true;
                        }
                    });
                } else {
                    this.showModal = true;
                }
            }
        });
        this.initAlertMixin(messageBus.channel(configuration.messageBusChannels.ALERT_CHANNEL as string));
    }

    get currentUser(): CurrentUserI {
        return this.authenticateService.currentUser;
    }

    get canViewUpgradePlanOption(): boolean {
        return (
            !this.currentUser.roles.includes(UserDefaultRolesE.STANDARD_USER) &&
            !this.currentUser.roles.includes(UserDefaultRolesE.COMPANY_MANAGER) &&
            !this.currentUser.roles.includes(UserDefaultRolesE.ADMINISTRATOR) &&
            this.currentUser.roles.includes(UserDefaultRolesE.TRIAL_COMPANY_MANAGER)
        );
    }

    get canViewGoToFreeTrialOption(): boolean {
        return (
            this.currentUser &&
            this.currentUser.roles.length === 1 &&
            this.currentUser.roles.includes(UserDefaultRolesE.PUBLIC_USER)
        );
    }

    close(): void {
        this.showModal = false;
    }

    async loadCompany(): Promise<void> {
        this.company = await this.sessionCompanyService.getSessionCompany();
        return Promise.resolve();
    }

    async upgradePlan(): Promise<void> {
        if (this.company.requestedUpgrade) {
            this.alertAlreadyRequestedUpdate();
            this.close();
            return null;
        }
        this.sessionCompanyService.requestUpgrade().subscribe(() => {
            this.alert({
                type: 'success',
                message: 'global.restrictedAccessPopup.texts.confirmationUpgradeRequest',
                duration: 6000
            });
            this.close();
        });
    }

    goToFreeTrial(): void {
        void this.router.navigate(['login'], { queryParams: { tab: 'free-trial' } });
        this.close();
    }

    goToLoginPage(): void {
        void this.router.navigate(['login']);
        this.close();
    }

    handleUpgradePlan(): void {
        if (this.company.initialized && this.company.modifiedLegalProfessionalsTime) {
            void this.upgradePlan();
        }
    }

    async handleCompanyDetailsFormSubmitted(company: CompanyI): Promise<void> {
        void this.sessionCompanyService.updateSessionCompany(company).then((company: CompanyI) => {
            this.alert({ type: 'success', message: 'company.edit.success', duration: 2500 });
            this.authenticateService.refreshCurrentUser();
            void this.afterUpdateCompany(company);
        });
    }

    async afterUpdateCompany(newCompany: CompanyI): Promise<void> {
        if (this.company.modifiedLegalProfessionalsTime) {
            await this.upgradePlan();
            this.company = newCompany;
        } else {
            this.matStepper.next();
            setTimeout(() => {
                // remove step, juste after stepper animation performed
                this.company = newCompany;
            }, 500);
        }
    }

    async handleCompanyActiveLegalProfessionalsFormSubmitted(numberActiveLegalProfessionals: number): Promise<void> {
        void this.sessionCompanyService.updateLegalProfessionals(numberActiveLegalProfessionals).then(() => {
            this.alert({
                type: 'success',
                message: 'users.management.activeLegalProfessionals.popups.updateConfirmation.message',
                duration: 2500
            });
            void this.afterSuccessUpdateLegalProfessionals();
        });
    }

    async afterSuccessUpdateLegalProfessionals(): Promise<void> {
        await this.upgradePlan();
        await this.loadCompany();
    }

    goToPreviousStep(): void {
        if (this.askToUpgradePlan && this.matStepper.selectedIndex === 0) {
            this.close();
        }
        this.matStepper.previous();
    }

    logout(): void {
        void this.authenticateService.logout();
        void this.router.navigate(['login']);
        this.close();
    }

    alertAlreadyRequestedUpdate(): void {
        this.notificationMessageService.init(`global.errors.business.820.title`, `global.errors.business.820.message`);
        this.notificationMessageService.addLeftButton({ label: 'global.action.ok' });
        this.notificationMessageService.show();
    }
}
