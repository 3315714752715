import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthorI } from '../../../../models/author';

@Component({
    selector: 'app-criteria-tree-dropdown',
    templateUrl: './criteria-tree-dropdown.component.html',
    styleUrls: ['./criteria-tree-dropdown.component.scss']
})
export class CriteriaTreeDropdownComponent implements OnInit, AfterViewInit {
    @ViewChild('list', {
        static: true
    })
    list: HTMLDListElement;

    fieldFilterValue = '';
    constructor() {
        console.log('CONSTR');
    }

    ngOnInit(): void {
        console.log('INIT, we have list: ' + this.list);
    }

    updateAuthorSubCategories(authors: AuthorI[]): void {
        console.log('UPDATE AUTHOR SUB CATEGORIES');
    }

    ngAfterViewInit(): void {
        console.log('AFTER INIT, we have list: ' + this.list);
    }
}
