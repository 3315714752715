import {FacetKeyHitsI} from '../../../../models/facets';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'facetHits'
})
export class FacetsHitsPipe implements PipeTransform {
    transform(facets: FacetKeyHitsI[] | number, key: string | number): number {
        if (facets === undefined || facets === null) {
            return null;
        }
        if (typeof facets === 'number') {
            return facets;
        }
        if (key === 'ALL') {
            let totalPublications = 0;
            facets.forEach(facet => {
                totalPublications += Number(facet.hits);
            });
            return totalPublications ? totalPublications : 0;
        }
        const element = facets.find(f => f.key === key.toString());
        return element !== undefined ? Number(element.hits) : 0;
    }
}
