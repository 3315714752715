import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle, RouterStateSnapshot } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private cache: { [key: string]: DetachedRouteHandle } = {};

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.routeConfig.data && !!route.routeConfig.data.reuse;
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if (handle) {
            this.cache[this.getUrl(route)] = handle;
        }
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.cache[this.getUrl(route)] && !!route.component;
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (!route.routeConfig || route.routeConfig.loadChildren) {
            return null;
        }
        return this.cache[this.getUrl(route)];
    }

    getUrl(route: ActivatedRouteSnapshot): string {
        const routeSnapshot = route[`_routerState`] as RouterStateSnapshot;
        if (routeSnapshot && route.routeConfig) {
            // we remove parameters, to avoid caching every page on parameters change
            // online/book/1 and online/book/2 will be transformed to online/book/:x
            let url = routeSnapshot.url.replace(/\/[0-9]+(\/|$)/g, '/:x$1');

            // remove query-params on url
            url = url.split('?')[0];
            return url + '|' + route.routeConfig.path;
        }
    }
}
