import { Injectable } from '@angular/core';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { createStore } from '@ngneat/elf';
import { addEntities, selectEntity, updateEntities, withEntities } from '@ngneat/elf-entities';
import { BookLicenseI } from '../models/book';
import { Observable } from 'rxjs/internal/Observable';

interface BookLicenseState {
    id: number; // id of book
    bookLicenses: BookLicenseI[];
}

const store = createStore(
    { name: 'bookLicense' },
    withEntities<BookLicenseState>(),
    withRequestsCache<`book-licenses-${string}`>()
);

export const CACHED_BOOK_LICENSES_KEY = 'book-licenses';
export const skipWhileBookLicenseCached = createRequestsCacheOperator(store);

@Injectable({ providedIn: 'root' })
export class BookLicenseRepository {
    selectBookLicensesById$(id: number): Observable<BookLicenseI[]> {
        return store.pipe(selectEntity(id, { pluck: 'bookLicenses' }));
    }

    addBookLicense(payload: BookLicenseState): void {
        store.update(addEntities(payload), updateRequestCache(`${CACHED_BOOK_LICENSES_KEY}-${payload.id}`));
    }

    activateOrDeactivateBookLicense(id: number, bookLicense: BookLicenseI): void {
        store.update(
            updateEntities(id, entity => {
                const bookLicenses = entity.bookLicenses.slice();
                bookLicenses.find(x => x.id === bookLicense.id).active = !bookLicense.active;

                return {
                    ...entity,
                    bookLicenses
                };
            })
        );
    }
}
