import { CompanyManagerFilterI, CompanyManagerI } from '../models/companyManager';
import { createStore } from '@ngneat/elf';
import {
    addEntities,
    deleteEntities,
    getEntity,
    updateEntities,
    upsertEntities,
    withEntities
} from '@ngneat/elf-entities';
import {
    getPaginationData,
    PaginationData,
    selectCurrentPageEntities,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination
} from '@ngneat/elf-pagination';
import { HttpPaginationResponseI } from '../models/http';
import {
    clearRequestsCache,
    createRequestsCacheOperator,
    isRequestCached,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Pageable } from '../providers/pagination-provider';

export interface ISessionUser extends CompanyManagerI {
    uuid: string;
}

const store = createStore(
    { name: 'sessionUser' },
    withEntities<ISessionUser, 'uuid'>({ idKey: 'uuid' }),
    withPagination(),
    withRequestsCache<`session-users-${string}-${number}`>()
);
export const skipWhileSessionUserCached = createRequestsCacheOperator(store);
export const CACHED_SESSION_USER_KEY = 'session-users';

@Injectable({ providedIn: 'root' })
export class SessionUserRepository {
    activeFilters: { [key: string]: CompanyManagerFilterI } = {};
    currentPageable: Pageable;
    sessionUsersOnCurrentPage$ = store.pipe(selectCurrentPageEntities());

    get sortField(): string {
        let field = this.currentPageable.sort;
        if (field === 'lastName') {
            field = 'name';
        }
        return field;
    }

    getPaginationData(): PaginationData {
        return store.query(getPaginationData());
    }
    setActiveFilters(companyUuid: string, filters: CompanyManagerFilterI): void {
        this.activeFilters[companyUuid] = JSON.parse(
            JSON.stringify({
                ...filters,
                companyUuid // if companyUuid changes, the cache will be reset
            })
        ); // to remove objects and arrays pointers
    }

    clearCacheIfDistinctFiltersOrSize(companyUuid: string, filters: CompanyManagerFilterI, size: number): void {
        if (
            !_.isEqual({ ...filters, companyUuid }, this.activeFilters[companyUuid]) ||
            this.getPaginationData().perPage !== size
        ) {
            this.clearCache();
        }
    }

    clearCache(): void {
        store.update(clearRequestsCache());
    }

    updateCurrentPageIfSessionUserIsCached(page: number, cacheKey: `session-users-${string}-${number}`): void {
        if (store.query(isRequestCached(cacheKey))) {
            store.update(setCurrentPage(page));
        }
    }

    addUser(payload: CompanyManagerI): void {
        store.update(addEntities(payload as ISessionUser));
        this.clearCache();
    }

    updateUser(payload: CompanyManagerI): void {
        const companyMangerUpdated = store.query(getEntity(payload.uuid));
        if (companyMangerUpdated[this.sortField] !== payload[this.sortField]) {
            // if sort field has been updated it can affect order
            this.clearCache();
        }
        store.update(updateEntities(payload.uuid, { ...payload }));
    }

    deleteUser(userUuid: string): void {
        store.update(deleteEntities(userUuid));
        this.clearCache();
    }

    addPaginationUsers(
        companyUuid: string,
        paginationResponse: HttpPaginationResponseI<CompanyManagerI[]>,
        pageable: Pageable
    ): void {
        this.currentPageable = pageable;
        store.update(
            updateRequestCache(`${CACHED_SESSION_USER_KEY}-${companyUuid}-${paginationResponse.number}`),
            upsertEntities(paginationResponse.content as ISessionUser[]),
            updatePaginationData({
                currentPage: paginationResponse.number,
                perPage: paginationResponse.size,
                total: paginationResponse.totalElements,
                lastPage: paginationResponse.totalPages
            }),
            setPage(
                paginationResponse.number,
                paginationResponse.content.map(c => c.uuid)
            ),
            setCurrentPage(paginationResponse.number)
        );
    }
}
