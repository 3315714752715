import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { addEntities, hasEntity, selectEntity, updateEntities, withEntities } from '@ngneat/elf-entities';
import { syncState } from 'elf-sync-state';
import { BookPartI } from '../models/book';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Observable } from 'rxjs/internal/Observable';

interface AdminBookPartState {
    id: number; // id of book
    bookParts: BookPartI[];
}

const store = createStore(
    { name: 'adminBookPart' },
    withEntities<AdminBookPartState>(),
    withRequestsCache<`admin-book-parts-${string}`>()
);
syncState(store);

export const skipWhileAdminBookPartCached = createRequestsCacheOperator(store);
export const CACHED_ADMIN_BOOK_PART_KEY = 'admin-book-parts';

@Injectable({ providedIn: 'root' })
export class AdminBookPartRepository {
    selectBookPartsByBook$(id: number): Observable<BookPartI[]> {
        return store.pipe(selectEntity(id, { pluck: 'bookParts' }));
    }
    addAdminBookPart(payload: AdminBookPartState): void {
        store.update(addEntities(payload), updateRequestCache(`${CACHED_ADMIN_BOOK_PART_KEY}-${payload.id}`));
    }

    addBookPartInBook(bookId: number, bookPart: BookPartI): void {
        store.update(
            updateEntities(bookId, entity => {
                const newBookParts = entity.bookParts.slice();
                newBookParts.push(bookPart);
                return {
                    ...entity,
                    bookParts: newBookParts
                };
            })
        );
    }

    updateBookPartInBook(bookId: number, bookPart: BookPartI): void {
        store.update(
            updateEntities(bookId, entity => {
                const newBookParts = entity.bookParts.slice();
                const index = newBookParts.findIndex(x => x.id === bookPart.id);
                if (index !== -1) {
                    newBookParts.splice(index, 1, bookPart);
                }
                return {
                    ...entity,
                    bookParts: newBookParts
                };
            })
        );
    }

    deleteBookPartByBook(bookId: number, bookPartId: number): void {
        store.update(
            updateEntities(bookId, entity => {
                const newBookParts = entity.bookParts.slice();
                const index = newBookParts.findIndex(x => x.id === bookPartId);
                if (index !== -1) {
                    newBookParts.splice(index, 1);
                }
                return {
                    ...entity,
                    bookParts: newBookParts
                };
            })
        );
    }

    publishBookPartsInBook(bookId: number): void {
        if (store.query(hasEntity(bookId))) {
            store.update(
                updateEntities(bookId, entity => {
                    const newBookParts = entity.bookParts.slice().map(bookPart => ({
                        ...bookPart,
                        published: true
                    }));
                    return {
                        ...entity,
                        bookParts: newBookParts
                    };
                })
            );
        }
    }
}
