import { ResolveFn } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { CurrentUserI } from '../models/user';
import { inject } from '@angular/core';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export const CURRENT_USER_RESOLVER: { resolve: ResolveFn<CurrentUserI> } = {
    resolve: () => {
        const authenticateService = inject(AuthenticateService);
        return fromPromise(authenticateService.getCurrentUser());
    }
};
