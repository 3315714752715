import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}
    transform(value: Date | string | number, pattern: string): string {
        const datePipe: DatePipe = new DatePipe(this.translateService.currentLang || 'fr');
        return datePipe.transform(value, pattern);
    }
}
