import { Component } from '@angular/core';
import { DocumentFilterService } from '../../service/document-filter.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
    selector: 'app-search-sub-menu',
    templateUrl: 'search-sub-menu.component.html',
    styleUrls: ['search-sub-menu.component.scss']
})
export class SearchSubMenuComponent {
    constructor(private documentFilterService: DocumentFilterService, public fileSaver: FileSaverService) {}

    keys = '';

    manageFilterMenu(): void {
        this.documentFilterService.isSideFilterMenuOpen = !this.documentFilterService.isSideFilterMenuOpen;
    }
}
