import { Injectable } from '@angular/core';
import { MessageBus } from '../../messaging/MessageBus';
import { LocalStorageAdapter } from '../utils/local-storage.adapter';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Configuration } from '../../constant/configuration';

export interface LanguageData {
    alt: any;
    twoLettersLanguage: string;
    languageKey: string;
    cssClass: string;
}

@Injectable({ providedIn: 'root' })
export class LanguageService {
    selectedLanguageData: LanguageData;

    constructor(
        public configuration: Configuration,
        public messageBus: MessageBus,
        public localStorageAdapter: LocalStorageAdapter,
        private translateService: TranslateService
    ) {
        // TODO: IVAN - CHANGE LANG TO OBSERVABLE AND REWIRE
        this.messageBus.channel(this.configuration.messageBusChannels.LANGUAGE_CHANNEL).subscribe(message => {
            this.changeLanguage(this.configuration.languages[message.data]);
        });

        this.messageBus
            .channel(this.configuration.messageBusChannels.USER_LOGIN_CHANNEL)
            .subscribe(({ message, data }) => {
                if (message === 'userLogin') {
                    this.changeLanguage(this.configuration.languages[data.language]);
                }
            });

        this.messageBus.channel(this.configuration.messageBusChannels.LANGUAGE_CHANNEL).next({
            data: this.localStorageAdapter.selectedLanguageCode
        });
    }

    changeLanguage(lang: LanguageData): void {
        this.selectedLanguageData = lang;
        moment.locale(lang.twoLettersLanguage);
        this.localStorageAdapter.selectedLanguageCode = lang.twoLettersLanguage;
        this.selectedLanguageData = this.configuration.languages[lang.twoLettersLanguage];
        this.translateService.use(lang.twoLettersLanguage);
    }

    instant(key: string): string {
        return this.translateService.instant(key);
    }
}
