export interface Pageable {
    page: number;
    size: number;
    sort: string;
}

export const createEmptyPageable = (size?: number, page?: number, sort?: string): Pageable => {
    return {
        page: page ? page : 0,
        size: size ? size : 10,
        sort: sort ? sort : ''
    };
};

export class PaginationProvider<T = void> {
    modelPageStart = 0;
    pagination: Pageable = { page: 0, size: 10, sort: '' };
    currentPageNumber = 0;
    currentPageSize = 10;
    pageSizes = [10, 20, 30];
    totalPage = 0;
    totalItems = 0;

    loadPageableData: () => T;

    constructor(loadPageableDataCallBack: () => T) {
        this.loadPageableData = loadPageableDataCallBack;
    }

    nextPage(): T {
        if (this.currentPageNumber < this.totalPage - (1 - this.modelPageStart)) {
            this.currentPageNumber++;
            this.pagination.page = this.pagination.page + 1;
            return this.loadPageableData();
        }
    }

    previousPage(): T {
        if (this.currentPageNumber > this.modelPageStart) {
            this.currentPageNumber--;
            this.pagination.page = this.pagination.page - 1;
            return this.loadPageableData();
        }
    }

    firstPage(): T {
        this.currentPageNumber = this.modelPageStart;
        this.pagination.page = this.currentPageNumber;
        return this.loadPageableData();
    }

    lastPage(): T {
        this.currentPageNumber = this.totalPage - (1 - this.modelPageStart);
        this.pagination.page = this.currentPageNumber;
        return this.loadPageableData();
    }

    setupPagination(totalElement = 0, modelPageStart = 0): void {
        this.modelPageStart = modelPageStart;
        this.currentPageNumber = this.modelPageStart;
        this.currentPageSize = this.pagination.size || this.pageSizes[0];
        this.totalPage = Math.ceil(totalElement / this.currentPageSize);
        this.totalItems = totalElement;
    }

    resetPagination(): void {
        this.currentPageNumber = -1;
        this.currentPageSize = -1;
        this.totalPage = -1;
        this.totalItems = -1;
    }

    updatePagination(totalElement: number): void {
        this.totalPage = Math.ceil(totalElement / this.currentPageSize);
        if (this.currentPageNumber > this.totalPage) {
            this.currentPageNumber = this.totalPage;
        }
        this.totalItems = totalElement;
    }

    isPaginationSetup(): boolean {
        return this.currentPageNumber > -1 && this.currentPageSize > -1 && this.totalPage > -1 && this.totalItems > -1;
    }

    changePageSize(size: number): void {
        this.currentPageSize = size;
        this.pagination.size = this.currentPageSize;
        this.totalPage = Math.ceil(this.totalItems / this.currentPageSize);
        this.firstPage();
    }
}
