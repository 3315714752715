import { createStore } from '@ngneat/elf';
import { addEntities, selectEntity, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { ICodeBucket } from '../models/codeBucket';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

const store = createStore(
    { name: 'bookBucket' },
    withEntities<ICodeBucket>(),
    withRequestsCache<`book-buckets-${string}`>()
);

export const skipWhileBookBucketCached = createRequestsCacheOperator(store);
export const CACHED_BOOK_BUCKETS_KEY = 'book-buckets';

@Injectable({ providedIn: 'root' })
export class BookBucketRepository {
    selectCodeBucket(id: number): Observable<ICodeBucket> {
        return store.pipe(selectEntity(id));
    }

    addCodeBucket(codeBucket: ICodeBucket): void {
        store.update(addEntities(codeBucket), updateRequestCache(`${CACHED_BOOK_BUCKETS_KEY}-${codeBucket.id}`));
    }
}
