import { Injectable } from '@angular/core';
import {
    deleteEntities,
    getAllEntities,
    selectAllEntities,
    setEntities,
    upsertEntities,
    withEntities
} from '@ngneat/elf-entities';
import { LicenseI } from '../models/license';
import { createStore } from '@ngneat/elf';
import { syncState } from 'elf-sync-state';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';

const store = createStore({ name: 'license' }, withEntities<LicenseI>(), withRequestsCache<'licenses'>());

syncState(store);

export const skipWhileLicensesCached = createRequestsCacheOperator(store);
export const CACHE_KEY_LICENSES = 'licenses';

@Injectable({ providedIn: 'root' })
export class LicenseRepository {
    licenses$ = store.pipe(selectAllEntities());

    get licenses(): LicenseI[] {
        return store.query(getAllEntities());
    }

    setLicenses(licenses: LicenseI[]): void {
        store.update(updateRequestCache(CACHE_KEY_LICENSES), setEntities(licenses));
    }

    upsertLicense(license: LicenseI): void {
        store.update(updateRequestCache(CACHE_KEY_LICENSES), upsertEntities([license]));
    }

    deleteLicenses(ids: number[]): void {
        store.update(updateRequestCache(CACHE_KEY_LICENSES), deleteEntities(ids));
    }
}
