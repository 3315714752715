import { AISearchPromptI } from '../models/aiSearch';
import { createStore } from '@ngneat/elf';
import { getEntity, selectEntity, updateEntities, upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

interface AiSearchPromptStateI {
    userUuid: string;
    aiSearchPromptsByLang: { [lang: string]: AISearchPromptI[] };
}

const store = createStore(
    { name: 'aiSearchPrompt' },
    withEntities<AiSearchPromptStateI, 'userUuid'>({ idKey: 'userUuid' }),
    withRequestsCache<`ai-search-prompts-${string}`>()
);
export const skipWhileAISearchPromptCached = createRequestsCacheOperator(store);
export const CACHE_KEY_AI_SEARCH_PROMPT = 'ai-search-prompts';

@Injectable({ providedIn: 'root' })
export class AISearchPromptRepository {
    getAiSearchPromptsByUserUuidAndLang$(userUuid: string, lang: string): Observable<AISearchPromptI[]> {
        return store.pipe(
            selectEntity(userUuid, { pluck: 'aiSearchPromptsByLang' }),
            map(aiSearchPromptsByLang => aiSearchPromptsByLang[lang] || [])
        );
    }

    upsertAiSearchPromptsByUserUuidAndLang(userUuid: string, lang: string, aiSearchPrompts: AISearchPromptI[]): void {
        store.update(
            updateRequestCache(`${CACHE_KEY_AI_SEARCH_PROMPT}-${userUuid}-${lang}`),
            upsertEntitiesById(userUuid, {
                updater: entity => ({
                    ...entity,
                    aiSearchPromptsByLang: {
                        ...entity.aiSearchPromptsByLang,
                        [lang]: aiSearchPrompts
                    }
                }),
                creator: () => ({ userUuid, aiSearchPromptsByLang: { [lang]: aiSearchPrompts } })
            })
        );
    }

    addAiSearchPrompt(userUuid: string, lang: string, aiSearchPrompt: AISearchPromptI): void {
        const currentAiSearchPromptsByLang = store.query(getEntity(userUuid)).aiSearchPromptsByLang;
        if (!currentAiSearchPromptsByLang[lang]) {
            currentAiSearchPromptsByLang[lang] = [aiSearchPrompt];
        } else {
            currentAiSearchPromptsByLang[lang].push(aiSearchPrompt);
        }

        store.update(
            updateEntities(userUuid, entity => ({
                ...entity,
                aiSearchPromptsByLang: currentAiSearchPromptsByLang
            }))
        );
    }
}
