import { Injectable } from '@angular/core';
import { IMagazine, IMagazineSubscription } from '../models/magazine';
import { createStore } from '@ngneat/elf';
import { deleteEntities, updateEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import {
    PaginationData,
    selectCurrentPageEntities,
    selectPaginationData,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination
} from '@ngneat/elf-pagination';
import { HttpPaginationResponseI } from '../models/http';

interface IMagazineSubscriptionState extends IMagazineSubscription {
    id: number;
}

const store = createStore(
    { name: 'adminMagazineSubscription' },
    withEntities<IMagazineSubscriptionState>(),
    withPagination()
);

@Injectable({ providedIn: 'root' })
export class AdminMagazineSubscriptionRepository {
    magazineSubscriptionsOnCurrentPage$ = store.pipe(selectCurrentPageEntities());
    selectCurrentPaginationData$ = store.pipe(selectPaginationData());
    currentMagazineId: number;

    updateMagazineSubscription(payload: IMagazine): void {
        store.update(updateEntities(payload.id, { ...payload }));
    }

    deleteMagazineSubscription(id: number): void {
        store.update(deleteEntities(id));
    }

    addPaginationMagazineSubscription(
        magazineId: number,
        paginationResponse: HttpPaginationResponseI<IMagazineSubscription[]>
    ): void {
        this.currentMagazineId = magazineId;
        store.update(
            upsertEntities(paginationResponse.content),
            updatePaginationData({
                currentPage: paginationResponse.number,
                perPage: paginationResponse.size,
                total: paginationResponse.totalElements,
                lastPage: paginationResponse.totalPages
            }),
            setPage(
                paginationResponse.number,
                paginationResponse.content.map(c => c.id)
            ),
            setCurrentPage(paginationResponse.number)
        );
    }

    updatePagination(magazineId: number, page: number, ids: number[], paginationData: PaginationData): void {
        this.currentMagazineId = magazineId;
        store.update(updatePaginationData(paginationData), setPage(page, ids), setCurrentPage(page));
    }
}
