import { Pageable } from '../../../../mixins/pagination/pagination.mixin';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { interval, Subject, tap } from 'rxjs';
import { Message, MessageBus } from '../../../../messaging/MessageBus';
import { Configuration } from '../../../../constant/configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ITranslation, ITranslationFilter, ITranslationGroup } from '../../../../models/translations';
import {
    CACHED_EXTERNAL_TRANSLATIONS_KEY,
    CACHED_TRANSLATION_KEY,
    ExternalTranslationsRepository,
    skipWhileExternalTranslationsCached,
    skipWhileTranslationCached,
    TranslationRepository
} from '../../../../store';
import { HttpPaginationResponseI } from '../../../../models/http';
import { IUpdateService } from '../../../../interfaces/crud';
import { PaginationData } from '@ngneat/elf-pagination';

interface ITranslationResponse {
    [key: string]: ITranslation[];
}

@Injectable({ providedIn: 'root' })
export class AdministrationTranslationService implements IUpdateService<ITranslation> {
    serviceUrl = '/webfront/translation';
    private keysToAdd: Map<string, string> = new Map<string, string>();
    // shared references
    public languages: Array<string>;
    externalTranslations$ = this.externalTranslationRepository.translations$;
    translationsOnCurrentPage$ = this.translationRepository.translationsOnCurrentPage$;

    constructor(
        private httpClient: HttpClient,
        private configuration: Configuration,
        private messageBus: MessageBus,
        private translateService: TranslateService,
        private externalTranslationRepository: ExternalTranslationsRepository,
        private translationRepository: TranslationRepository
    ) {
        this.languages = configuration.languagesArray;
        this.fetchAll().subscribe();

        interval(60000).subscribe(() => {
            if (this.keysToAdd.size > 0) {
                void this.createGroup(this.keysToAdd).subscribe(() => {
                    this.keysToAdd = new Map<string, string>();
                });
            }
        });
    }

    get adminTranslationChannel(): Subject<Message> {
        return this.messageBus.channel(this.configuration.messageBusChannels.TRANSLATION_ADMIN_CHANNEL);
    }

    get currentPaginationData(): PaginationData {
        return this.translationRepository.getPaginationData();
    }

    /**
     * @param keyContext should come in format *context*key
     */
    findTranslation(keyContext: string): string {
        const contextReg = new RegExp(/.{0}(?=\*)(.*)(?=\*)/).exec(keyContext);
        let context = contextReg != null && contextReg[0].toString().replace('*', '').trim();
        const key = keyContext.replace(/\*[^*]*\*/g, '').trim();

        if (!context) {
            context = 'NONE';
        }

        // translation group does not exist
        if (!this.externalTranslationRepository.translationsEntryExists(key, context)) {
            this.keysToAdd.set(key, context);
            this.externalTranslationRepository.addTranslation(key, context, []);
        }

        const translation = this.externalTranslationRepository.getTranslationValue(
            key,
            context,
            this.translateService.currentLang
        );

        return translation ?? key;
    }

    fetchAll(): Observable<ITranslationResponse> {
        return this.httpClient.get<ITranslationResponse>(`${this.serviceUrl}/all`).pipe(
            tap(data => {
                this.externalTranslationRepository.updateTranslations(data);
            }),
            skipWhileExternalTranslationsCached(CACHED_EXTERNAL_TRANSLATIONS_KEY)
        );
    }

    createGroup(keys: Map<string, string>): Observable<boolean> {
        const keysToSend = [];
        keys.forEach((context, key) => {
            keysToSend.push({ key, context });
        });
        return this.httpClient.post<boolean>(`${this.serviceUrl}/group`, keysToSend, {
            headers: new HttpHeaders({ skipSpinner: 'true' })
        });
    }

    filterAll(filter: ITranslationFilter): Observable<ITranslation[]> {
        return this.httpClient.post<ITranslation[]>(`${this.serviceUrl}/filter`, filter);
    }

    filterTranslationGroup(
        filter: ITranslationFilter,
        pageable: Pageable
    ): Observable<HttpPaginationResponseI<ITranslationGroup[]>> {
        this.translationRepository.clearCacheIfDistinctFiltersOrSize(filter, pageable.size);
        this.translationRepository.updateCurrentPageIfIsCached(pageable.page);

        return this.httpClient
            .post<HttpPaginationResponseI<ITranslationGroup[]>>(this.serviceUrl + '/group/filter', filter, {
                params: { ...pageable }
            })
            .pipe(
                tap((response: HttpPaginationResponseI<ITranslationGroup[]>) => {
                    this.translationRepository.setActiveFilters(filter);
                    response.content = response.content.map(translationGroup => ({
                        ...translationGroup,
                        isOpen: false,
                        translations: []
                    }));
                    this.translationRepository.addPaginationTranslations(response, pageable);
                }),
                skipWhileTranslationCached(`${CACHED_TRANSLATION_KEY}-${pageable.page}`)
            );
    }
    update(item: ITranslation): Observable<ITranslation> {
        return this.httpClient.put<ITranslation>(`${this.serviceUrl}`, item);
    }
}
