export interface PartnerI {
    id: number;
    code?: string;
    description?: string;
    label?: string;
    type?: PartnerTypeE;
    externalId?: number;
    importLabel?: string;
    email?: string;
    uuiduser?: string;
    markets?: MarketI[];
}

export interface MarketI {
    id: number;
    code: string;
}

export enum PartnerTypeE {
    LEXNOW = 'LEXNOW',
    LEGITECH = 'LEGITECH',
    CRLN = 'CRLN'
}
