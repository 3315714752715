import { Pipe, PipeTransform } from '@angular/core';
import { PublicationI } from '../../models/publication';

@Pipe({
    name: 'orderLatestDocuments'
})
export class LatestDocumentsOrderPipe implements PipeTransform {
    /**
     * Publications that are in the future should be at the end of the array,
     * And should be order from oldest to newest
     * Otherwise publications should be order from newest to oldest
     */
    transform(entries: PublicationI[]): PublicationI[] {
        return entries != null
            ? entries.sort((a, b) => {
                  const dateApplicabilityA = new Date(a.applicability).getTime();
                  const dateApplicabilityB = new Date(b.applicability).getTime();

                  if (a.isFuture && b.isFuture) {
                      return dateApplicabilityA - dateApplicabilityB;
                  } else if (a.isFuture && !b.isFuture) {
                      return 1;
                  } else if (!a.isFuture && b.isFuture) {
                      return -1;
                  } else {
                      return dateApplicabilityB - dateApplicabilityA;
                  }
              })
            : [];
    }
}
