export function equalSet(a: Set<string>, b: Set<string>): boolean {
    if (a.size !== b.size) {
        return false;
    }
    for (const aValue of a) {
        if (!b.has(aValue)) {
            return false;
        }
    }
    for (const bValue of b) {
        if (!a.has(bValue)) {
            return false;
        }
    }
    return true;
}
