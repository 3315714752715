import { createStore } from '@ngneat/elf';
import { selectAllEntities, setEntities, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { TerritoryI } from '../models/territory';

const store = createStore({ name: 'territory' }, withEntities<TerritoryI>(), withRequestsCache<'territories'>());

export const skipWhileTerritoriesCached = createRequestsCacheOperator(store);
export const CACHED_TERRITORIES_KEY = 'territories';

@Injectable({ providedIn: 'root' })
export class TerritoryRepository {
    territories$ = store.pipe(selectAllEntities());

    setTerritories(payload: TerritoryI[]): void {
        store.update(updateRequestCache(CACHED_TERRITORIES_KEY), setEntities(payload));
    }
}
