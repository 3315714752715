import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Configuration } from '../constant/configuration';

@Pipe({
    name: 'localDateTime'
})
export class LocalDateTime implements PipeTransform {
    constructor(private configuration: Configuration, private translate: TranslateService) {}

    transform(localDateTime: string, utc = false): string {
        const format = this.configuration.datetimeFormats[this.translate.currentLang?.toLowerCase()] as string;
        if (utc) {
            const timezoneOffset = new Date().getTimezoneOffset();
            return moment(localDateTime).subtract(timezoneOffset, 'minutes').format(format);
        } else {
            return moment(localDateTime).format(format);
        }
    }
}
