import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

/** @deprecated */
@Injectable({ providedIn: 'root' })
export class HttpClientService {
    serviceUrl = '/webfront';
    constructor(private httpClient: HttpClient) {}

    /** @deprecated */
    get(url: string, options?: any): Observable<any> {
        return this.httpClient.get(this.serviceUrl + url, options);
    }

    // This is a temporary method in order to migrate all http methods progressively
    getMethod<T>(url: string, options?: object): Observable<T> {
        return this.httpClient.get<T>(this.serviceUrl + url, options);
    }

    put(url: string, body?: any, options?: any): Observable<any> {
        return this.httpClient.put(this.serviceUrl + url, body, options);
    }

    /** @deprecated */
    post(url: string, body?: any, options?: any, baseUrl?: string): Observable<any> {
        const currentBaseUrl = baseUrl === null || baseUrl === undefined ? this.serviceUrl : baseUrl;
        return this.httpClient.post(currentBaseUrl + url, body, options);
    }

    // This is a temporary method in order to migrate all http methods progressively
    postMethod<T>(url: string, body?: unknown, options?: object, baseUrl?: string): Observable<T> {
        const currentBaseUrl = !baseUrl ? this.serviceUrl : baseUrl;
        return this.httpClient.post<T>(currentBaseUrl + url, body, options);
    }

    delete(url: string, options?: any): Observable<any> {
        return this.httpClient.delete(this.serviceUrl + url, options);
    }
}
