import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateService } from '../services/authenticate.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { map } from 'rxjs/operators';
import { Configuration } from '../constant/configuration';
import { inject } from '@angular/core';

export const AUTH_GUARD: {
    canActivate: CanActivateFn;
    canMatch: CanMatchFn;
} = {
    canActivate: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean => {
        const url: string = state.url;
        return checkAccess(url);
    },
    canMatch: (route: Route, segments: UrlSegment[]): Observable<boolean> | boolean => {
        const currentUrl = '/' + segments.map(segment => segment.path).join('/');
        return checkAccess(currentUrl);
    }
};

function isOpenedUrl(currentUrl: string): boolean {
    const configuration = inject(Configuration);
    return !!configuration.openUrls.find(openUrl => currentUrl.startsWith(openUrl));
}

function checkAccess(currentUrl: string): Observable<boolean> | boolean {
    const authenticateService = inject(AuthenticateService);
    const router = inject(Router);

    if (isOpenedUrl(currentUrl)) {
        return true;
    } else {
        return fromPromise(authenticateService.getCurrentUser()).pipe(
            map(currentUser => {
                if (!currentUser) {
                    void router.navigate(['login'], { state: { initialUrl: currentUrl } });
                }
                return !!currentUser;
            })
        );
    }
}
