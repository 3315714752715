import { Component } from '@angular/core';
import { CheckBoxTreeDeterminedState } from '../components/three-state-checkbox/three-state-checkbox.component';
import { CheckBoxTreeItem } from '../components/collapsible-checkbox-tree/collapsible_checkbox_tree_item.type';
import { CheckStateItemUpdate } from '../components/collapsible-checkbox-tree/collapsible-checkbox-tree.component';
import { BehaviorSubject } from 'rxjs';

type TestType = {
    counter: number;
    constantValue: string;
};

@Component({
    selector: 'app-sandbox',
    templateUrl: './sandbox.component.html',
    styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent {
    CheckBoxThreeState = CheckBoxTreeDeterminedState;

    $flatSelectionIds: BehaviorSubject<Array<string>> = new BehaviorSubject([]);

    items: Array<CheckBoxTreeItem> = [
        {
            id: '1',
            label: 'Item 1',
            amount: 20
        },
        {
            id: '2',
            label: 'Item 2',
            amount: 13,
            children: [
                {
                    id: '2.1',
                    label: 'Sub Item 2.1',
                    amount: 11
                },
                {
                    id: '2.2',
                    label: 'Sub Item 2.2',
                    amount: 11
                },
                {
                    id: 'fmd',
                    label: 'Filter Me Dude',
                    amount: 20
                }
            ]
        },
        {
            id: 'fma',
            label: 'Filter Me Again',
            amount: 20
        },
        {
            id: '3',
            label: 'Item 3',
            amount: 20
        },
        {
            id: '4',
            label: 'Item 4',
            amount: 11,
            children: [
                {
                    id: '4.1',
                    label: 'Sub Item 4.1',
                    amount: 11
                },
                {
                    id: '4.2',
                    label: 'Sub Item 4.2',
                    amount: 11
                },
                {
                    id: '4.3',
                    label: 'Sub Item 4.3',
                    amount: 11
                },
                {
                    id: '4.4',
                    label: 'Sub Item 4.4',
                    amount: 11
                }
            ]
        },
        {
            id: '5',
            label: 'Item 5',
            amount: 20
        },
        {
            id: 'fmp',
            label: 'Filter Me Please',
            amount: 20
        }
    ];
    updatedCheckBoxTreeStatusData: Array<CheckStateItemUpdate>;
    updatedCheckBoxTreeDropDownStatusData: Array<CheckStateItemUpdate>;
    selectedItems: Array<CheckBoxTreeItem>;

    revertSelectedValues(): void {
        this.$flatSelectionIds.next(['1', '2', '4.1', '4.3']);
    }

    handleCheckBoxCollapsibleTreeItemsCheckedStatusUpdate(data: Array<CheckStateItemUpdate>): void {
        this.updatedCheckBoxTreeStatusData = data;
    }

    handleCheckBoxCollapsibleTreeDropDownItemsCheckedStatusUpdate(data: Array<CheckStateItemUpdate>): void {
        this.updatedCheckBoxTreeDropDownStatusData = data;
    }

    handleSelectedItemsChange(data: Array<CheckBoxTreeItem>): void {
        this.selectedItems = data;
    }
}
