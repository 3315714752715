import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { selectEntity, updateEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { syncState } from 'elf-sync-state';
import { ILicenseCount } from '../models/license';
import { Observable } from 'rxjs/internal/Observable';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';

interface LicenseCountCompanyStateI {
    id: number; // company id
    licenses: ILicenseCount[];
}

const store = createStore(
    { name: 'licenseCountCompany' },
    withEntities<LicenseCountCompanyStateI>(),
    withRequestsCache<`license-count-companies-${string}`>()
);
export const skipWhileLicenseCountCompanyCached = createRequestsCacheOperator(store);
export const CACHE_KEY_LICENSE_COUNT_COMPANY = 'license-count-companies';

syncState(store);

@Injectable({ providedIn: 'root' })
export class LicenseCountCompanyRepository {
    getLicensesCountByCompanyId(companyId: number): Observable<ILicenseCount[]> {
        return store.pipe(selectEntity(companyId, { pluck: 'licenses' }));
    }

    upsertLicensesCountByCompanyId(companyId: number, licenses: ILicenseCount[]): void {
        store.update(
            updateRequestCache(`${CACHE_KEY_LICENSE_COUNT_COMPANY}-${companyId}`),
            upsertEntities({ id: companyId, licenses })
        );
    }

    addLicensesCountByCompanyId(companyId: number, license: ILicenseCount): void {
        store.update(
            updateRequestCache(`${CACHE_KEY_LICENSE_COUNT_COMPANY}-${companyId}`),
            updateEntities(companyId, e => ({
                ...e,
                licenses: [...e.licenses.filter(l => l.license.id !== license.license.id), license]
            }))
        );
    }

    removeLicensesCountByCompanyId(companyId: number, license: ILicenseCount): void {
        store.update(
            updateEntities(companyId, e => ({
                ...e,
                licenses: e.licenses.filter(l => l.license.id !== license.license.id)
            }))
        );
    }

    clearCacheOfCompany(companyId: number): void {
        store.update(updateRequestCache(`${CACHE_KEY_LICENSE_COUNT_COMPANY}-${companyId}`, { value: 'none' }));
    }
}
