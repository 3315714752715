import { Injectable } from '@angular/core';
import { AuthenticateService } from './authenticate.service';
import { CurrentUserI, UserDefaultRolesE, UserPropsI } from '../models/user';
import routesDataGuards from '../constant/routesDataGuards';

@Injectable({
    providedIn: 'root'
})
export class GuardService {
    constructor(private authenticateService: AuthenticateService) {}

    get currentUser(): CurrentUserI {
        return this.authenticateService.currentUser;
    }

    checkPermissions(permissions: string[]): boolean {
        if (!permissions || permissions.length === 0) {
            return true;
        }
        for (const permission of permissions) {
            // user must have all permissions required to be able to visit the page
            if (!this.currentUser.permissions.includes(permission)) {
                return false;
            }
        }
        return true;
    }
    currentUserCanBeUpgraded(): boolean {
        return (
            !this.currentUser.roles.includes(UserDefaultRolesE.STANDARD_USER) &&
            !this.currentUser.roles.includes(UserDefaultRolesE.COMPANY_MANAGER) &&
            !this.currentUser.roles.includes(UserDefaultRolesE.ADMINISTRATOR)
        );
    }

    checkMarkets(markets: number[]): boolean {
        if (!markets || markets.length === 0) {
            return true;
        }
        return this.currentUser.markets.findIndex(market => markets.includes(market)) !== -1;
    }

    checkProps(props: UserPropsI): boolean {
        if (!props) {
            return true;
        }
        let valid = true;
        for (const key in props) {
            if (Object.prototype.hasOwnProperty.call(props, key)) {
                const prop = props[key];
                if (this.currentUser.props[key] !== prop) {
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }

    checkAuthorizedToAccessRoutePath(path: string): boolean {
        if (!this.currentUser) {
            return false;
        }
        const sanitizedUrl = path[0] === '/' ? path.substring(1) : path;
        const guards = routesDataGuards[sanitizedUrl] || {};
        return (
            this.checkPermissions(guards.permissions) &&
            this.checkMarkets(guards.markets) &&
            this.checkProps(guards.props)
        );
    }
}
