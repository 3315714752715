import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CheckBoxTreeTechnicalItem } from '../collapsible-checkbox-tree/collapsible_checkbox_tree_item.type';

export enum CheckBoxTreeDeterminedState {
    Checked = 'Checked',
    Unchecked = 'Unchecked'
}
export type CheckBoxTreeIndeterminateState = CheckBoxTreeTechnicalItem[];
export type CheckBoxTreeState = CheckBoxTreeDeterminedState | CheckBoxTreeIndeterminateState;

export function isIndeterminateState(state: CheckBoxTreeState): state is CheckBoxTreeIndeterminateState {
    return !(state === CheckBoxTreeDeterminedState.Unchecked || state === CheckBoxTreeDeterminedState.Checked);
}

@Component({
    selector: 'app-three-state-checkbox',
    templateUrl: './three-state-checkbox.component.html',
    styleUrls: ['./three-state-checkbox.component.scss']
})
export class ThreeStateCheckboxComponent implements OnChanges, OnInit {
    CheckBoxThreeState = CheckBoxTreeDeterminedState;

    @Input() label: string;
    @Input() state: CheckBoxTreeState;
    @ViewChild('checkbox', { static: true }) checkbox: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        this.checkbox.nativeElement.indeterminate = isIndeterminateState(this.state);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.checkbox.nativeElement.indeterminate = isIndeterminateState(this.state);
    }
}
