import { IMagazine } from '../models/magazine';
import { createStore } from '@ngneat/elf';
import {
    selectEntity,
    updateEntities,
    withEntities,
    selectEntityByPredicate,
    upsertEntitiesById
} from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface IMagazineState extends IMagazine {
    id: number;
    periodicsId?: number[];
    thumbnail?: string;
}

const store = createStore(
    { name: 'magazine' },
    withEntities<IMagazineState>(),
    withRequestsCache<`magazine-${number}` | `magazine-by-periodic-${number}` | `magazine-thumbnail-${number}`>()
);

export const skipWhileMagazineCached = createRequestsCacheOperator(store);

export enum CACHED_MAGAZINE_KEYS {
    DEFAULT = 'magazine',
    PERIODIC = 'magazine-by-periodic',
    THUMBNAIL = 'magazine-thumbnail'
}

@Injectable({ providedIn: 'root' })
export class MagazineRepository {
    selectById$(id: number): Observable<IMagazine> {
        return store.pipe(selectEntity(id));
    }

    selectByPeriodicId$(id: number): Observable<IMagazine> {
        return store.pipe(selectEntityByPredicate(({ periodicsId }) => periodicsId?.includes(id)));
    }

    selectMagazineThumbnail$(id: number): Observable<string> {
        return store.pipe(selectEntity(id, { pluck: 'thumbnail' }));
    }

    upsertMagazine(payload: IMagazine): void {
        store.update(
            updateRequestCache(`${CACHED_MAGAZINE_KEYS.DEFAULT}-${payload.id}`),
            upsertEntitiesById(payload.id, {
                creator: () => payload as IMagazineState,
                updater: entity => ({
                    ...(payload as IMagazineState),
                    thumbnail: entity?.thumbnail || null
                })
            })
        );
    }

    addMagazineByPeriodic(payload: IMagazine, periodicId: number): void {
        this.upsertMagazine(payload);

        store.update(
            updateRequestCache(`${CACHED_MAGAZINE_KEYS.PERIODIC}-${periodicId}`),
            updateEntities(payload.id, entity => ({
                ...entity,
                periodicsId: [...(entity.periodicsId || []), periodicId]
            }))
        );
    }

    addMagazineThumbnail(id: number, thumbnail: string): void {
        store.update(
            updateRequestCache(`${CACHED_MAGAZINE_KEYS.THUMBNAIL}-${id}`),
            upsertEntitiesById(id, {
                creator: () => ({
                    id,
                    thumbnail
                }),
                updater: entity => ({
                    ...entity,
                    thumbnail
                })
            })
        );
    }
}
