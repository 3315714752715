import { CompanyI } from '../../../../models/company';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { HttpClientService } from '../../../../services/http-client.service';
import { Injectable } from '@angular/core';
import { AbstractCRUDsService } from '../../../../services/abstract_cruds.service';
import { CompanyManagerFilterI, CompanyManagerI } from '../../../../models/companyManager';
import { LicenseI } from '../../../../models/license';
import { UserInvitationI } from '../../../../models/userInvitation';
import { HttpPaginationResponseI } from '../../../../models/http';
import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ExportExcelService } from '../../../../services/export-excel.service';
import { CACHED_SESSION_USER_KEY, SessionUserRepository, skipWhileSessionUserCached } from '../../../../store';
import { AuthenticateService } from '../../../../services/authenticate.service';
import { Pageable } from '../../../../providers/pagination-provider';
import { PaginationData } from '@ngneat/elf-pagination';

@Injectable({ providedIn: 'root' })
export class SessionCompanyService extends AbstractCRUDsService {
    selectedUser: CompanyManagerI;
    companyUpdatedSubject: Subject<void> = new Subject<void>();
    companySuccessfullyRequestedUpgradeSubject: Subject<void> = new Subject<void>();
    usersOnCurrentPage$ = this.sessionUserRepository.sessionUsersOnCurrentPage$;

    constructor(
        httpService: HttpClientService,
        private httpClient: HttpClient,
        private exportExcelService: ExportExcelService,
        private authenticateService: AuthenticateService,
        private sessionUserRepository: SessionUserRepository
    ) {
        super('/session-company', httpService);
    }

    get companyUuid(): string {
        return this.authenticateService.currentUser.companyUuid;
    }

    get currentPaginationData(): PaginationData {
        return this.sessionUserRepository.getPaginationData();
    }
    /**
     * Get company of current user
     * @return Promise<CompanyI> The company
     */
    async getSessionCompany(): Promise<CompanyI> {
        return firstValueFrom<CompanyI>(this.httpService.get(this.serviceUrl));
    }

    /**
     * Update company of current user
     * @return Promise<CompanyI> The company updated
     */
    async updateSessionCompany(payload: CompanyI): Promise<CompanyI> {
        return firstValueFrom<CompanyI>(
            this.httpService.put(this.serviceUrl, payload).pipe(
                tap(() => {
                    this.companyUpdatedSubject.next();
                })
            )
        );
    }

    /**
     * Invite users by email to company of current user
     * @return Promise<string[]> Emails whose subscription failed
     */
    inviteUsersToSessionCompany(emails: string[]): Observable<string[]> {
        return this.httpService.put(`${this.serviceUrl}/invite`, emails);
    }

    /**
     * Updates the number of legal professionals to company of current user
     * @return Promise<any>
     */
    async updateLegalProfessionals(numberOfLegalProfessionals: number): Promise<any> {
        return firstValueFrom(
            this.httpService.put(`${this.serviceUrl}/legal-professionals/${numberOfLegalProfessionals}`)
        );
    }

    /**
     * Request to the super admin to review this company contract
     * @return Observable<any>
     */
    requestUpgrade(): Observable<void> {
        return this.httpService.put(`${this.serviceUrl}/request-upgrade`).pipe(
            tap(() => {
                this.companySuccessfullyRequestedUpgradeSubject.next();
            })
        );
    }

    getUsersOfSessionCompany(
        filter: CompanyManagerFilterI,
        pageable: Pageable
    ): Observable<HttpPaginationResponseI<CompanyManagerI[]>> {
        this.sessionUserRepository.clearCacheIfDistinctFiltersOrSize(this.companyUuid, filter, pageable.size);
        this.sessionUserRepository.updateCurrentPageIfSessionUserIsCached(
            pageable.page,
            `${CACHED_SESSION_USER_KEY}-${this.companyUuid}-${pageable.page}`
        );

        return this.httpClient
            .post<HttpPaginationResponseI<CompanyManagerI[]>>(`/webfront${this.serviceUrl}/users`, filter, {
                params: { ...pageable }
            })
            .pipe(
                tap(response => {
                    this.sessionUserRepository.setActiveFilters(this.companyUuid, filter);
                    this.sessionUserRepository.addPaginationUsers(this.companyUuid, response, pageable);
                }),
                skipWhileSessionUserCached(`${CACHED_SESSION_USER_KEY}-${this.companyUuid}-${pageable.page}`)
            );
    }

    getLicensesOfSessionCompany(): Observable<LicenseI[]> {
        return this.httpClient.get<LicenseI[]>(`/webfront${this.serviceUrl}/available-licenses`);
    }

    getInvitations(): Observable<UserInvitationI[]> {
        return this.httpService.get(`${this.serviceUrl}/invitation-list`);
    }

    reSendInvitation(email: string): Observable<void> {
        return this.httpService.put(`${this.serviceUrl}/re-invite`, email);
    }

    hasAccessToBookPart(bookPartId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`/webfront${this.serviceUrl}/has-access-to-book-part/${bookPartId}`, {
            headers: new HttpHeaders().append('skipSpinner', 'true')
        });
    }

    exportCompanyUsersReport(): Observable<ArrayBuffer> {
        return this.exportExcelService.fetchExcelReportAndDownload(
            `/webfront${this.serviceUrl}`,
            'users.xlsx',
            '/users.xlsx'
        );
    }

    addUserInRepository(companyManager: CompanyManagerI): void {
        this.sessionUserRepository.addUser(companyManager);
        this.refreshCurrentRepositoryPage();
    }

    refreshCurrentRepositoryPage(): void {
        this.getUsersOfSessionCompany(
            this.sessionUserRepository.activeFilters[this.companyUuid],
            this.sessionUserRepository.currentPageable
        ).subscribe();
    }

    deleteUserInRepository(userUuid: string): void {
        this.sessionUserRepository.deleteUser(userUuid);
        this.refreshCurrentRepositoryPage();
    }
}
