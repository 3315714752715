import { Pipe, PipeTransform } from '@angular/core';
import { CheckBoxTreeTechnicalItem } from './collapsible_checkbox_tree_item.type';
import { TranslateService } from '@ngx-translate/core';
import { removeAccents } from '../../shared/utils/stringUtils';

@Pipe({
    name: 'sortCollapsibleElements'
})
export class SortCollapsibleElementsPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(checkboxes: CheckBoxTreeTechnicalItem[], translationContext: string): CheckBoxTreeTechnicalItem[] {
        return checkboxes.sort((n1, n2) => {
            const label1 = removeAccents(
                this.translateService.instant(translationContext + n1.data.label)
            ).toUpperCase();
            const label2 = removeAccents(
                this.translateService.instant(translationContext + n2.data.label)
            ).toUpperCase();

            if (label1 > label2) {
                return 1;
            }
            if (label1 < label2) {
                return -1;
            }
            return 0;
        });
    }
}
