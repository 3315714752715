import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-pagination',
    styles: [
        `
            .page-size-selector {
                text-align: center;
                min-width: 60px !important;
            }
        `
    ],
    template: `
        <div class="pagination-menu">
            <nav class="navigation">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" title="First page" (click)="firstPage(filter)">
                            <i class="fa fa-angle-double-left"></i>
                        </a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" title="Previous page" (click)="previousPage(filter)">
                            <i class="fa fa-angle-left"></i>
                        </a>
                    </li>
                    <li class="page-item">
                        <span class="float-start">{{ 'mybooks.pagination.page' | translate }}</span>
                        <span> {{ currentPageNumber + (1 - modelPageStart) }} </span>
                        <span>{{ 'mybooks.pagination.of' | translate }}</span>
                        <span> {{ totalPage }} </span>
                    </li>
                    <li class="page-item">
                        <a class="page-link" title="Next page" (click)="nextPage(filter)">
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" title="Last page" (click)="lastPage(filter)">
                            <i class="fa fa-angle-double-right"></i>
                        </a>
                    </li>
                </ul>
            </nav>
            <span class="dropdown float-end">
                <span class="sortBy">{{ 'global.sort.display' | translate }}</span>
                <div class="btn-group" role="group">
                    <button type="button" class="btn" data-bs-toggle="dropdown">
                        {{ currentPageSize }}
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu page-size-selector" aria-labelledby="dropdownMenu1">
                        <li *ngFor="let pageSize of pageSizes">
                            <a (click)="updateCollectionAndChangePageSize(pageSize)">
                                {{ pageSize }}
                            </a>
                        </li>
                    </ul>
                </div>
            </span>
        </div>
    `
})
export class PaginationComponent {
    @Input() currentPageSize: number;
    @Input() pageSizes: number[];
    @Input() currentPageNumber: number;
    @Input() modelPageStart: number;
    @Input() totalPage: number;
    @Input() filter: any;
    @Input() firstPage: (filter: any) => void;
    @Input() previousPage: (filter: any) => void;
    @Input() nextPage: (filter: any) => void;
    @Input() lastPage: (filter: any) => void;
    @Input() updateCollectionAndChangePageSize: (pageSize: number) => void;
}
