import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatMinutesHours'
})
export class FormatMinutesHoursPipe implements PipeTransform {
    transform(minutes: number): string {
        if (minutes == null) {
            return '?';
        }
        if (minutes < 60) {
            return minutes + ' min';
        } else {
            return Math.floor(minutes / 60) + ' h ' + (minutes % 60) + ' min';
        }
    }
}
