import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'labelTruncator',
    pure: false
})
export class LabelTruncatorFilter implements PipeTransform {
    transform(label: string, maxSize: number): string {
        if (!label) {
            return '';
        }
        if (!maxSize) {
            return label;
        }
        if (label.length <= maxSize) {
            return label;
        }

        const newLabel = label.substr(0, maxSize);

        return newLabel + '[...]';
    }
}
