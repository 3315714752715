import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from '../../constant/configuration';
import { DisplayType } from '../../online/document/service/DisplayType';
import { map, share } from 'rxjs/operators';
import { AuthenticationTypeE } from '../../models/user';

export interface SearchParams {
    id: number;
    reference: string;
    evidensseId: string;
    hits: string;
    keys: string;
}

export type Credentials = {
    timestamp?: number;
    email: string;
    password: string;
};

export type TrialCredentials = {
    email?: string;
    firstname?: string;
    name?: string;
    language?: string;
    market?: string;
};

type LocalStorageSetItemFunction = (key: string, value: string) => void;

const LS_DISPLAY_TYPE = 'ls.displayType';

@Injectable({ providedIn: 'root' })
export class LocalStorageAdapter {
    valueOfConsultedDocuments: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    consultedDocumentStorageKey = 'ls.consultedDocumentIds';
    searchParamsStorageKey = 'search-params';
    localStorageOriginalSetItem: LocalStorageSetItemFunction;

    constructor(private configuration: Configuration) {
        window.addEventListener('storage', () => this.fetchConsultedDocumentsValuesFromLocalStorage());
        this.localStorageOriginalSetItem = localStorage.setItem.bind(localStorage) as LocalStorageSetItemFunction;
        localStorage.setItem = (key, value) => {
            this.fetchConsultedDocumentsValuesFromLocalStorage();
            this.localStorageOriginalSetItem(key, value);
        };
        this.fetchConsultedDocumentsValuesFromLocalStorage();
    }

    get selectedLanguageCode(): string {
        return (
            localStorage.getItem(this.configuration.localStorageKeys.userLanguageKey) ||
            this.paramsLang ||
            this.navigatorFallbackLanguage ||
            'fr'
        );
    }

    set selectedLanguageCode(value: string) {
        localStorage.setItem(this.configuration.localStorageKeys.userLanguageKey, value);
    }

    get navigatorFallbackLanguage(): string {
        const navigatorLang = navigator.language.split('-')[0].toLowerCase();
        return this.configuration.languages[navigatorLang] ? navigatorLang : null;
    }

    get paramsLang(): string {
        const queryString = window.location.href.split('?')[1];
        if (!queryString) {
            return null;
        }
        const urlParams = new URLSearchParams(queryString);
        const lang = urlParams.get('lang');
        return lang && this.configuration.languages[lang] ? lang : null;
    }

    get credentials(): Credentials | null {
        return JSON.parse(localStorage.getItem(this.configuration.localStorageKeys.credentials));
    }

    set credentials(value: Credentials | null) {
        if (value == null) {
            localStorage.removeItem(this.configuration.localStorageKeys.credentials);
        } else {
            localStorage.setItem(this.configuration.localStorageKeys.credentials, JSON.stringify(value));
        }
    }

    get userAuthenticationType(): AuthenticationTypeE | null {
        return JSON.parse(localStorage.getItem(this.configuration.localStorageKeys.authenticationType));
    }
    // we need to store use authentication type to have possibility restore it for current user in case Session lost
    set userAuthenticationType(value: AuthenticationTypeE | null) {
        if (value == null) {
            localStorage.removeItem(this.configuration.localStorageKeys.authenticationType);
        } else {
            localStorage.setItem(this.configuration.localStorageKeys.authenticationType, JSON.stringify(value));
        }
    }

    fetchConsultedDocumentsValuesFromLocalStorage(): void {
        const newValue = localStorage.getItem(this.consultedDocumentStorageKey);
        const oldValue = JSON.stringify(this.valueOfConsultedDocuments.value);
        if (newValue && newValue !== oldValue && newValue.length > oldValue.length) {
            this.valueOfConsultedDocuments.next(JSON.parse(newValue) as string[]);
        } else if (!newValue) {
            this.localStorageOriginalSetItem(this.consultedDocumentStorageKey, JSON.stringify([]));
            this.valueOfConsultedDocuments.next([]);
        }
    }

    isDocumentConsultedAlready(documentId: string): Observable<boolean> {
        return this.valueOfConsultedDocuments.asObservable().pipe(
            map(consultedDocs => consultedDocs.indexOf(documentId) !== -1),
            share()
        );
    }

    trackAsConsultedDocument(documentId: string): void {
        const consultedDocuments = this.valueOfConsultedDocuments.value;
        if (consultedDocuments.indexOf(String(documentId)) === -1) {
            const newConsultedElements = [String(documentId), ...consultedDocuments];
            localStorage.setItem(this.consultedDocumentStorageKey, JSON.stringify(newConsultedElements));
            this.valueOfConsultedDocuments.next(newConsultedElements);
        }
    }

    resetConsultedDocumentIds(): void {
        this.localStorageOriginalSetItem(this.consultedDocumentStorageKey, JSON.stringify([]));
        this.valueOfConsultedDocuments.next([]);
    }

    get displayType(): DisplayType {
        if (localStorage.getItem(LS_DISPLAY_TYPE)) {
            return JSON.parse(localStorage.getItem(LS_DISPLAY_TYPE)).value as DisplayType;
        }
        return DisplayType.GRID;
    }

    set displayType(value: DisplayType) {
        localStorage.setItem(LS_DISPLAY_TYPE, JSON.stringify({ value }));
    }

    get searchParams(): SearchParams {
        const params: SearchParams = JSON.parse(localStorage.getItem(this.searchParamsStorageKey)) as SearchParams;
        this.localStorageOriginalSetItem(this.searchParamsStorageKey, JSON.stringify({})); // this params are meant to be used only once
        return params;
    }

    set searchParams(params: SearchParams) {
        this.localStorageOriginalSetItem(this.searchParamsStorageKey, JSON.stringify(params));
    }

    set publicLibraryDisplayType(displayType: DisplayType) {
        localStorage.setItem(this.configuration.localStorageKeys.publicLibraryDisplayType, displayType);
    }

    get publicLibraryDisplayType(): DisplayType {
        return localStorage.getItem(this.configuration.localStorageKeys.publicLibraryDisplayType) as DisplayType;
    }
}
