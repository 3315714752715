import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, share, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthorService } from '../../../../shared/components/author-input-multiple-selection/author.service';

@Component({
    selector: 'app-autocomplete-author-input',
    templateUrl: './autocomplete-author-input.component.html',
    styleUrls: ['./autocomplete-author-input.component.scss']
})
export class AutocompleteAuthorInputComponent {
    searchValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    @Output() listOf = this.searchValue$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(text => {
            if (text.length > 2) {
                return this.authorService.filter(text.trim());
            } else {
                return of([]);
            }
        }),
        share()
    );
    @Input() set filterValue(value: string) {
        this.searchValue$.next(value);
        this.searchValueControl.setValue(value);
    }
    get filterValue(): string {
        return this.searchValue$.value;
    }
    @Output() filterValueChange = new EventEmitter<string>();

    searchValueControl = new UntypedFormControl('', [Validators.minLength(3)]);

    constructor(private authorService: AuthorService) {}

    filter(): void {
        this.searchValue$.next(this.searchValueControl.value);
        this.filterValueChange.emit(this.searchValueControl.value);
    }
}
