export const MARKET_CODE_LU = 'lu';
export const MARKET_CODE_BE = 'be';

export interface MarketI {
    id?: number;
    code: string;
}

export enum MarketE {
    BE = 1,
    LU = 0
}
