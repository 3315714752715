import { Component } from '@angular/core';
import { Configuration } from '../../../constant/configuration';
import { MessageBus } from '../../../messaging/MessageBus';

type AlertI = {
    type: string;
    message: string;
    translateParameters: Record<string, string>;
    duration: number;
};

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {
    alerts: AlertI[] = [];

    constructor(configuration: Configuration, messageBus: MessageBus) {
        messageBus.channel(configuration.messageBusChannels.ALERT_CHANNEL).subscribe(message => {
            this.addAlert(message.data);
            this.launchAlertDuration(message.data);
        });
    }

    addAlert(alert: AlertI): void {
        this.alerts.push({
            type: alert.type,
            message: alert.message,
            translateParameters: alert.translateParameters,
            duration: alert.duration
        });
    }

    // Set an alert display duration
    launchAlertDuration(alert: AlertI): void {
        if (alert.duration) {
            setTimeout(() => {
                this.removeAlert(alert);
            }, alert.duration);
        }
    }

    // Remove an alert from the list of alerts
    removeAlert(alert: AlertI): void {
        const index = this.alerts.indexOf(alert);
        this.close(index);
    }

    close(index: number): void {
        this.alerts.splice(index, 1);
    }

    getAlertClass(alertType: string): string {
        switch (alertType) {
            case 'success':
            case 'info':
                return 'alert-success';
            case 'warning':
                return 'alert-warning';
            case 'error':
                return 'alert-danger';
            default:
                return 'alert-success';
        }
    }
}
