import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Configuration } from '../constant/configuration';

@Pipe({
    name: 'localDate'
})
export class LocalDate implements PipeTransform {
    constructor(private configuration: Configuration, private translate: TranslateService) {}

    transform(value: any): string {
        const format = this.configuration.dateFormats[this.translate.currentLang?.toLowerCase()];
        return moment(value).format(format);
    }
}
