// original: https://github.com/ovsleep/switch
import {
    Component,
    Input,
    ElementRef,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChange,
    ViewChild,
    Renderer2,
    AfterViewInit,
    AfterViewChecked,
    ViewEncapsulation,
    OnInit
} from '@angular/core';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { SwitchAnimations } from './switch.animations';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    animations: SwitchAnimations
})
export class SwitchComponent implements OnChanges, AfterViewChecked, AfterViewInit {
    @ViewChild('on') onSpanRerf: ElementRef;
    @ViewChild('off') offSpanRef: ElementRef;
    @ViewChild('mid') midSpanRef: ElementRef;
    @ViewChild('container') containerRef: ElementRef;
    @ViewChild('main') mainRef: ElementRef;

    // public properties
    @Input() status = false;
    @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() onText = 'on';
    @Input() offText = 'off';
    @Input() onColor = 'switch-info';
    @Input() offColor = 'switch-default';
    @Input() size = 'normal';
    @Input() disabled = false;

    statusStr = 'false';

    // styles properties
    onColorClass = 'switch-info';
    offColorClass = 'switch-default';
    minWidth = 60;
    width = this.minWidth;
    separatorWidth = 5;
    sizeClass = 'switch-normal';
    disabledClass = '';
    needCalculateWidth = false;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this._calculateSize();
    }

    ngAfterViewInit(): void {
        this.updateBorder();
    }

    updateBorder(): void {
        if (this.status) {
            this.renderer.addClass(this.mainRef.nativeElement, 'switch-selected');
        } else {
            this.renderer.removeClass(this.mainRef.nativeElement, 'switch-selected');
        }
    }
    toggleStatus(): void {
        if (!this.disabled) {
            this.status = !this.status;
            this.updateBorder();
            this.statusStr = this.status.toString();
            this.statusChange.emit(this.status);
        }
    }

    private _setDisabled(disabled: boolean): void {
        if (disabled) {
            this.disabledClass = 'switch-disabled';
        } else {
            this.disabledClass = '';
        }
    }

    private _setColor(switchLabel: string, value: string): void {
        let color = '';
        let defaultColor = 'switch-info';

        if (switchLabel === 'off') {
            defaultColor = 'switch-default';
        }

        switch (value) {
            case 'default':
                color = defaultColor;
                break;
            case 'blue':
                color = 'switch-primary';
                break;
            case 'sky-blue':
                color = 'switch-info';
                break;
            case 'red':
                color = 'switch-danger';
                break;
            case 'yellow':
                color = 'switch-warning';
                break;
            case 'green':
                color = 'switch-success';
                break;
            case 'gray':
                color = 'switch-default';
                break;
        }

        if (switchLabel === 'off') {
            this.offColorClass = color;
        } else {
            this.onColorClass = color;
        }
    }

    private _calculateWidth(): void {
        if (this.onSpanRerf != null) {
            this.renderer.setStyle(this.onSpanRerf.nativeElement, 'width', '');
            this.renderer.setStyle(this.midSpanRef.nativeElement, 'width', '');
            this.renderer.setStyle(this.offSpanRef.nativeElement, 'width', '');
            this.renderer.setStyle(this.mainRef.nativeElement, 'width', '');

            this.width = Math.max(
                this.onSpanRerf.nativeElement.clientWidth,
                this.offSpanRef.nativeElement.clientWidth,
                this.minWidth
            );

            this.renderer.setStyle(this.onSpanRerf.nativeElement, 'width', this.width.toString() + 'px');
            this.renderer.setStyle(this.midSpanRef.nativeElement, 'width', this.separatorWidth.toString() + 'px');
            this.renderer.setStyle(
                this.midSpanRef.nativeElement,
                'margin-left',
                -this.separatorWidth.toString() / 2 + 'px'
            );
            this.renderer.setStyle(
                this.midSpanRef.nativeElement,
                'margin-right',
                -this.separatorWidth.toString() / 2 + 'px'
            );
            this.renderer.setStyle(this.offSpanRef.nativeElement, 'width', this.width.toString() + 'px');
            this.renderer.setStyle(this.containerRef.nativeElement, 'width', (this.width * 2).toString() + 'px');
            this.renderer.setStyle(this.mainRef.nativeElement, 'width', this.width.toString() + 'px');
        }
    }

    private _calculateSize(): void {
        switch (this.size) {
            case 'mini':
                this.sizeClass = 'switch-mini';
                this.separatorWidth = 4;
                this.minWidth = 25;
                break;
            case 'small':
                this.sizeClass = 'switch-small';
                this.separatorWidth = 16;
                this.minWidth = 42;
                break;
            case 'normal':
                this.sizeClass = 'switch-normal';
                this.separatorWidth = 10;
                this.minWidth = 60;
                break;
            case 'large':
                this.sizeClass = 'switch-large';
                this.separatorWidth = 12;
                this.minWidth = 80;
                break;
        }

        this.needCalculateWidth = true;
    }

    ngAfterViewChecked(): void {
        if (this.needCalculateWidth) {
            this._calculateWidth();
            this.needCalculateWidth = false;
        }
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
        const log: string[] = [];
        for (const propName in changes) {
            if (Object.prototype.hasOwnProperty.call(changes, propName)) {
                const changedProp = changes[propName];
                const from = changedProp.previousValue;
                const value = changedProp.currentValue;

                switch (propName) {
                    case 'onText':
                        this.needCalculateWidth = true;
                        break;
                    case 'offText':
                        this.needCalculateWidth = true;
                        break;
                    case 'onColor':
                        this._setColor('on', value);
                        break;
                    case 'offColor':
                        this._setColor('off', value);
                        break;
                    case 'status':
                        this.statusStr = value?.toString() || 'false';
                        break;
                    case 'size':
                        this._calculateSize();
                        break;
                    case 'disabled':
                        this._setDisabled(value);
                        break;
                }
            }
        }
    }
}
