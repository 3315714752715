import { Component } from '@angular/core';
import { TimesheetSharedService } from '../service/timesheet.shared.service';
import { TimesheetRecordingService } from '../service/timesheet.recording.service';

@Component({
    selector: 'app-timesheet-info-popup',
    templateUrl: './infopopup.component.html',
    styleUrls: ['./infopopup.component.scss']
})
export class InfoPopupComponent {
    description = '';
    reference = '';

    open: boolean;

    constructor(public sharedService: TimesheetSharedService, public service: TimesheetRecordingService) {}

    show(): void {
        this.open = true;
    }

    close(): void {
        this.open = false;
    }

    toggle(): void {
        this.open = !this.open;
        if (
            this.sharedService.reference &&
            this.sharedService.description &&
            this.service.currentGroupUuid &&
            (this.sharedService.reference !== this.reference || this.sharedService.description !== this.description)
        ) {
            // it means information changed so we should update this group with new ref and desc
            void this.service.updateTimesheetGroup({
                groupUuid: this.service.currentGroupUuid,
                reference: this.reference,
                description: this.description
            });
        }
        this.sharedService.setReferenceDescription(this.reference, this.description);
    }

    setReferenceDescription(reference, description): void {
        this.reference = reference;
        this.description = description;
    }

    reset(): void {
        this.description = '';
        this.reference = '';
    }
}
