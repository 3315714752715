import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PairKeyLabelI } from 'src/app/models/filter';

@Component({
    selector: 'app-migrated-type-ahead-dropdown',
    templateUrl: './migrated-type-ahead-dropdown.component.html',
    styleUrls: ['./migrated-type-ahead-dropdown.component.scss']
})
export class MigratedTypeAheadDropdownComponent implements OnChanges {
    @Input() filteredKeyLabelList: PairKeyLabelI[];
    @Input() initialKeyLabelSelection: PairKeyLabelI[];
    @Input() title: string;
    @Input() minimumFilterLength: number;
    modelList: { isSelected: boolean; label: string; key: string }[];
    filterValue = '';
    numberOfSelected = 0;
    @Output() updateSelection: EventEmitter<PairKeyLabelI[]> = new EventEmitter<PairKeyLabelI[]>();
    @Output() notifyTypeAheadChange: EventEmitter<string> = new EventEmitter<string>();

    handleOpenChange(isOpen: boolean): void {
        if (isOpen === false) {
            this.resetDisplay();
        }
    }

    onSubmitClick(): void {
        this.updateSelection.emit(this.generateSelectedList());
    }

    onRemoveClick(): void {
        this.modelList = [];
        this.numberOfSelected = 0;
        this.updateSelection.emit(this.generateSelectedList());
    }

    onFilterChange(newValue: string): void {
        if (newValue.length >= this.minimumFilterLength) {
            this.notifyTypeAheadChange.emit(newValue);
        } else {
            this.modelList = [];
        }
    }

    generateSelectedList(): PairKeyLabelI[] {
        return this.modelList
            .filter(keyLabelValue => keyLabelValue.isSelected)
            .map(keyLabelValue => ({ key: keyLabelValue.key, label: keyLabelValue.label }));
    }

    generateModelList(): { isSelected: boolean; label: string; key: string }[] {
        let modelList: { isSelected: boolean; label: string; key: string }[] = [];
        if (this.initialKeyLabelSelection) {
            modelList = this.initialKeyLabelSelection.map(pairKeyLabel => {
                this.numberOfSelected += 1;
                return { isSelected: true, label: pairKeyLabel.label, key: pairKeyLabel.key };
            });
        }

        this.filteredKeyLabelList.forEach(pairKeyLabel => {
            if (!modelList.map(pairKeyLabelValue => pairKeyLabelValue.key).includes(pairKeyLabel.key)) {
                modelList.push({ isSelected: false, key: pairKeyLabel.key, label: pairKeyLabel.label });
            }
        });

        return modelList;
    }

    resetDisplay(): void {
        this.numberOfSelected = 0;
        this.modelList = this.generateModelList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filteredKeyLabelList) {
            this.resetDisplay();
        }
        if (changes.initialKeyLabelSelection) {
            this.resetDisplay();
        }
    }
}
