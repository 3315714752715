import { AnyConstructor } from '../anyconstructor.mixin';
import { Message } from '../../messaging/MessageBus';
import { Subject } from 'rxjs';
import { MixinBase } from '../crud/MixinBase';

const mixin = <T extends AnyConstructor<MixinBase>>(Base: T) =>
    class extends Base {
        alertChannel: Subject<Message>;

        public initAlertMixin(alertChannel: Subject<Message>): void {
            this.alertChannel = alertChannel;
        }

        public alert(alert): any {
            this.alertChannel.next({ message: 'display', data: alert });
        }
    };

type AlertMixinType<T> = ReturnType<typeof mixin> & T;

export function AlertMixin<T extends AnyConstructor<MixinBase>>(Base: T): AlertMixinType<T> {
    return mixin<T>(Base);
}
