import { ParseRomanPipe } from './parse-roman.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortAlphanumeric'
})
export class SortAlphanumericPipe implements PipeTransform {
    parseRomanPipe = new ParseRomanPipe();
    articleOnRomanRegExp = new RegExp('^art[.] (.*)$', 'i');

    transform(array: any, field: string, sortEmptyToEnd = false): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            const aFormatted = this.format(a[field] as string);
            const bFormatted = this.format(b[field] as string);

            if (sortEmptyToEnd && (aFormatted === '' || bFormatted === '')) {
                if (aFormatted === '' && bFormatted === '') {
                    return 0;
                } else {
                    return aFormatted === '' ? 1 : -1;
                }
            }

            const aOnRoman = this.convertIfIsOnRomanFormat(aFormatted);
            const bOnRoman = this.convertIfIsOnRomanFormat(bFormatted);

            const textA = aOnRoman ? aOnRoman : aFormatted;
            const textB = bOnRoman ? bOnRoman : bFormatted;

            const valueComparator = textA.localeCompare(textB, undefined, { numeric: true, sensitivity: 'base' });
            if (valueComparator === 0) {
                // to place Art. 1 before Art. I
                if (aOnRoman && !bOnRoman) {
                    return 1;
                } else if (!aOnRoman && bOnRoman) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                return valueComparator;
            }
        });
        return array;
    }

    format(text: string): string {
        return text.trim().replace(/^§+/, '§').replace(/^§ +/, '§').replace(/\.$/, '');
    }

    convertIfIsOnRomanFormat(text: string): string {
        const find = this.articleOnRomanRegExp.exec(text);
        if (!find) {
            return null;
        }
        if (!this.parseRomanPipe.isRoman(find[1])) {
            return null;
        }
        const romanValue = this.parseRomanPipe.transform(find[1]);
        return text.replace(/^(art\. ).*$/i, '$1' + romanValue + '$2');
    }
}
