import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform(array: any[], field: string, desc = false): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
            if (a[field] && b[field]) {
                let aValue = a[field].toString();
                let bValue = b[field].toString();
                if (moment.isMoment(a[field])) {
                    aValue = (a[field] as Moment).toISOString();
                }
                if (moment.isMoment(b[field])) {
                    bValue = (b[field] as Moment).toISOString();
                }
                const valueComparator = aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
                return valueComparator * (desc ? -1 : 1);
            } else {
                if (a[field] && !b[field]) {
                    return -1;
                } else if (!a[field] && b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });

        return array;
    }
}
