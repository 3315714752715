import { createStore } from '@ngneat/elf';
import { selectAllEntities, setEntities, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { PartnerI } from '../models/partner';
import { Injectable } from '@angular/core';

const store = createStore({ name: 'bookPartner' }, withEntities<PartnerI>(), withRequestsCache<'book-partners'>());

export const skipWhileBookPartnersCached = createRequestsCacheOperator(store);
export const CACHED_BOOK_PARTNERS_KEY = 'book-partners';

@Injectable({ providedIn: 'root' })
export class BookPartnerRepository {
    bookPartners$ = store.pipe(selectAllEntities());
    setBookPartners(payload: PartnerI[]): void {
        store.update(updateRequestCache(CACHED_BOOK_PARTNERS_KEY), setEntities(payload));
    }
}
