import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MessageBus } from '../messaging/MessageBus';
import { Configuration } from '../constant/configuration';

@Injectable({ providedIn: 'root' })
export class TitleService {
    constructor(
        private translateService: TranslateService,
        private messageBus: MessageBus,
        private configuration: Configuration,
        private titleService: Title
    ) {}

    initialize(): void {
        this.translateService.get('global.title').subscribe(title => this.titleService.setTitle(title));
        this.messageBus.channel(this.configuration.messageBusChannels.LANGUAGE_CHANNEL).subscribe(message => {
            this.translateService.use(message.data);
            this.translateService.get('global.title').subscribe(title => this.titleService.setTitle(title));
        });
    }
}
