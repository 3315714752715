import { createStore, setProp, setProps, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { NotificationMessageService } from '../services/notification_message.service';

interface UserBookActionState {
    printsAndDownloadsOnDayInterval: Array<{
        userUuid: string;
        bookPartIds: number[];
    }>;
    currentDay: string;
}

const store = createStore(
    { name: 'userBookAction' },
    withProps<UserBookActionState>({
        printsAndDownloadsOnDayInterval: [],
        currentDay: new Date().toISOString().split('T')[0]
    })
);

persistState(store, {
    storage: localStorageStrategy,
    key: 'userBookAction@store'
});

@Injectable({ providedIn: 'root' })
export class UserBookActionRepository {
    thresholdDownloadOrPrintByDay = 10;
    constructor(private notificationService: NotificationMessageService) {
        this.resetPrintsAndDownloadsIfOutDated();
    }

    incrementPrintsAndDownloadsForUser(userUuid: string, bookPartId: number): void {
        store.update(
            setProp('printsAndDownloadsOnDayInterval', printsAndDownloadsOnDayInterval => {
                const currentIndex = printsAndDownloadsOnDayInterval.findIndex(item => item.userUuid === userUuid);
                if (~currentIndex) {
                    const clone = printsAndDownloadsOnDayInterval.slice();
                    if (!clone[currentIndex].bookPartIds.includes(bookPartId)) {
                        clone[currentIndex].bookPartIds.push(bookPartId);
                        if (
                            clone[currentIndex].bookPartIds.length >= this.thresholdDownloadOrPrintByDay &&
                            clone[currentIndex].bookPartIds.length % 5 === 0
                        ) {
                            this.showWarningTooManyDownloadsAndPrints();
                        }
                    }
                    return clone;
                } else {
                    return [{ userUuid, bookPartIds: [bookPartId] }, ...printsAndDownloadsOnDayInterval];
                }
            })
        );
    }

    private resetPrintsAndDownloadsIfOutDated(): void {
        const stateCurrentDay = store.getValue().currentDay;
        const currentDay = new Date().toISOString().split('T')[0];
        if (stateCurrentDay && stateCurrentDay !== currentDay) {
            store.update(
                setProps({
                    currentDay,
                    printsAndDownloadsOnDayInterval: []
                })
            );
        }
    }

    private showWarningTooManyDownloadsAndPrints(): void {
        this.notificationService.init(
            'document.popups.warningTooManyDownloadsAndPrints.title',
            'document.popups.warningTooManyDownloadsAndPrints.message'
        );
        this.notificationService.addLeftButton({ label: 'global.action.ok' });
        this.notificationService.show();
    }
}
