import { AuthenticateService } from '../../services/authenticate.service';
import { Configuration } from '../../constant/configuration';
import { Component } from '@angular/core';
import { LanguageService } from '../../shared/service/language.service';
import { NotificationMessageService } from '../../services/notification_message.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-main-forget-dialog',
    templateUrl: './main-forget-dialog.component.html'
})
export class MainForgetDialogComponent {
    forgetInformation: Partial<{
        language: string;
        identifier: string; // can be an email or a username
    }> = {};

    constructor(
        private languageService: LanguageService,
        private configuration: Configuration,
        private authenticateService: AuthenticateService,
        private notificationMessageService: NotificationMessageService,
        private dialogRef: MatDialogRef<MainForgetDialogComponent>
    ) {
        this.dialogRef.addPanelClass('w-600');
    }

    forget(): void {
        this.forgetInformation.language = this.languageService.selectedLanguageData.twoLettersLanguage;
        this.authenticateService.forget(this.forgetInformation).subscribe(() => {
            this.alertMailSent();
            this.closeDialog();
        });
    }

    alertMailSent(): void {
        this.notificationMessageService.init('account.forget.success.title', 'account.forget.success.message');
        this.notificationMessageService.initTranslationValues({}, { emailAddress: this.forgetInformation.identifier });
        this.notificationMessageService.addLeftButton({ label: 'global.action.ok' });
        this.notificationMessageService.show();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
