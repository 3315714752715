import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-sort-field',
    templateUrl: './sort-field.component.html'
})
export class SortFieldComponent implements OnInit {
    @Input() fields: Array<{ label: string; value: string }>;
    @Input() sort;
    @Input() desc;
    @Output() sortChangedEvent = new EventEmitter<string>();
    _desc = false;
    _sort = '';

    ngOnInit(): void {
        this._sort = this.sort;
        this._desc = this.desc;
    }

    changeAscending(): void {
        this._desc = !this._desc;
        this.onSort();
    }

    onSort(): void {
        if (this._sort) {
            const desc = this._desc ? ',DESC' : '';
            this.sortChangedEvent.emit(this._sort + desc);
        } else {
            this.sortChangedEvent.emit('');
        }
    }
}
