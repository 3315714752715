import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-filters-search-input',
    templateUrl: './filters-search-input.component.html'
})
export class FiltersSearchInputComponent {
    @Input() filterValue: string;
    @Output() filterValueChange: EventEmitter<string> = new EventEmitter();

    emitChanges(value): void {
        this.filterValueChange.emit(value);
    }
}
