import { Pipe, PipeTransform } from '@angular/core';
import { AuthorI } from '../models/author';
@Pipe({
    name: 'authorsToOneLine'
})
export class AuthorsToOneLinePipe implements PipeTransform {
    transform(authors: AuthorI[]): string {
        return authors.map(author => author.fullName).join(', ');
    }
}
