import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { validateAllFormFields } from '../../utils/form-utils';
import { SessionCompanyService } from '../../../online/administration/my-company/service/session-company.service';
import { CompanyI } from '../../../models/company';
import { AlertMixin } from '../../../mixins/alert/alert.mixin';
import { MixinBase } from '../../../mixins/crud/MixinBase';
import { Configuration } from '../../../constant/configuration';
import { MessageBus } from '../../../messaging/MessageBus';

@Component({
    selector: 'app-company-details-form',
    templateUrl: './company-details-form.component.html',
    styleUrls: ['./company-details-form.component.scss']
})
export class CompanyDetailsFormComponent extends AlertMixin(MixinBase) implements OnInit {
    @Input() submitLabel = 'global.action.save';
    @Input() targetCompany: CompanyI;
    @Output() submittedEvent = new EventEmitter<CompanyI>();
    @Output() resetEvent = new EventEmitter<void>();
    formGroup: UntypedFormGroup;
    company: CompanyI;

    constructor(
        messageBus: MessageBus,
        configuration: Configuration,
        private formBuilder: UntypedFormBuilder,
        private sessionCompanyService: SessionCompanyService
    ) {
        super();
        this.initAlertMixin(messageBus.channel(configuration.messageBusChannels.ALERT_CHANNEL));
    }

    ngOnInit(): void {
        if (!this.targetCompany) {
            void this.loadCompany();
        } else {
            this.company = this.targetCompany;
        }
        this.initForm();
    }

    async loadCompany(): Promise<void> {
        this.company = await this.sessionCompanyService.getSessionCompany();
        this.initForm();
    }

    initForm(): void {
        const { required, email } = Validators;
        this.formGroup = this.formBuilder.group({
            name: [this.company?.name, required],
            legalAddress: [this.company?.legalAddress, required],
            billingAddress: [this.company?.billingAddress, required],
            vat: [this.company?.vat, required],
            billingEmail: [this.company?.billingEmail, [required, email]]
        });
    }

    onSubmit(): void {
        if (this.formGroup.invalid) {
            validateAllFormFields(this.formGroup);
        } else {
            this.submittedEvent.emit(this.formGroup.value as CompanyI);
        }
    }

    onReset(e: Event): void {
        e.preventDefault();
        this.formGroup.reset();
        this.initForm();
        this.resetEvent.emit();
    }
}
