import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
    public showBehaviour = new BehaviorSubject<boolean>(false);

    get isShow(): Observable<boolean> {
        return this.showBehaviour.asObservable();
    }

    show(): void {
        this.showBehaviour.next(true);
    }

    hide(): void {
        this.showBehaviour.next(false);
    }
}
