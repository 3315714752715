import { createStore } from '@ngneat/elf';
import { addEntities, selectEntity, withEntities } from '@ngneat/elf-entities';
import { ILicenseCompanyManager } from '../models/licenseCompany';
import { Injectable } from '@angular/core';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Observable } from 'rxjs/internal/Observable';

interface LicenseCompanyManagerStoreStateI {
    id: string; // company uuid
    licenseCompanyManagers: ILicenseCompanyManager[];
}

const store = createStore(
    { name: 'licenseCompanyManager' },
    withEntities<LicenseCompanyManagerStoreStateI>(),
    withRequestsCache<`license-company-manager-${string}`>()
);
export const skipWhileLicenseCompanyManagersCached = createRequestsCacheOperator(store);
export const CACHED_LICENSE_COMPANY_MANAGER_KEY = 'license-company-manager';

@Injectable({ providedIn: 'root' })
export class LicenseCompanyManagerRepository {
    selectLicenseCompanyManager(id: string): Observable<ILicenseCompanyManager[]> {
        return store.pipe(selectEntity(id, { pluck: 'licenseCompanyManagers' }));
    }
    addLicenseCompanyManager(payload: LicenseCompanyManagerStoreStateI): void {
        store.update(addEntities(payload), updateRequestCache(`${CACHED_LICENSE_COMPANY_MANAGER_KEY}-${payload.id}`));
    }
}
