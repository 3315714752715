import { TopWordI } from '../../../models/suggestion';
import { DocumentFilterService } from '../../document/service/document-filter.service';
import { Component, OnInit } from '@angular/core';
import { HomeServiceNg } from '../home.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-home-top10-search-dialog',
    templateUrl: './home-top10-search-dialog.component.html'
})
export class HomeTop10SearchDialogComponent implements OnInit {
    topwords: TopWordI[] = [];

    constructor(
        private homeService: HomeServiceNg,
        private documentFilterService: DocumentFilterService,
        private matDialogRef: MatDialogRef<HomeTop10SearchDialogComponent>
    ) {
        this.matDialogRef.addPanelClass('w-600');
    }

    ngOnInit(): void {
        this.searchTopWords();
    }

    /**
     * Display the first 10 most researched terms
     */
    searchTopWords(): void {
        this.homeService.searchTopWords().subscribe(result => {
            this.topwords = result;
        });
    }

    updateFilterKeys(word: string): void {
        this.documentFilterService.updateFilterKeys(word);
    }

    close(): void {
        this.matDialogRef.close();
    }
}
