import { Pipe, PipeTransform } from '@angular/core';
import { FilterSubCategoryI } from '../../../../models/filter';
import { removeAccents } from '../../../../shared/utils/stringUtils';

@Pipe({
    name: 'filterFields'
})
export class FilterFieldsPipe implements PipeTransform {
    transform(subCategories: FilterSubCategoryI[], filterValue: string): FilterSubCategoryI[] {
        return subCategories.filter(subCat => {
            // Remove accents and symbols from both strings
            const label = removeAccents(subCat.label.toUpperCase().trim());
            const value = removeAccents(filterValue.toUpperCase().trim());
            // check if the showed label contains the value or if it is selected
            return label.includes(value) || subCat.selected.value;
        });
    }
}
