import { Component } from '@angular/core';
import { CurrentUserI } from '../../models/user';
import { AuthenticateService } from '../../services/authenticate.service';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
    constructor(private authenticateService: AuthenticateService) {}

    get currentUser(): CurrentUserI {
        return this.authenticateService.currentUser;
    }
}
