import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { addEntities, selectEntity, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { ISimplePeriodic } from '../models/book';
import { Observable } from 'rxjs/internal/Observable';

interface IPeriodicMostRecentState {
    id: number; // id of magazine
    periodic: ISimplePeriodic;
}

const store = createStore(
    { name: 'adminPeriodicMostRecent' },
    withEntities<IPeriodicMostRecentState>(),
    withRequestsCache<`admin-periodics-most-recent-${number}`>()
);

export const skipWhileAdminPeriodicMostRecentCached = createRequestsCacheOperator(store);
export const CACHED_ADMIN_PERIODIC_MOST_RECENT_KEY = 'admin-periodics-most-recent';

@Injectable({ providedIn: 'root' })
export class AdminPeriodicMostRecentRepository {
    selectPeriodicByMagazineId$(magazineId: number): Observable<ISimplePeriodic> {
        return store.pipe(selectEntity(magazineId, { pluck: 'periodic' }));
    }
    addPeriodic(magazineId: number, periodic: ISimplePeriodic): void {
        store.update(
            updateRequestCache(`${CACHED_ADMIN_PERIODIC_MOST_RECENT_KEY}-${magazineId}`),
            addEntities({ id: magazineId, periodic })
        );
    }
}
