import { Injectable } from '@angular/core';
import { Pageable } from '../providers/pagination-provider';
import { createStore } from '@ngneat/elf';
import { getEntity, upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import {
    createRequestsCacheOperator,
    isRequestCached,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';
import { HttpPaginationResponseI } from '../models/http';
import { AdminCompanyManagerRepository } from './adminCompanyManager.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import * as _ from 'lodash';
import { CompanyManagerFilterI, CompanyManagerI } from '../models/companyManager';

interface ICompanyManagerContainerState {
    companyUuid: string;
    pages: string[][]; // id of magazine subscription by page
    activeFilters: CompanyManagerFilterI;
    pageable: Pageable;
    paginationData: PaginationData;
}

const store = createStore(
    { name: 'adminCompanyManagerContainer' },
    withEntities<ICompanyManagerContainerState, 'companyUuid'>({ idKey: 'companyUuid' }),
    withRequestsCache<`admin-company-manager-containers-${string}-${number}`>()
);

export const skipWhileAdminCompanyManagerContainerCached = createRequestsCacheOperator(store);
export const CACHED_ADMIN_COMPANY_MANAGER_CONTAINER_KEY = 'admin-company-manager-containers';

@Injectable({ providedIn: 'root' })
export class AdminCompanyManagerContainerRepository {
    constructor(private adminCompanyManagerRepository: AdminCompanyManagerRepository) {}

    getCompanyManagerContainer(companyUuid: string): ICompanyManagerContainerState {
        return store.query(getEntity(companyUuid));
    }

    clearCacheIfDistinctFiltersOrPageable(
        companyUuid: string,
        filters: CompanyManagerFilterI,
        pageable: Pageable
    ): void {
        const currentMagazine = store.query(getEntity(companyUuid));
        if (
            currentMagazine &&
            (!_.isEqual(filters, currentMagazine.activeFilters) ||
                currentMagazine.pageable.size !== pageable.size ||
                currentMagazine.pageable.sort !== pageable.sort)
        ) {
            this.clearCacheOfCompany(companyUuid);
        }
    }

    clearCacheOfCompany(companyUuid: string): void {
        const currentCompany = store.query(getEntity(companyUuid));
        currentCompany?.pages.forEach((value, key) => {
            store.update(
                updateRequestCache(`${CACHED_ADMIN_COMPANY_MANAGER_CONTAINER_KEY}-${companyUuid}-${key}`, {
                    value: 'none'
                })
            );
        });
    }

    updateCurrentPageIfIsCached(companyUuid: string, page: number): void {
        if (store.query(isRequestCached(`${CACHED_ADMIN_COMPANY_MANAGER_CONTAINER_KEY}-${companyUuid}-${page}`))) {
            const currentCompany = store.query(getEntity(companyUuid));
            this.adminCompanyManagerRepository.updatePagination(
                companyUuid,
                page,
                currentCompany.pages[page],
                currentCompany.paginationData
            );
        }
    }

    addPaginationCompanyManagers(
        companyUuid: string,
        paginationResponse: HttpPaginationResponseI<CompanyManagerI[]>,
        pageable: Pageable,
        filters: CompanyManagerFilterI
    ): void {
        const paginationData = {
            currentPage: paginationResponse.number,
            perPage: paginationResponse.size,
            total: paginationResponse.totalElements,
            lastPage: paginationResponse.totalPages
        };

        store.update(
            updateRequestCache(
                `${CACHED_ADMIN_COMPANY_MANAGER_CONTAINER_KEY}-${companyUuid}-${paginationResponse.number}`
            ),
            upsertEntitiesById(companyUuid, {
                creator: id => {
                    const pages = [];
                    pages[paginationResponse.number] = paginationResponse.content.map(c => c.uuid);
                    return {
                        companyUuid: id,
                        activeFilters: { ...filters },
                        pageable: { ...pageable },
                        paginationData,
                        pages
                    };
                },
                updater: entity => {
                    const pages = [...entity.pages];
                    pages[paginationResponse.number] = paginationResponse.content.map(c => c.uuid);
                    return {
                        ...entity,
                        activeFilters: { ...filters },
                        pageable: { ...pageable },
                        paginationData,
                        pages
                    };
                }
            })
        );

        this.adminCompanyManagerRepository.addPaginationCompanyManagers(companyUuid, paginationResponse);
    }
}
