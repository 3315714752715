import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    CheckBoxTreeDeterminedState,
    CheckBoxTreeIndeterminateState,
    CheckBoxTreeState,
    isIndeterminateState
} from '../three-state-checkbox/three-state-checkbox.component';
import { CheckBoxTreeItem, CheckBoxTreeTechnicalItem } from './collapsible_checkbox_tree_item.type';
import * as uuid from 'uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { equalSet } from '../../shared/utils/setUtils';

export type CheckStateItemUpdate = {
    id: string;
    checked: boolean;
};

@Component({
    selector: 'app-collapsible-checkbox-tree',
    templateUrl: './collapsible-checkbox-tree.component.html',
    styleUrls: ['./collapsible-checkbox-tree.component.scss']
})
export class CollapsibleCheckboxTreeComponent {
    private treeSubscriptions: Array<Subscription> = [];
    private checkedItemsSubject: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());
    private defaultFlatSelectionIdsSubject: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(new Set());
    private selectedAmountSubject: BehaviorSubject<number> = new BehaviorSubject(0);
    private _doesAnyoneHaveChildren: boolean;

    @Input() translationContext = '';
    @Input() parentItem: CheckBoxTreeTechnicalItem | null = null;
    @Input() parentComponent: CollapsibleCheckboxTreeComponent | null = null;
    @Input() technicalItems: Array<CheckBoxTreeTechnicalItem> = [];
    @Input() set items(value: Array<CheckBoxTreeItem>) {
        if (value) {
            this.technicalItems = [];
            this.buildTechnicalTree(value); // we will need it for filtering
            this.technicalItemsComputed.emit(this.technicalItems);
        }
    }
    @Input() set defaultFlatSelectionIds(value: Set<string>) {
        this.defaultFlatSelectionIdsSubject.next(value);
    }
    @Input() useScroll = true;

    @Output() itemsCheckedStatusUpdated = new EventEmitter<Array<CheckStateItemUpdate>>();
    @Output() checkedItems$ = this.checkedItemsSubject.pipe(distinctUntilChanged(equalSet));
    @Output() selectedAmount$ = this.selectedAmountSubject.asObservable();
    @Output() technicalItemsComputed = new EventEmitter<Array<CheckBoxTreeTechnicalItem>>();

    resetToDefault(): void {
        this.defaultFlatSelectionIdsSubject.next(this.defaultFlatSelectionIdsSubject.value);
    }

    private buildTechnicalTree(items: Array<CheckBoxTreeItem>, parent?: CheckBoxTreeTechnicalItem): void {
        if (parent) {
            parent.children = [];
            parent.expanded = false;
        } else {
            this.treeSubscriptions.forEach(subscription => subscription.unsubscribe());
            this.treeSubscriptions = [];
        }
        items.forEach(item => {
            const technicalItem: CheckBoxTreeTechnicalItem = {
                data: item,
                internalUuid: uuid.v4() as string,
                parent,
                state: CheckBoxTreeDeterminedState.Unchecked
            };

            this.treeSubscriptions.push(
                this.defaultFlatSelectionIdsSubject.subscribe(selection => {
                    const checked: boolean = selection && selection.has(item.id);
                    if (technicalItem.children?.length > 0) {
                        if (checked) {
                            this.updateTechnicalItemStatus(technicalItem, CheckBoxTreeDeterminedState.Checked);
                        } else {
                            if (!isIndeterminateState(technicalItem.state)) {
                                this.updateTechnicalItemStatus(technicalItem, [] as CheckBoxTreeIndeterminateState);
                            }
                        }
                    } else {
                        this.updateTechnicalItemStatus(
                            technicalItem,
                            checked ? CheckBoxTreeDeterminedState.Checked : CheckBoxTreeDeterminedState.Unchecked
                        );
                    }
                })
            );

            if (parent == null) {
                this.technicalItems.push(technicalItem);
            } else {
                parent.children.push(technicalItem);
            }

            if (item.children && item.children.length > 0) {
                this.buildTechnicalTree(item.children, technicalItem);
            }
        });
        this._doesAnyoneHaveChildren = this.technicalItems.some(technicalItem => this.mayHaveChildren(technicalItem));
    }

    mayHaveChildren(item: CheckBoxTreeTechnicalItem): boolean {
        return item.children != null;
    }

    get doesAnyoneHaveChildren(): boolean {
        return this._doesAnyoneHaveChildren;
    }

    handleCheckboxClick($event: MouseEvent, item: CheckBoxTreeTechnicalItem): void {
        if (isIndeterminateState(item.state) && !item.expanded) {
            item.expanded = true;
            $event.preventDefault();
        } else {
            const newState =
                item.state === CheckBoxTreeDeterminedState.Checked
                    ? CheckBoxTreeDeterminedState.Unchecked
                    : CheckBoxTreeDeterminedState.Checked;

            this.updateTechnicalItemStatus(item, newState);
        }
    }

    recomputeSelectedItemsOnTopLevel(): void {
        let topLevelAmount = 0;
        const values = new Set(
            this.technicalItems
                .filter(technicalItem => technicalItem.state !== CheckBoxTreeDeterminedState.Unchecked)
                .map(technicalItem => {
                    switch (technicalItem.state) {
                        case CheckBoxTreeDeterminedState.Checked:
                            topLevelAmount = topLevelAmount + 1;
                            return [technicalItem.data.id];
                        default:
                            return (technicalItem.state as CheckBoxTreeIndeterminateState).map(stateTechnicalItem => {
                                topLevelAmount = topLevelAmount + 1;
                                return stateTechnicalItem.data.id;
                            });
                    }
                })
                .reduce((accumulation, items) => accumulation.concat(items), [])
        );
        this.checkedItemsSubject.next(values);
        this.selectedAmountSubject.next(topLevelAmount);
    }

    handleCollapseButtonClick(item: CheckBoxTreeTechnicalItem): void {
        item.expanded = !item.expanded;
    }

    cleanupSelection(): void {
        const updatedItems = new Array<CheckStateItemUpdate>();
        const cleanupTechnicalItems = (technicalItems: CheckBoxTreeTechnicalItem[]) => {
            technicalItems.forEach(item => {
                if (item.state !== CheckBoxTreeDeterminedState.Unchecked) {
                    item.state = CheckBoxTreeDeterminedState.Unchecked;
                    updatedItems.push({
                        id: item.data.id,
                        checked: false
                    });
                }
                if (item.children) {
                    cleanupTechnicalItems(item.children);
                }
            });
        };
        cleanupTechnicalItems(this.technicalItems);

        this.itemsCheckedStatusUpdated.emit(updatedItems);
        this.checkedItemsSubject.next(new Set());
        this.selectedAmountSubject.next(0);
    }

    private updateTechnicalItemStatus(item: CheckBoxTreeTechnicalItem, newState: CheckBoxTreeState): void {
        const updatedItems = new Array<CheckStateItemUpdate>();

        const changeStatus = (itemToUpdate: CheckBoxTreeTechnicalItem, state: CheckBoxTreeState) => {
            const oldState = itemToUpdate.state;
            if (state !== oldState) {
                itemToUpdate.state = state;

                if (!(oldState == null && state === CheckBoxTreeDeterminedState.Unchecked)) {
                    // we don't need to emit undefined -> false value change
                    updatedItems.push({
                        id: itemToUpdate.data.id,
                        checked: state === CheckBoxTreeDeterminedState.Checked
                    });
                }
            }
        };

        const uncheckChildren = (parentItem: CheckBoxTreeTechnicalItem) => {
            if (parentItem.children != null) {
                parentItem.children.forEach(childItem => {
                    changeStatus(childItem, CheckBoxTreeDeterminedState.Unchecked);
                    uncheckChildren(childItem);
                });
            }
        };

        const syncParentState = (checkBoxTreeTechnicalItem: CheckBoxTreeTechnicalItem) => {
            if (checkBoxTreeTechnicalItem.parent) {
                let selectedChildren: CheckBoxTreeTechnicalItem[] = [];
                checkBoxTreeTechnicalItem.parent.children.forEach(itemToCheck => {
                    if (itemToCheck.state === CheckBoxTreeDeterminedState.Checked) {
                        selectedChildren.push(itemToCheck);
                    } else if (isIndeterminateState(itemToCheck.state)) {
                        selectedChildren = [
                            ...selectedChildren,
                            ...(itemToCheck.state as Array<CheckBoxTreeTechnicalItem>)
                        ];
                    }
                });
                let newParentState: CheckBoxTreeState;
                if (selectedChildren.length > 0) {
                    newParentState = selectedChildren as CheckBoxTreeState;
                } else {
                    if (isIndeterminateState(checkBoxTreeTechnicalItem.parent.state)) {
                        if (checkBoxTreeTechnicalItem.parent.state.length > 0) {
                            newParentState = CheckBoxTreeDeterminedState.Checked;
                        } else {
                            newParentState = CheckBoxTreeDeterminedState.Unchecked;
                        }
                    }
                }
                if (newParentState != null) {
                    changeStatus(checkBoxTreeTechnicalItem.parent, newParentState);
                }
                syncParentState(checkBoxTreeTechnicalItem.parent);
            }
        };

        changeStatus(item, newState);

        syncParentState(item);

        if (
            item.state === CheckBoxTreeDeterminedState.Unchecked ||
            item.state === CheckBoxTreeDeterminedState.Checked
        ) {
            uncheckChildren(item);
        }

        if (item.state === CheckBoxTreeDeterminedState.Unchecked && !item.expanded) {
            item.expanded = true;
        }

        this.itemsCheckedStatusUpdated.emit(updatedItems);
        this.recomputeSelectedItemsOnTopLevel();
    }
}
