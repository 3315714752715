import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { generateGRecaptchaHeaders } from '../../shared/utils/g-recaptcha';
import { mergeMap } from 'rxjs/operators';

interface IAutoLogonCredentialsResponse {
    companyUuid: string;
    hash: string;
}

@Injectable({
    providedIn: 'root'
})
export class ExposedAutologonService {
    serviceUrl = '/u/auto';
    constructor(private http: HttpClient) {}

    fetchAutologonCredentials(): Observable<IAutoLogonCredentialsResponse> {
        return generateGRecaptchaHeaders('FETCH_AUTOLOGON_CREDENTIALS').pipe(
            mergeMap(headers =>
                this.http.get<IAutoLogonCredentialsResponse>(`${this.serviceUrl}`, {
                    headers
                })
            )
        );
    }
}
