import { Pipe, PipeTransform } from '@angular/core';
import { removeAccents } from '../shared/utils/stringUtils';

@Pipe({
    name: 'filterCustomFields'
})
export class FilterCustomFieldsPipe implements PipeTransform {
    transform(object: any[], filterValue: string, property: string, splitByWord = false): any[] {
        return object.filter(item => {
            // Remove accents and symbols from both strings

            const propertyValue = item[property] as string;
            const propertyValueFormat = removeAccents(propertyValue.toUpperCase().trim());
            if (!splitByWord) {
                const value = removeAccents(filterValue.toUpperCase().trim());
                // check if the showed label contains the value or if it is selected
                return propertyValueFormat.includes(value);
            } else {
                const values = filterValue.split(' ').map(x => {
                    return removeAccents(x.toUpperCase().trim());
                });
                return propertyValueFormat.match(new RegExp(values.join('.*')));
            }
        });
    }
}
